import {AttributesValidator, Warning} from "../types";

const checkEachAttribute: AttributesValidator = {
  name: 'checkNameCollision',
  desc: '检查每一个属性的设置是否有问题',
  validate (attrList, knowledge) {

    let validated = true;
    const messages: Warning[] = [];

    const fail = (message: Warning) => {
      if (validated) {
        validated = false;
      }
      messages.push(message);
    }

    const {dataTypes = []} = knowledge;

    attrList.forEach(attr => {
      if (!attr.name) { // 要有属性名
        fail({
          type: 'warning',
          message: '请输入属性名称'
        })
      } else if (!attr.type) { // 要有值类型
        fail({
          type: 'warning',
          message: attr.name + ': 请选择属性值类型'
        })
      } else {
        const selectedDataType = dataTypes.find(dtype => dtype.name === attr.type);
        if (!selectedDataType || !selectedDataType.options) {
          // no rules available, no-op
        } else {
          const {type_options} = attr
          if (typeof type_options === 'object') {
            // 类型附带了选项。检查类型选项
            selectedDataType.options.forEach(option => {
              if (typeof type_options[option.name] === 'undefined') {
                fail({
                  type: 'warning',
                  message: attr.name + ': 请设置属性类型选项'
                })
              }
            })
          }
        }
      }

    });

    return {
      skip: !validated,
      validated: validated,
      messages: messages
    }
  }

}

export default checkEachAttribute;
