import moment from 'moment';
import React from 'react';
import Tag from '../Tag';
import './Card.less';

interface Category {
  id: number;
  label: string;
}

export interface CardFields {
  id: number;
  name: string;
  title: string;
  description: string;
  last_update_time: number;
  industry?: Category;
  business_scene?: Category;
  suitable?: Category;
  background: {
    uri?: string;
  };
  wip: boolean;
}

interface CardProps {
  data: CardFields;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Card: React.FC<CardProps> = (props) => {
  const { onClick, data } = props;
  const {
    title,
    description,
    last_update_time,
    industry,
    business_scene,
    suitable,
    background,
  } = data;
  const updateTime = moment(last_update_time);

  return (
    <div className="card-item" onClick={onClick}>
      <img
        className="card-img"
        src={background.uri}
        alt=""
        width="100%"
        height={200}
      />
      <div className="card-content">
        <div className="card-title">{title}</div>
        <div className="card-tags">
          <Tag text={industry?.label} />
          <Tag text={business_scene?.label} />
          <Tag text={suitable?.label} />
        </div>
        <div className="card-desc">{description}</div>
        <div className="card-date">{updateTime.format('YYYY-MM-DD')} 更新</div>
      </div>
    </div>
  );
};

export default Card;
