import ModalAddRelationship from './ModalAddRelationship';
import ModalUpsertRelationAttributes from './ModalUpsertRelationAttributes';

ModalAddRelationship.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template',
    },
    {
      name: 'type1',
      type: 'string',
    },
    {
      name: 'type2',
      type: 'string',
    },
    {
      name: 'showButton',
      type: 'boolean',
    },
    {
      name: 'disabled',
      type: 'boolean',
    },
  ],
  events: [
    {
      type: 'ok',
    },
  ],
  editorConfig: {
    defaultValue: {
      showButton: false,
      disabled: false,
      type1: '',
      type2: '',
    },
  },
};

ModalUpsertRelationAttributes.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template',
    },
    {
      name: 'relation',
      type: 'template',
    },
    {
      name: 'showButton',
      type: 'boolean',
    },
    {
      name: 'disabled',
      type: 'boolean',
    },
  ],
  events: [
    {
      type: 'close',
    },
  ],
  editorConfig: {
    defaultValue: {
      showButton: false,
      disabled: false,
    },
  },
};

export {
  ModalAddRelationship as ModalAddRelation,
  ModalUpsertRelationAttributes,
};
