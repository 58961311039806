import {AttributesValidator} from "../types";

const snakeCaseOrCamelCase: AttributesValidator = {
  name: 'snakeCaseOrCamelCase',
  desc: '检查每一个自定义属性的大小写命名方式是否统一，不统一的时候给出一条提示',
  validate (attrList, knowledge) {
    // todo implement this
    return {
      skip: false,
      validated: true,
      messages: []
    }
  }
}

export default snakeCaseOrCamelCase;
