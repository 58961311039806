import HTMLRender from './HTMLRender';

HTMLRender.register = {
  attributes: [
    {
      name: 'html',
      type: 'template',
    },
  ],
  editorConfig: {
    defaultValue: {
      html: '<span>hello HTMLRender</span>',
    },
  },
};

export default HTMLRender;
