/** @jsxImportSource @emotion/react */
import { Button, Form, message, Modal, Select } from "antd";
import { useState } from 'react';
import { useMutation } from "react-query";
import dataSourceApi from "../../api/requests/dataSourcesReqs";
import { EETBindInfo } from "../../api/types/DataSourceTypes";
import useMyQuery from "../../api/useMyQuery";
import { Day4CustomFC } from '../../types';
import './modalBindEETStyles.less';


interface ModalBindEETPropType {
  namespace: string,
  showButton: boolean,
  onOk: () => void,
  onCancel: () => void
}

const { Option } = Select;

const ModalBindEET: Day4CustomFC<ModalBindEETPropType> = ({
  namespace,
  showButton,
  onOk,
  onCancel
}) => {
  const [modalVisible, setModalVisible] = useState(!showButton);

  const { data: dataSourcesToChooseResponse } = useMyQuery(['datasources'], dataSourceApi.list);
  const dataSourcesToChoose = dataSourcesToChooseResponse?.data;
  const [selectedDatasource, setSelectedDatasource] = useState<string>('');

  const { data: eetsToChooseResponse } = useMyQuery(['eets', selectedDatasource], dataSourceApi.eets, {
    enabled: !!selectedDatasource
  });
  const eetsToChoose = eetsToChooseResponse?.data;
  const [selectedEET, setSelectedEET] = useState<string>('');

  const [form] = Form.useForm();

  const mutationBindEET =
    useMutation((bindInfo: EETBindInfo) => dataSourceApi.bindEET(bindInfo));

  // 回调区 ------------------------------------------------------

  const handleOk = async () => {
    try {
      const values = await form.validateFields() as EETBindInfo;

      // console.log(values);

      const bindInfo: EETBindInfo = {
        ...values,
        namespace: namespace
      }
      // console.log(bindInfo);
      // return;

      const result = await mutationBindEET.mutateAsync(bindInfo);

      if (result?.data) {
        message.success('绑定成功！');

        form.resetFields();
        setModalVisible(false);

        onOk && onOk();
        return result?.data; // todo in need of further chaining.
      }
    } catch (e) {
      // no-op
    }
  }

  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
    onCancel && onCancel();
  }

  // 组件片段 ----------------------------------------------------

  const footer = (
    <>
      <Button key="ok" type="primary" onClick={handleOk}>确定</Button>
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  )

  return (
    <>
      {showButton && <Button type="default" onClick={() => setModalVisible(true)}
      >绑定外部实体类</Button>}
      <Modal visible={modalVisible}
        closable={false} maskClosable={false}
        title={"绑定外部实体类"} footer={footer}
        className={'eet-bind-modal'}
      >
        <Form
          name="bindEET"
          form={form}
          labelCol={{ span: 5 }}
        >
          <Form.Item
            label="数据源"
            name="data_source"
            rules={[
              { required: true, message: '请选择数据源' }
            ]}
          >
            <Select
              placeholder={"请选择数据源"}
              value={selectedDatasource}
              onChange={setSelectedDatasource}
              dropdownMatchSelectWidth
              showSearch
            >
              {dataSourcesToChoose && dataSourcesToChoose.map(ds => (
                <Option value={ds.id} key={ds.id}>{ds.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="外部实体类"
            name="name" // 和后端保持一致
            rules={[
              { required: true, message: '请选择外部实体类' }
            ]}
          >
            <Select
              placeholder={'请选择外部实体类'}
              value={selectedEET}
              onChange={setSelectedEET}
              dropdownMatchSelectWidth
              showSearch
            >
              {eetsToChoose && eetsToChoose.map(eet => (
                <Option value={eet.name} key={eet.name}>
                  <div className={'eet-list-item-name'}>{eet.name}</div>
                  <div className={'eet-list-item-display'}>{eet.display}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ModalBindEET;

