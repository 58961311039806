export interface EntityDataTypes {
  [index:string]:{}[]
}


export interface EntityDataDataFrameTypes {

  schema: Schema[]
  rows: [][]

}

export interface Schema {
  name: string
  data_type: string
}

export interface DataColumn {
  title: string
  dataIndex: string
  key: string
}


/**
 * {
    "lineConnection": {
      "edges": [
        {
          "node": {
            "id": "ALypKi2nR",
            "name": "产线1"
          }
        },
        {
          "node": {
            "id": "1lP6Sdhng",
            "name": "产线2"
          }
        }
      ],
      "pageInfo": {
        "endCursor": "aW90ZGJfbW9kdWxlX3Rlc3QvbGluZS9pZD0xbFA2U2Robmc=",
        "hasNextPage": true,
        "hasPreviousPage": false,
        "startCursor": "aW90ZGJfbW9kdWxlX3Rlc3QvbGluZS9pZD1BTHlwS2kyblI="
      }
    }
  }
 */

export interface EntityConnectionDataTypes {
  edges: RecordNode[]
  pageInfo: PageInfo
  _aggregate?: Aggregate


}


interface Aggregate {
  total_count: number
}

export interface RecordNode {
  node: { [index: string]: any }
}

export interface PageInfo {
  endCursor: string
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string
  pageSize: number
  total:number
}


/**
 * {
  lineConnection(first:2) {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
 */


export function QueryEntityDataTemplate(
                                              table: string,
                                              columns: string[],
                                              filter: string) {



    return `{
        ${table} ${filter === undefined || filter === 'undefined' || filter === '' ? '' : `(filter:{${filter}})`} {
               ${columns.join(" ")}
        }
  }`


}

export function QueryEntityConnectionTemplate(isNextPage: boolean,
                                              table: string,
                                              cursor: string,
                                              pageSize: number,
                                              columns: string[],
                                              filter: string) {


  if (pageSize === -1) {
    return `{
        ${table} ${filter === undefined || filter === 'undefined' || filter === '' ? '' : `(filter:{${filter}})`} {
               ${columns.join(" ")}
        }
  }`
  }

  return `query {
  ${table}Connection(${filter === undefined || filter === 'undefined' ? '' : `filter:{${filter}},`} ${isNextPage ? 'after' : 'before'}:"${cursor === '-1' ? '' : cursor}",${isNextPage ? 'first' : 'last'}:${pageSize}) {
    edges {
      node {
        ${columns.join(" ")}
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}`
}

export function QueryEntityConnectionWithOffsetTemplate(
                                              table: string,
                                              current: number,
                                              pageSize: number,
                                              columns: string[],
                                              filter: string) {

  const offset = (current - 1) * pageSize

  return `query {
  ${table}Connection(${filter === undefined || filter === 'undefined' ? '' : `filter:{${filter}},`} limit:${pageSize},offset:${offset}) {
    edges {
      node {
        ${columns.join(" ")}
      }
    }
    pageInfo {
      total
    }
  }
}`
}

export function QueryRelationshipDataConnectionTemplate(foreignTable: string, filter: string) {

  return `query {
  ${foreignTable}Connection (filter:{${filter}}){
    _aggregate {
     total_count
    }
  }
}`

}


/**
 * mutation m1 {
  createunit(data:{name:"", line:{id:""}}) {
    id
    name
  }
}
 */

export  function CreateEntityDataTypeTemplate(entity:string, data:string, columns:string[]) {

  return `
        mutation ${entity} {
            create${entity}(data:{${data}}){
              ${columns.join(" ")}
            }
        }
  `
}
