import useMyQuery from "../../../api/useMyQuery";
import entityApi from "../../../api/requests/entityReqs";
import relationApi from "../../../api/requests/relationReqs";

import useSavedPositions from "../../EntityRelationDiagram/hooks/useSavedPositions";


const useQueryElements = (namespace: string) => {

  const {positions, size} = useSavedPositions(namespace);

  const {
    data: entitiesResponse,
    isLoading: isEntityLoading,
    refetch: refetchEntities
  } = useMyQuery(['entities', namespace], entityApi.list, {
    refetchOnWindowFocus: false,
    // see: https://react-query.tanstack.com/guides/dependent-queries
    enabled: !!namespace
  });

  const entities = entitiesResponse?.data;

  const {
    data: relationsResponse,
    isLoading: isRelationLoading,
    refetch: refetchRelations
  } = useMyQuery(['relations', namespace], relationApi.list, {
    refetchOnWindowFocus: false,
    // see: https://react-query.tanstack.com/guides/dependent-queries
    enabled: !!namespace
  });

  const relations = relationsResponse?.data;

  return {
    entities,
    relations,
    positions,
    size
  };
};

export default useQueryElements;
