import Day4BaseProvider from 'day4-helpers/es/components/Day4BaseProvider';
import Day4Renderer from 'day4-helpers/es/components/Day4Renderer';
import env from 'day4-utils/es/env';
import React, { FC, lazy, Suspense, useEffect, useState } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { ss } from './k2assets-auth';
import modules from './modules';

const Admin = lazy(() => import('./Admin'));
const queryClient = new QueryClient();

interface UserInfo {
  name: string;
}

const App: FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>(); // 用state hook更新userInfo2

  //K2Assets SSO功能
  useEffect(() => {
    console.log('DAY4_APP_AUTH_ENABLED:', env.DAY4_APP_AUTH_ENABLED);
    console.log('auth enabled');
    ss.getUser()
      .then((user) => {
        // console.log(user, 'user');
        // console.log('sid is ', window.sid);
        // console.log('username is ', window.username);
        // console.log('permissions is ', window.permissions);
        // console.log('access token is ', window.access_token);
        // console.log('token type is ', window.token_type);
        // // 将登录信息写入本地存储
        // localStorage.setItem('ssoToken', window.access_token);
        // localStorage.setItem('ssoTokenType', window.token_type);
        // localStorage.setItem('ssoSid', window.sid);
        if (user) {
          setUserInfo({ name: window.username });
        } else {
          setUserInfo({ name: '' });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return userInfo ? (
    <QueryClientProvider client={queryClient}>
      <Day4BaseProvider modules={modules} userInfo={userInfo}>
        <ReactFlowProvider>
          <Suspense fallback={null}>
            <Switch>
              <Route path="/day4-admin">
                <Admin />
              </Route>
              <Route path="">
                <Day4Renderer />
              </Route>
            </Switch>
          </Suspense>
        </ReactFlowProvider>
      </Day4BaseProvider>
    </QueryClientProvider>
  ) : null;
};

export default App;
