import {AttributesValidator} from "../types";

const isItBlankEntity: AttributesValidator = {
  name: 'isItBlankEntity',
  desc: '检查是否是空白实体（没有自定义属性），对刚创建的实体，给出友好提示',
  validate (attrList, knowledge) {
    const customAttributes = attrList.filter(attr => {
      return attr.category === 'CUSTOM'
    });
    if (customAttributes.length === 0) {
      return {
        skip: true,
        validated: true,
        messages: [
          {
            type: 'info',
            message: '这是一个新创建的实体类型。请点击“创建新属性”'
          }
        ]
      }
    } else {
      return {
        skip: false,
        validated: true,
        messages: []
      }
    }
  }
}

export default isItBlankEntity;
