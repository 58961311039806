/** @jsxImportSource @emotion/react */
import styles from 'day4-components/es/styles';

import {FC} from "react";
import {EntityAttributeType, EntityType} from "../../../api/types/EntityTypes";
import {ReactFlowCustomNodePropType} from "../types";

import {Handle, Position} from "react-flow-renderer";
import FingerprintIcon from "./FingerPrintIcon";

interface ItemProps {
  // 来自哪个对象。用于拼出Handle-id
  from: string,
  attr: EntityAttributeType;
}

const Item: FC<ItemProps> = ({from, attr}) => {
  const {name, type, is_primary} = attr;

  return (
    <div
      css={[
        styles.position.relative,
        styles.padding([
          {size: '2', side: 'x'},
          {size: '0.5', side: 'y'},
        ]),
      ]}
    >
      <div css={[styles.display.flex, styles.alignItems.center]}>
        {is_primary && <FingerprintIcon color={'#999'} style={{marginLeft: -5}}/>}
        <div css={styles.marginRight('0.5em')}>{name}</div>
        <div css={[styles.marginLeft('auto')]}>{type}</div>
      </div>
      <Handle
        type="source"
        id={`source@${from}.${name}`}
        position={Position.Right}
        style={{top: '50%', transform: 'translate(0, -50%)'}}
      />
    </div>
  );
};

const EntityNode: FC<ReactFlowCustomNodePropType<EntityType>> = ({data, type}) => {

  const {name, display, attributes = []} = data;

  return (
    <div
      css={[
        styles.border({style: 'solid', width: 'default', color: 'gray.800'}),
        styles.borderRadius({radius: 'default'}),
        styles.backgroundColor('gray.300'),
        styles.minWidth('48'),
      ]}
    >
      <div
        css={[
          styles.border({
            side: 'bottom',
            width: 'default',
            color: 'gray.800',
            style: 'solid',
          }),
          styles.padding([
            {size: '2', side: 'x'},
            {size: '1', side: 'y'},
          ]),
          styles.position.relative
        ]}
      >
        <Handle
          type="target"
          position={Position.Left}
          style={{top: '50%', transform: 'translate(0, -50%)'}}
          id={`target@${name}`}
        />
        <span css={[styles.fontWeight.bold, styles.fontSize.lg]}>{name}({display})</span>
      </div>
      {attributes.map((attr: EntityAttributeType) => (
        <Item key={attr.attribute_id} from={name} attr={attr}/>
      ))}
    </div>
  );
}

export default EntityNode;
