import React from 'react';
import ReactDOM from 'react-dom';
import 'day4-helpers/es/utils/polyfill10k';
import './index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';

function render() {
  ReactDOM.render(<App />, document.getElementById('root'));
}

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  console.log('day4 app bootstraped');
}

export async function mount(props: any) {
  console.log(props);
  render();
}

export async function unmount() {
  const rootElement = document.getElementById('root');
  if (rootElement) {
    ReactDOM.unmountComponentAtNode(rootElement);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
