/** @jsxImportSource @emotion/react */
import styles from 'day4-components/es/styles';

import {FC, ReactElement, useMemo} from "react";
import {EntityAttributeType} from "../../../api/types/EntityTypes";
import {
  AugmentedEntityType,
  NodeHighlightType,
  ReactFlowCustomNodePropType
} from "../types";

import {Handle, Position} from "react-flow-renderer";
import FingerprintIcon from "./FingerPrintIcon";

interface ItemProps {
  attr: EntityAttributeType;
}

const Item: FC<ItemProps> = ({attr}) => {
  const {name, type, is_primary} = attr;

  return (
    <div
      css={[
        styles.position.relative,
        styles.padding([
          {size: '2', side: 'x'},
          {size: '0.5', side: 'y'},
        ]),
      ]}
    >
      <div css={[styles.display.flex, styles.alignItems.center]}>
        {is_primary && <FingerprintIcon color={'#999'} style={{marginLeft: -5}}/>}
        <div css={styles.marginRight('0.5em')}>{name}</div>
        <div css={[styles.marginLeft('auto')]}>{type}</div>
      </div>
    </div>
  );
};

const DynamicHandleOpaqueEntityNode: FC<ReactFlowCustomNodePropType<AugmentedEntityType>> = ({data, type}) => {

  const {name, display, desc, attributes = [], relations = [], viewMode, highlight} = data;
  // console.log(relations);

  const calculateCssTop = (currentIndexZeroBased: number, total: number) => {
    const float = (currentIndexZeroBased + 1) / (total + 1);
    // squeeze toward center (50%), ratio 2
    const percent = ((100 * float + 50) / 2).toFixed(2) + '%'
    return percent;
  }

  const handles = useMemo<ReactElement[]>(() => {
    // 读取rel的表示，生成source或target
    return relations.flatMap((rel, index) => {
      const {
        canonicalExpression,
        object_type_name1: name1,
        object_type_name2: name2
      } = rel;

      if (!canonicalExpression) {
        throw new Error('for dev: canonicalExpression not implemented!')
      }

      const result: ReactElement[] = [];

      // console.log(name, name1, name2);

      if (name === name1) {
        result.push(
          <Handle
            type="source"
            position={Position.Right}
            style={{top: calculateCssTop(index, relations.length), transform: 'translate(0, -50%)'}}
            id={`source@${name1}(${canonicalExpression})`}
            key={`source@${name1}(${canonicalExpression})`}
          />
        )
      }

      if (name === name2) {
        result.push(
          <Handle
            type="target"
            position={Position.Left}
            style={{top: calculateCssTop(index, relations.length), transform: 'translate(0, -50%)'}}
            id={`target@${name2}(${canonicalExpression})`}
            key={`target@${name2}(${canonicalExpression})`}
          />
        )
      }

      return result;
    })
  }, [name, relations])

  const highlightBorderStyleDict: Record<Exclude<NodeHighlightType, null>, string> = {
    source: '#ff2222',
    target: '#3536ff',
    filter: '#ffbc00',
    "filter-out": '#dbdbdb',
    'default': 'grey.800'
  }

  const highlightBgStyleDict: Record<Exclude<NodeHighlightType, null>, string> = {
    source: '#fcddd4',
    target: '#d4dcef',
    filter: '#ffdb8f',
    "filter-out": '#dddddd',
    'default': 'rgb(252,251,234)'
  }

  return (
    <div
      css={[
        styles.border({
          style: 'solid', width: 'md',
          color: highlightBorderStyleDict[highlight || 'default']
        }),
        styles.borderRadius({radius: 'default'}),
        styles.backgroundColor(highlightBgStyleDict[highlight || 'default']),
        styles.minWidth('48'),
        styles.position.relative,
        styles.paddingBottom('5px')
      ]}
      style={{
        transition: 'border-color 0.1s 0.3s ease-out, background-color 0.1s 0.3s ease-out'
      }}
    >
      {handles}
      <div
        css={[
          styles.border({
            side: 'bottom',
            width: 'default',
            color: 'gray.600',
            style: 'solid',
          }),
          styles.padding([
            {size: '2', side: 'x'},
            {size: '1', side: 'y'},
          ])
        ]}
      >
        {
          viewMode === 'expanded'
            ? <span css={[styles.fontWeight.bold, styles.fontSize.lg]}>{name}({display})</span>
            : (<div>
                 <div css={[styles.fontWeight.bold, styles.fontSize["5xl"]]}>{name}</div>
                 <div css={[styles.textColor('#666'), styles.fontSize["3xl"]]}>({display})</div>
              </div>)
        }
      </div>
      {viewMode === 'expanded' && attributes.map((attr) => (
        <Item key={attr.name} attr={attr}/>
      ))}
      {viewMode === 'collapsed' && desc && <div>
        {desc}
      </div>}
    </div>
  );
}

export default DynamicHandleOpaqueEntityNode;
