import request from '../../sdk/request';
import env from 'day4-utils/es/env';
import {TemplateType} from "../types/TemplateTypes";
import { TagType } from '../types/TagTypes';

const api = env.DAY4_APP_MODELER_META_API;

const templateApi = {
  create (params: TemplateType) {
    const {namespace: nsName} = params;
    return request<TemplateType>({
      url: `${api}/namespaces/${nsName}/templates`,
      method: "POST",
      data: params
    });
  },
  remove (params: TemplateType) {
    const {namespace: nsName, name: tplName} = params;
    return request({
      url: `${api}/namespaces/${nsName}/templates/${tplName}`,
      method: "DELETE"
    });
  },
  update (params: TemplateType) {
    const {old_name:oldName, namespace: nsName, ...restParams} = params;
    return request<TemplateType>({
      url: `${api}/namespaces/${nsName}/templates/${oldName}`,
      method: "PUT",
      data: restParams
    });
  },
  detail (nsName: string, tplName: string) {
    return request<TemplateType>({
      url: `${api}/namespaces/${nsName}/templates/${tplName}`,
    })
  },
  list (nsName: string) {
    return request<TemplateType>({
      url: `${api}/namespaces/${nsName}/templates`
    });
  },
  bindTag (nsName: string, tplName: string, tags: TagType[]) {
    return request<TagType[]>({
      url: `${api}/namespaces/${nsName}/templates/${tplName}/tags`,
      method: "POST",
      data: tags
    })
  },
  unbindTag (nsName: string, tplName: string, tags: TagType[]) {
    return request<TagType>({
      url: `${api}/namespaces/${nsName}/templates/${tplName}/tags`,
      method: "DELETE",
      data: tags
    })
  },
  TagList (nsName: string, tplName: string) {
    return request<TagType[]>({
      url: `${api}/namespaces/${nsName}/templates/${tplName}/tags`,
      method: "GET",
    })
  }
}

export default templateApi;
