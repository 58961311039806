import { useQuery } from 'react-query';
import { QueryFunction, UseQueryOptions, QueryKey } from 'react-query/types'

/**
 * A wrapper around `useQuery` in `react-query`,
 * making query parameter passing straight-forward.
 *
 * **Use it inside custom components!**
 *
 * Basic idea is to transform all the elements in `key` except the first one,
 * into the argument of fn.
 *
 * e.g. useMyQuery(['entity_type', namespace, entity], entityApi.detail, {...})
 *      will pass `namespace` and `entity` as arguments to `entityApi.detail`,
 *      so as to call `entityApi.detail(namespace, entity)`
 */
export default function useMyQuery<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  key: TQueryKey,
  fn: (...params: any[]) => TQueryFnData | Promise<TQueryFnData>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) {
  const queryFn: QueryFunction<TQueryFnData, TQueryKey> = ({ queryKey }) => {
    if (typeof queryKey === 'string') {
      return fn();
    } else if (Array.isArray(queryKey)) {
      // see: https://react-query.tanstack.com/guides/query-functions#query-function-variables
      const [_key, ...rest] = queryKey;
      return fn(...rest);
    } else {
      // no exception
      throw new Error('queryKey is neither string or array, which is not supported.');
    }
  };
  return useQuery(key, queryFn, {
    ...options,
    retry: false
  });
}
