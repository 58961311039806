import dataFrameApi from "../api/requests/dataFrameReqs";
import {getByPath} from "../sdk/utils";

export function dataFrameList ({dependencies}: any) {
  const namespace = getByPath(dependencies, [0, 1]);
  if (!namespace) {
    // prevent uninitialized query
    return null;
  }
  return dataFrameApi.list(namespace);
}
