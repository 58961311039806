/** @jsxImportSource @emotion/react */
import styles from 'day4-components/es/styles';
import {Day4CustomFC} from "../../types";
import useStyles from "day4-components/es/hooks/useStyles";
import useQueryElements from "./hooks/useQueryElements";
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  ConnectionLineType,
  Controls,
  Edge,
  Elements,
  MiniMap,
  Node,
  removeElements
} from "react-flow-renderer";
import {Spin} from "antd";
import {useCallback, useState} from "react";

import {minimapNodeColors, nodeRegistry} from './customNodes'
import {ViewMode} from "./types";
import usePositionSaving from "./hooks/usePositionSaving";

interface EntityRelationDiagramPropType {
  styles?: any,
  namespace: string,
  search?: string,
  viewMode: ViewMode
}

const EntityRelationDiagram: Day4CustomFC<EntityRelationDiagramPropType> = ({
  namespace,
  search = '',
  viewMode = 'expanded',
  styles: styleProps
}) => {
  const componentCss = useStyles([], styleProps);
  const [focusedEdge, setFocusedEdge] = useState<Edge | null>(null);
  const {
    isLoading,
    elements,
    setElements,
    /* note: use this to manually refetch, and the result should be expected
          on `elements` (it will auto update).
     */
    // refetchEntities,
    // refetchRelations
  } = useQueryElements(namespace, viewMode, search, focusedEdge);

  // 保存画布上节点的位置。
  const [namespaceInfo, nodes] = usePositionSaving(namespace);
  // console.log(namespaceInfo, nodes);

  const onElementsRemove = (elementsToRemove: Elements<any>) =>
    setElements((els) => removeElements(elementsToRemove, els) as Elements);
  const onConnect = (params: Edge<any> | Connection) =>
    setElements((els) => addEdge(params, els) as Elements);

  /* note: mouse enter/leave and search filtering only change elements' style,
  *     but we need to make the logic clear and push all the calculations
  *     inside `useQueryElements`, and reflect the changes per nodeType.
  * */

  const handleEdgeMouseEnter = (event: any, edge: Edge) => {
    setFocusedEdge(edge);
  }
  const handleEdgeMouseLeave = (event: any, edge: Edge) => {
    setFocusedEdge(null);
  }

  const resolveMinimapNodeColor = useCallback((node: Node) => {
    return minimapNodeColors[node.type || 'unknown'] || '#eee';
  }, [])

  return (
    <div css={[componentCss, styles.position.relative]}>
      {/* for debugging use */}
      {/*{focusedEdge && <JsonDebugger value={focusedEdge} style={{*/}
      {/*  position: 'absolute', zIndex: 10, background: 'white', border: '2px solid #333',*/}
      {/*  pointerEvents: 'none'*/}
      {/*}}/>}*/}
      {isLoading && (
        <div
          css={[
            styles.position.absolute,
            styles.positionPlacement['inset-0'],
            styles.display.flex,
            styles.alignItems.center,
            styles.justifyContent.center,
          ]}
        >
          <Spin />
        </div>
      )}
      <ReactFlow
        elements={elements}
        nodeTypes={nodeRegistry}
        connectionLineType={ConnectionLineType.Bezier}
        onElementsRemove={onElementsRemove}
        onEdgeMouseEnter={handleEdgeMouseEnter}
        onEdgeMouseLeave={handleEdgeMouseLeave}
        onConnect={onConnect}
        defaultZoom={0.5}
        minZoom={0.2}
        maxZoom={2}
        style={{background: '#ffffff'}}
      >
        <Background variant={BackgroundVariant.Dots}
                    gap={21}
        />
        <MiniMap
          nodeColor={resolveMinimapNodeColor}
          nodeStrokeWidth={3}
        />
        <Controls />
      </ReactFlow>
    </div>
  )

}

export default EntityRelationDiagram;
