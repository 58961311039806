/** @jsxImportSource @emotion/react */
import { Day4CustomFC } from '../../types'
import { TagType } from '../../api/types/TagTypes';
import { Select, message, Button, Modal } from "antd";
import {tagList} from '../../queries/tagQueries'
import { useState } from 'react';
import templateApi from '../../api/requests/templateReqs';
import {useMutation} from "react-query";

type ModalAddTagsPropType = {
	ns: string | "",
	resourceType: string | ""
	resourceName: string | ""
  initialValue: TagType[] | [],
}

const ModalAddTags: Day4CustomFC<ModalAddTagsPropType> = ({
	ns,
	resourceType,
	resourceName,
  initialValue,
}) => {

	const [modalVisible, setModalVisible] = useState(false)
	const [successedflag, setSuccessedflag] = useState(true)
	const [changedflag, setChangedflag] = useState(false)

	let _v: Array<string> = []
	if (initialValue) {
    for (let i = 0; i < initialValue.length; i++) {
			_v.push(initialValue[i].keyword)
		}
	}
	const [values, setValues] = useState<string[]>(_v)

	const [tags, setTags] = useState<TagType[]>([])
	
	const mutationBindTags =
    useMutation((v: TagType[]) => templateApi.bindTag(ns, resourceName, v));
		const mutationUnBindTags =
	useMutation((v: TagType[]) => templateApi.unbindTag(ns, resourceName, v));

	const onChange  = (value: string[]) => {
		if (successedflag) {
	    setValues(value)
		}
		//set successFlag
		setSuccessedflag(true)
	}

	const onDeselect = (value: string) => {
		let v;

		for (let i = 0; i < tags.length; i++) {
			if (tags[i].keyword == value) {
				v = tags[i];
			} 
		}

		if (v != undefined) {
			mutationUnBindTags.mutateAsync([v]).then((result)=> {
				if (result?.code == 0) {
					setChangedflag(true)
					message.success("移除tag成功");
				} else {
			    setSuccessedflag(false)
					message.error(result?.message);
				}
			}).catch((err) => {
					// reset
				let vv: string[] = []
				for (let i = 0; i < values.length; i++) {
					if (values[i] == value) {
						continue
					}
					vv.push(values[i])
				}
				vv.push(value)

				setValues(vv)
				setSuccessedflag(false)

			  message.error(err.message)
			});
		} else {
			// reset
				let vv: string[] = []
				for (let i = 0; i < values.length; i++) {
					if (values[i] == value) {
						continue
					}
					vv.push(values[i])
				}
				vv.push(value)

				setValues(vv)
				setSuccessedflag(false)

			  message.error("参数缺失")
		}
	}

	const onSelect = (value: string) => {
		let v: TagType = {
			keyword: value,
		};

		 mutationBindTags.mutateAsync([v]).then((result)=> {
			if (result?.code == 0) {
				setChangedflag(true)
				message.success("添加tag成功");

				// 如果是新建的tag,需要更新下select options
				for (let i = 0; i < tags.length; i++) {
					if (tags[i].keyword == v.keyword) {
						return
					}
				}
				tagList().then((res) => {
					let vv: TagType[] = [];
					if (res?.data) {
						for(let i = 0; i < res?.data.length; i++) {
							vv.push(res.data[i]);
						}

						setTags(vv);
					}
				})
			} else {
				// reset
				let vv: string[] = []
				for (let i = 0; i < values.length; i++) {
					if (values[i] == v.keyword) {
						continue
					}
					vv.push(values[i])
				}
				setValues(vv)
				setSuccessedflag(false)
				message.error(result?.message);
			}
		}).catch((err) => {
				// reset
				let vv: string[] = []
				for (let i = 0; i < values.length; i++) {
					if (values[i] == v.keyword) {
						continue
					}
					vv.push(values[i])
				}
				setValues(vv)
				setSuccessedflag(false)
				message.error(err.message);
		});
	
	} 

	const onDropdownVisibleChange = (open: boolean) => {
		if (open) {
			tagList().then((res) => {
			let v: TagType[] = [];
			if (res?.data) {
				for(let i = 0; i < res?.data.length; i++) {
					v.push(res.data[i]);
				}

				setTags(v);
			}
		})
		}
	}
	
	const handleClick = () => {
		templateApi.TagList(ns, resourceName).then((res) => {
			let v: string[] = [];
			if (res?.data) {
				for(let i = 0; i < res?.data.length; i++) {
					v.push(res.data[i].keyword);
				}

				setValues(v);
			}
		});

		tagList().then((res) => {
			let v: TagType[] = [];
			if (res?.data) {
				for(let i = 0; i < res?.data.length; i++) {
					v.push(res.data[i]);
				}

				setTags(v);
			}
		});

    setModalVisible(true);
  }

	const handleClose = () => {
		// if changed, reflash
		if (changedflag) {
			window.location.reload()
		}
		// reset state
    setModalVisible(false);
		setChangedflag(false)
  }

  const footer = (
    <>
      <Button key="cancel" type="primary" onClick={handleClose}>Close</Button>
    </>
  )

	return (
		<>
		 {<Button type="default" onClick={handleClick}>添加标签</Button>}
		<Modal visible={modalVisible} closable={false} maskClosable={false} title={"添加标签(按回车)"} footer={footer} className={'add-tag-modal'}>
    <Select mode="tags"
		  value = {values}
			style = {{width: '100%'}}
			onDropdownVisibleChange = {onDropdownVisibleChange}
			onChange = {onChange}
			onDeselect = {onDeselect}
			onSelect = {onSelect}
			placeholder = "点击添加"
			bordered = {true}
		>
			{
				tags.map((item) => {
					return (
						<Select.Option value={item.keyword} key={item.keyword}>{item.keyword}</Select.Option>
					)
				})
			}
		</Select>
		</Modal>
		</>
	)
}

export default ModalAddTags