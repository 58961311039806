import {FC, ReactNode, useMemo} from "react";
import {Warning} from "../types";

import { InfoCircleTwoTone, WarningTwoTone, StopTwoTone } from '@ant-design/icons';

interface WarningAreaPropType {
  warnings: Warning[],
  style: any
}

const WarningArea: FC<WarningAreaPropType> = ({
  warnings,
  style
}) => {

  const iconDict = useMemo<Record<'info' | 'warning' | 'error', ReactNode>>(() => {
    return {
      info: <InfoCircleTwoTone twoToneColor={'#6fa3fd'}/>,
      warning: <WarningTwoTone twoToneColor={'#ffd84d'}/>,
      error: <StopTwoTone twoToneColor={'#f35151'}/>
    }
  }, [])

  return (
    <div style={{...style, height: '100%', paddingLeft: 8, overflow: 'auto'}}>
      {warnings.map((w, i) => {
        const {type, message} = w;
        const icon = iconDict[type];
        return (<div key={i}>
          {icon}
          <span style={{marginLeft: 5, fontSize: 'smaller'}}>{message}</span>
        </div>)
      })}
    </div>
  )
}

export default WarningArea;

