import React from 'react';
import { Anchor as AntdAnchor, Avatar, Divider } from 'antd';
import { Day4CustomFC } from '../../types';
import './Anchor.less';
import { LeftOutlined } from '@ant-design/icons';

const { Link } = AntdAnchor;

interface Option {
  key: string;
  title: string;
}

interface Props {
  options: Option[];
  otherInfoVisible?: boolean;
}

const Anchor: Day4CustomFC<Props> = ({ options, otherInfoVisible }) => {
  return (
    <>
      <AntdAnchor className="custom-anchor" showInkInFixed={false} offsetTop={8}>
        <div className="custom-anchor-group">
          {options.map((x, index) => (
            <Link
              href={`#${x.key}`}
              title={
                <>
                  <Avatar
                    size="small"
                    style={{
                      verticalAlign: 'middle',
                      fontSize: 18,
                      marginRight: 11,
                    }}
                  >
                    {index + 1}
                  </Avatar>
                  {x.title}
                </>
              }
              key={x.key}
            />
          ))}
          {otherInfoVisible && (
            <>
              <Divider style={{ margin: '12px 0' }} />
              <Link
                href="#other-info"
                title={<span style={{ marginLeft: 35 }}>其他信息</span>}
              />
            </>
          )}
        </div>
        <div className="custom-anchor-group" style={{ marginTop: 24, paddingTop: 10, paddingBottom: 1 }}>
          <Link
            href="/"
            title={
              <>
                <Avatar
                  size="small"
                  style={{
                    verticalAlign: 'middle',
                    fontSize: 18,
                    marginRight: 11,
                  }}
                >
                  <LeftOutlined />
                </Avatar>
                返回首页
              </>
            }
          />
        </div>
      </AntdAnchor>
    </>
  );
};

export default Anchor;
