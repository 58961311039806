/** @jsxImportSource @emotion/react */
import { CodeOutlined as ShellIcon } from '@ant-design/icons';
import { Button, Form, Modal, Tabs } from "antd";
import { useState } from 'react';
import { CopyCode } from "..";
import dataSourceApi from "../../api/requests/dataSourcesReqs";
import useMyQuery from "../../api/useMyQuery";
import { Day4CustomFC } from '../../types';
import './modalCopyCodeStyles.less';
const { TabPane } = Tabs;

interface ModalCopyCodePropType {
  showButton: boolean,
  buttonLabel: string,
  shellCode: string,
}

const ModalCopyCode: Day4CustomFC<ModalCopyCodePropType> = ({
  showButton,
  buttonLabel,
  shellCode,
}) => {

  // 设置默认值
  showButton = showButton ? showButton : true
  buttonLabel = buttonLabel ? buttonLabel : "Show Sample Code"
  shellCode = shellCode ? shellCode : "curl localhost"


  // console.log("shellCode", shellCode)

  const [modalVisible, setModalVisible] = useState(!showButton);

  const { data: dataSourcesToChooseResponse } = useMyQuery(['datasources'], dataSourceApi.list);


  const [form] = Form.useForm();


  // 回调区 ------------------------------------------------------

  const handleOk = async () => {
    return "copied"
  }

  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  }

  // 组件片段 ----------------------------------------------------

  const footer = (
    <>
      <Button key="ok" type="primary" onClick={handleOk}>确定</Button>
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  )


  return (
    <>
      {showButton && <Button type="default" onClick={() => setModalVisible(true)}
      >{buttonLabel}</Button>}
      <Modal
        visible={modalVisible}
        closable={true}
        maskClosable={false}
        // title={"示例代码"}
        width={800}
        footer={null}
        onCancel={handleCancel}
        className={'eet-bind-modal'}
      >
        <Tabs defaultActiveKey="2">
          <TabPane
            tab={
              <span>
                <ShellIcon />
                Shell
              </span>
            }
            key="shell"
          >
            <CopyCode language="shell" text={shellCode}></CopyCode>
          </TabPane>
        </Tabs>
      </Modal >
    </>
  )
}

export default ModalCopyCode;

