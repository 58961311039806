import ModalBindEET from './ModalBindEET'

ModalBindEET.register = {
  attributes: [
    {
      name: "namespace",
      type: "template"
    },
    {
      name: 'showButton',
      type: 'boolean'
    }
  ],
  events: [
    {
      type: 'ok'
    }
  ],
  editorConfig: {
    defaultValue: {
      showButton: true
    }
  }
}

export default ModalBindEET;
