import request from '../../sdk/request';
import env from 'day4-utils/es/env';
import {DataFrameDataType, GraphqlParamType} from "../types/DataServiceTypes";

const api = env.DAY4_APP_MODELER_GRAPHQL;

const dataServiceApi = {
  dataFramePreview (namespace: string, {query, variables}: GraphqlParamType) {
    // console.log('gql-dataframe-preview: ', query, variables);
    return request<DataFrameDataType>({
      url: `${api}/namespaces/${namespace}/data_frame`,
      method: "GET",
      params: {
        query,
        ...variables ? {variables: JSON.stringify(variables)} : {}
      },
      // mute the error popup
      silent: true
    });
  },
  gql (namespace: string, {query, variables}: GraphqlParamType) {
    return request<any>({
      url: `${api}/${namespace}/graphql`,
      method: "POST",
      data: {
        query,
        ...variables ? {variables: JSON.stringify(variables)} : {}
      }
    })
  }
}

export default dataServiceApi;
