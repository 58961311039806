import { Avatar } from 'antd';
import React, { useState } from 'react';
import { Day4CustomFC } from '../../types';
import './Collapse.less';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import { ReactComponent as SendIcon } from './send.svg';
import Icon from '@ant-design/icons';

interface Props {
  id?: string;
  no?: string;
  title?: string;
  defaultCollapse?: boolean;
  children?: React.ReactNode;
  disableCollapse?: boolean;
  linkTitle?: string;
  linkUrl?: string;
  visible?: boolean;
}

const Collapse: Day4CustomFC<Props> = ({
  id,
  no,
  title,
  defaultCollapse,
  children,
  disableCollapse,
  linkTitle,
  linkUrl,
  visible = true,
}) => {
  const [collapse, setCollapse] = useState(defaultCollapse);

  return (
    <div
      id={id}
      className="custom-collapse"
      style={{ display: visible ? undefined : 'none' }}
    >
      <div className="custom-collapse-header">
        <span className="custom-collapse-title">
          {no && <Avatar>{no}</Avatar>}
          {title}
        </span>
        <span>
          {linkTitle && (
            <a
              href={linkUrl}
              className="custom-collapse-link"
              target="_blank"
              rel="noreferrer"
            >
              <Icon component={SendIcon} style={{ marginRight: 4 }} />
              {linkTitle}
            </a>
          )}
          {!disableCollapse && (
            <Icon
              style={{
                padding: 4,
                transform: collapse ? undefined : 'rotate(180deg)',
              }}
              component={ArrowIcon}
              onClick={() => {
                setCollapse((pre) => !pre);
              }}
            />
          )}
        </span>
      </div>
      <div
        style={{
          display: collapse ? 'none' : undefined,
        }}
        className="custom-collapse-content"
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
