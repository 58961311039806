import EntityRelationDiagram from './EntityRelationDiagram'

EntityRelationDiagram.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'search',
      type: 'string'
    },
    {
      name: 'viewMode',
      type: 'select',
      options: [
        {
          label: 'expanded',
          value: 'expanded',
          default: true
        },
        {
          label: 'collapsed',
          value: 'collapsed'
        },
        {
          label: 'expanded-cross',
          value: 'expanded-cross'
        }
      ]
    }
  ],
  styles: [
    'width',
    'height'
  ]
}

export default EntityRelationDiagram
