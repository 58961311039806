import SearchInput from './SearchInput';

SearchInput.register = {
  attributes: [
    {
      name: 'value',
      type: 'StringValue',
      isTemplate: true,
    },
  ],
  styles: ['width'],
  editorConfig: {
    defaultStyle: {
      width: 'fitContent',
    },
  },
};

export default SearchInput;
