import entityApi from "../../../api/requests/entityReqs";
import { ObjectType } from "../../../api/types/CommonTypes";
import { EntityType } from "../../../api/types/EntityTypes";
import useMyQuery from "../../../api/useMyQuery";

export type ObjectNameGroup = {
  type: ObjectType,
  display: string,
  items: EntityType[]
}[]

type NamespaceQueryHookType = (namespace: string) => [result: ObjectNameGroup]

const useNamespaceObjectQuery: NamespaceQueryHookType = (namespace) => {
  const result: ObjectNameGroup = [];
  // Entity
  const {data: entityDataResponse} = useMyQuery(['entities', namespace], entityApi.list, {
    enabled: !!namespace
  });
  const entityData = (entityDataResponse?.data || []).filter(et => !et.is_external);
  result.push({
    type: 'ENTITY_TYPE',
    display: '实体类型',
    items: entityData,
  })
  // fix: add EXTERNAL_ENTITY_TYPE
  const eetData = (entityDataResponse?.data || []).filter(et => et.is_external);
  result.push({
    type: 'EXTERNAL_ENTITY_TYPE',
    display: '外部实体类',
    items: eetData
  })
  // todo VO

  return [result];
}

export default useNamespaceObjectQuery;

