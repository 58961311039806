/** @jsxImportSource @emotion/react */
import styles from 'day4-components/es/styles';
import useStyles from 'day4-components/es/hooks/useStyles';
import useDebounce from 'react-use/esm/useDebounce';
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  ConnectionLineType,
  Controls,
  Edge,
  Elements,
  Handle,
  MiniMap,
  Position,
  ReactFlowState,
  removeElements,
  useStoreState, XYPosition,
} from 'react-flow-renderer';
import {Day4CustomFC} from '../../types';
import {FC, memo} from 'react';
import {Spin} from 'antd';
import useQueryElements from './useQueryElements';
// import useDagreLayout from './useDagreLayout';
import {EntityAttributeType, EntityType} from "../../api/types/EntityTypes";
import * as localforage from "localforage";

interface ItemProps {
  // 来自哪个对象。用于拼出Handle-id
  from: string,
  attr: EntityAttributeType;
}

const Item: FC<ItemProps> = ({ from, attr }) => {
  const { name, type } = attr;
  return (
    <div
      css={[
        styles.position.relative,
        styles.padding([
          { size: '2', side: 'x' },
          { size: '0.5', side: 'y' },
        ]),
      ]}
    >
      {/* v3中，属性只出不入，不作为target */}
      {/*<Handle*/}
      {/*  type="target"*/}
      {/*  position={Position.Left}*/}
      {/*  style={{ top: '50%', transform: 'translate(0, -50%)' }}*/}
      {/*  id={`target@${from}.${name}`}*/}
      {/*/>*/}
      <div css={[styles.display.flex, styles.alignItems.center]}>
        <div css={styles.marginRight('0.5em')}>{name}</div>
        <div css={[styles.marginLeft('auto')]}>{type}</div>
      </div>
      <Handle
        type="source"
        id={`source@${from}.${name}`}
        position={Position.Right}
        style={{ top: '50%', transform: 'translate(0, -50%)' }}
      />
    </div>
  );
};

interface ReactFlowCustomNodePropType<T> {
  id: string,
  data: T,
  type: string,
  selected: boolean,
  sourcePosition: Position
  targetPosition: Position
}

const EntityNode = memo((props: ReactFlowCustomNodePropType<EntityType>) => {
  const { data, type } = props;

  const { name, display, attributes = [] } = data;

  return (
    <div
      css={[
        styles.border({ style: 'solid', width: 'default', color: 'gray.800' }),
        styles.borderRadius({ radius: 'default' }),
        styles.backgroundColor('gray.300'),
        styles.minWidth('48'),
      ]}
    >
      <div
        css={[
          styles.border({
            side: 'bottom',
            width: 'default',
            color: 'gray.800',
            style: 'solid',
          }),
          styles.padding([
            { size: '2', side: 'x' },
            { size: '1', side: 'y' },
          ]),
          styles.position.relative
        ]}
      >
        <Handle
          type="target"
          position={Position.Left}
          style={{ top: '50%', transform: 'translate(0, -50%)' }}
          id={`target@${name}`}
        />
        {name}({display})
      </div>
      {attributes.map((attr: EntityAttributeType) => (
        <Item key={attr.attribute_id} from={name} attr={attr} />
      ))}
    </div>
  );
});

export interface TextProps {
  styles?: any;
  text: string;
  onClick: (params: any) => any;
}

type UMLPropType = TextProps & {
  namespace: string
}

const UML: Day4CustomFC<UMLPropType> = ({ styles: stylesProps, namespace }) => {
  const componentCss = useStyles([], stylesProps);
  const {isLoading, elements, setElements} = useQueryElements(namespace);
  const nodes = useStoreState((state: ReactFlowState) => state.nodes);
  // useDagreLayout({
  //   flowElements: elements,
  //   paintedNodes: nodes,
  //   setElements,
  // });

  useDebounce(
    () => {
      // 保存新的 position
      // console.log(nodes)
      // fixme store the positionGuide in backend
      const positionGuide = nodes.reduce((res: Record<string, XYPosition>, item) => {
        const {id} = item;
        res[id] = item.__rf.position;
        return res;
      }, {});
      localforage.setItem<Record<string, XYPosition>>('positionGuide@' + namespace, positionGuide);
    },
    2000,
    [nodes]
  );

  const onElementsRemove = (elementsToRemove: Elements<any>) =>
    setElements((els) => removeElements(elementsToRemove, els) as Elements);
  const onConnect = (params: Edge<any> | Connection) =>
    setElements((els) => addEdge(params, els) as Elements);

  return (
    <div css={[componentCss, styles.position.relative]}>
      {isLoading && (
        <div
          css={[
            styles.position.absolute,
            styles.positionPlacement['inset-0'],
            styles.display.flex,
            styles.alignItems.center,
            styles.justifyContent.center,
          ]}
        >
          <Spin />
        </div>
      )}
      <ReactFlow
        elements={elements}
        nodeTypes={{ entity: EntityNode }}
        onElementsRemove={onElementsRemove}
        onConnect={onConnect}
        defaultZoom={0.5}
        minZoom={0.2}
        maxZoom={2}
        connectionLineType={ConnectionLineType.Bezier}
      >
        <Background variant={BackgroundVariant.Dots}
                    gap={21}
                    size={0.5}
        />
        <MiniMap
          nodeColor={(node) => {
            switch (node.type) {
              case 'entity':
                return 'rgb(220,220,255)';
              default:
                return '#eee';
            }
          }}
          nodeStrokeWidth={3}
        />
        <Controls />
      </ReactFlow>
    </div>
  );
};

UML.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    }
  ],
  styles: ['width', 'height'],
};

export default UML;
