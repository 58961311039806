import FileOutlined from "@ant-design/icons/lib/icons/FileOutlined";
import classNames from "classnames";
import cuid from "cuid";
import { useDrag, useDrop } from "react-dnd";
import styles from "./index.module.scss";

interface Props {
  className?: string
}

const NodeGroup: React.FC<Props> = (props) => {
	
	const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
		// "type" is required. It is used by the "accept" specification of drop targets.
    type: 'BOX',
		// The collect function utilizes a "monitor" instance (see the Overview for what this is)
		// to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
			display: monitor.isDragging() ? 'block' : 'none'
    }),
		item: {
			id: cuid()
		}
  }))

	return (
		<div className={classNames(props.className, styles.container)}>
			<div>UML类图</div>
			{/* <div ref={dragPreview}>123321123321</div> */}
			<div className={styles.nodePool}>
				<div role="Handle" className={styles.nodeItem}>
					<FileOutlined style={{ fontSize: '28px', cursor: "pointer"}} ref={drag}/>
					{/* {isDragging ? <div>123</div> : <div>222</div>} */}
				</div>
			</div>
		</div>
	);
}
export default NodeGroup;