import ChildrenRenderer from 'day4-modules/es/blocks/core/ChildrenRenderers';
import Collapse from './Collapse';

Collapse.register = {
  attributes: [
    {
      name: 'id',
      type: 'template',
    },
    {
      name: 'no',
      type: 'template',
    },
    {
      name: 'title',
      type: 'template',
    },
    {
      name: 'defaultCollapse',
      type: 'boolean',
    },
    {
      name: 'disableCollapse',
      type: 'boolean',
    },
    {
      name: 'linkTitle',
      type: 'template',
    },
    {
      name: 'linkUrl',
      type: 'template',
    },
    {
      name: 'visible',
      type: 'boolean',
    },
  ],
  isExpectChildren: true,
  ChildrenRenderer,
  editorConfig: {
    defaultValue: {
      no: '1',
      title: 'Title',
      defaultCollapse: false,
      url: '',
      visible: true,
    },
  },
};

export default Collapse;
