import {Day4CustomFC} from "../../types";
import {Button, message, Modal} from "antd";
import { ReactElement, useState } from "react";
import { useQuery } from "react-query"
import namespaceApi from "../../api/requests/namespaceReqs";

interface IProps {
  buttonText?: string,
  namespace: string,
  disabled: boolean,
  onOk: () => void
}

const PublishProcessButton: Day4CustomFC<IProps> = ({
    buttonText = '查看发布进度',
    disabled = true,
    namespace = "default",
    onOk
}):ReactElement => {

    const [loading, setLoading] = useState(false); 
    const handleClick = () => {
      setLoading(true); 
      namespaceApi.publishProcess(namespace).then(res => {
        message.success('发布进度:' + res?.data);
        onOk && onOk();
      }).finally(() => {
        setLoading(false);
      }); 
    }
    return (
      <Button onClick={handleClick}
              loading={loading}
              disabled={disabled}
      >{buttonText}</Button>
    )
}


export default PublishProcessButton