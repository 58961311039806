import relationApi from "../api/requests/relationReqs";
import {getByPath} from "../sdk/utils";
import {RelationDirectionType, RelationType} from "../api/types/RelationTypes";

export function relationList({dependencies}: any) {
  const namespace = getByPath(dependencies, [0, 1]);
  if (!namespace) {
    return null;
  }
  return relationApi.list(namespace);
}

export async function getRelationOf({dependencies}: any) {
  const namespace = getByPath(dependencies, [0, 1]);
  const entity = getByPath(dependencies, [1, 1]);
  if (!namespace || !entity) {
    return null;
  }
  const relationResponse = await relationApi.list(namespace);
  const relationList = relationResponse?.data || [];

  const cache = relationList.reduce((res: Record<string, RelationType[]>, item) => {
    const {
      object_type_name1: name1,
      object_type_name2: name2
    } = item;
    res[name1] = [...(res[name1] || []), item]
    // fixme self relation should count once.
    if (name1 !== name2) {
      res[name2] = [...(res[name2] || []), item]
    }
    return res;
  }, {});

  const result = cache[entity] || [];
  return result.sort(byDirection);
}

export async function getRelationBetween({dependencies}: any) {
  const namespace = getByPath(dependencies, [0, 1]);
  if (!namespace) {
    return null;
  }
  const relationResponse = await relationApi.list(namespace);
  const relationList = relationResponse?.data || [];

  const result = relationList.sort(byDirection);
  return result;

}

// --------------------------- 关系排序算法 ----------------------------

type Comparator<T> = (a: T, b: T) => number

const byDirection = compose(directionComparator, nameComparator);

function compose<T> (...funcs: Comparator<T>[]): Comparator<T> {
  return (a, b) => {
    for (let i = 0; i < funcs.length; i++) {
      const func = funcs[i];
      const result = func(a, b);
      if (result !== 0) {
        return result;
      } else {
        // no-op
      }
    }
    return 0;
  }
}

function directionComparator (a: RelationType, b: RelationType) {
  const dict: Record<RelationDirectionType | 'UNKNOWN', number> = {
    "BI_DIRECTION": 1,
    "LEFT2RIGHT": 2,
    "RIGHT2LEFT": 3,
    "UNKNOWN": 100
  }
  const aa = dict[a.relation_direction || 'UNKNOWN'] || 99;
  const bb = dict[b.relation_direction || 'UNKNOWN'] || 99;

  return aa - bb;

}

function nameComparator (a: RelationType, b: RelationType) {
  const aa = a.name;
  const bb = b.name;
  if (aa < bb) {
    return -1;
  } else if (aa > bb) {
    return 1
  } else {
    return 0
  }
}
