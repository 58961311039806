import ModalUpdateRelation from "./ModalUpdateRelationship";

ModalUpdateRelation.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'relationName',
      type: 'template'
    },
    {
      name: 'disabled',
      type: 'boolean'
    }
  ],
  events: [
    {
      type: 'ok'
    }
  ],
  editorConfig: {
    defaultValue: {
      disabled: false
    }
  }
}

export {ModalUpdateRelation}
