import * as coreBlocks from 'day4-modules/es/blocks/core';
import * as integrateBlocks from 'day4-modules/es/blocks/integrates';
import datasources from 'day4-modules/es/datasources';
import { mergeBlocks } from 'day4-utils/es/registrar';
import * as customComponents from './components';
import * as customEventHandlers from './events';
import * as customQueries from './queries';


const registry = Object.entries(customComponents).map(([name, Comp]: any) => ({
  type: `custom/${name}`,
  category: 'Custom',
  component: Comp,
  ...Comp.register,
}));

const eventHandlers = Object.entries(customEventHandlers).map(
  ([name, handler]) => ({
    name,
    handler,
  })
);

const queries = Object.entries(customQueries).map(([name, query]) => ({
  name,
  query,
}));

// 定制core block的属性
var coreTree = coreBlocks.registry.find(block => {
  return block.type === "core/Tree"
})
if (coreTree !== undefined) {
  coreTree.attributes?.push({ name: 'disabled', type: 'boolean' })
  coreTree.attributes?.push({ name: 'defaultSelectedKeys', type: 'json' })
}


export default {
  datasources: [...datasources],
  blocks: mergeBlocks(coreBlocks, integrateBlocks, {
    registry,
  }),
  eventHandlers,
  queries,
};
