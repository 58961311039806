import ModalCopyCode from './ModalCopyCode';

ModalCopyCode.register = {
  attributes: [
    {
      name: 'showButton',
      type: 'boolean',
    },
    {
      name: 'buttonLabel',
      type: 'string',
    },
    {
      name: 'shellCode',
      type: 'string',
    },
  ],
  events: [
    {
      type: 'ok',
    },
  ],
  editorConfig: {
    defaultValue: {
      showButton: true,
      buttonLabel: '示例代码',
      shellCode: 'curl localhost',
    },
  },
};

export default ModalCopyCode;
