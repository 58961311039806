import ChildrenRenderer from 'day4-modules/es/blocks/core/ChildrenRenderers';
import CustomWidthContainer from './CustomWidthContainer';

CustomWidthContainer.register = {
  attributes: [
    {
      name: 'width',
      type: 'number',
    },
    {
      name: 'maxWidth',
      type: 'number',
    },
    {
      name: 'minWidth',
      type: 'number',
    },
  ],
  isExpectChildren: true,
  ChildrenRenderer,
  styles: [
    'height',
    'maxHeight',
    'minHeight',
    'border',
    'borderRadius',
    'backgroundColor',
    'textColor',
    'backgroundImage',
    'backgroundPosition',
    'backgroundSize',
    'position',
    'positionPlacement',
    'flex',
    'flexWrap',
    'alignItems',
    'justifyContent',
    'overflow',
    'shadow',
    'cursor',
    'opacity',
    'zIndex',
  ],
};

export default CustomWidthContainer;
