import request from '../../sdk/request';
import env from 'day4-utils/es/env';
import { TagType } from '../types/TagTypes';

const api = env.DAY4_APP_MODELER_META_API;

const tagApi = {
  list () {
    return request<Array<TagType>>({
      url: `${api}/tags`
    });
  },
}

export default tagApi;
