import {Day4CustomFC} from "../../types";
import {Button, Form, Input, message, Modal} from "antd";
import {useState} from "react";
import {useMutation} from "react-query";
import {EntityType} from "../../api/types/EntityTypes";
import entityApi from "../../api/requests/entityReqs";
import useMyQuery from "../../api/useMyQuery";

interface ModalUpdateEntityPropType {
  namespace: string,
  entity: string,
  onOk: () => void,
  disabled?: boolean
}

const ModalUpdateEntity: Day4CustomFC<ModalUpdateEntityPropType> = ({
  namespace,
  entity,
  onOk,
  disabled = false
}) => {
  const [modalShown, setModalShown] = useState(false);
  const [form] = Form.useForm();

  const {
    data: entityResponse,
    isLoading
  } = useMyQuery(['entity-info', namespace, entity], entityApi.detail);

  const initialValue = entityResponse?.data;

  const mutationUpdateEntity =
    useMutation((newEntity: EntityType) => entityApi.update(newEntity));

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      // 执行mutation，使用异步方法，成功则返回ResponseBody，失败则抛出异常
      const result = await mutationUpdateEntity.mutateAsync({
        namespace,
        old_name: entity,
        ...values,
        labels: {}
      });
      // result可能为undefined。如有data，表示成功
      if (result?.data) {
        message.success('修改成功！');

        form.resetFields();
        setModalShown(false);
        // 调用Day4-event
        onOk && onOk();
        return result.data.name;
      }
    } catch (e) {
      // no-op
    }
  }

  const handleCancel = () => {
    form.resetFields();
    setModalShown(false);
  }

  const footer = (
    <>
      <Button key="ok" type="primary" onClick={handleOk}>确定</Button>
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  )

  return (
    <>
      <Button type={"primary"} disabled={disabled}
              loading={isLoading}
              onClick={() => setModalShown(true)}
      >编辑实体信息</Button>
      <Modal visible={!disabled && modalShown}
             title={"编辑实体信息"} footer={footer}
             closable={false} maskClosable={false}
      >
        <Form
          name="updateEntity"
          form={form}
          labelCol={{span: 4}}
          initialValues={initialValue}
        >
          <Form.Item
            label="名称"
            name="name"
            rules={[
              {required: true, message: '请输入名称'}
            ]}
          ><Input placeholder={"例如:contacts_01"}/></Form.Item>
          <Form.Item
            label="显示名"
            name="display"
            rules={[
              {required: true, message: '请输入显示名'}
            ]}
          ><Input placeholder={"例如:联系人"}/></Form.Item>
          <Form.Item
            label="描述"
            name="desc"
            rules={[
              {type: "string", max: 128}
            ]}
          ><Input.TextArea rows={3}
                           placeholder={"例如:联系人列表"}/></Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ModalUpdateEntity;
