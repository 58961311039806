import React from 'react';
import ReactPlayer from 'react-player';
import { Day4CustomFC } from '../../types';

interface Props {
  url?: string;
  width?: number;
  height?: number;
}

const Video: Day4CustomFC<Props> = ({ url, width, height }) => {
  return <ReactPlayer width={width} height={height} url={url} />;
};

export default Video;
