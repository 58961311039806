import { InfoCircleFilled } from '@ant-design/icons';
import { message, Modal } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Day4CustomFC } from '../../types';
import Card, { CardFields } from './Card';
import './CardList.less';
import UserCollection from './UserCollection';

interface Props {
  data: CardFields[];
  homeUrl?: string;
}

const CardList: Day4CustomFC<Props> = ({ data, homeUrl }) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [currentCase, setCurrentCase] = useState<CardFields>();

  const handleClick = (record: CardFields) => {
    if (window.username) {
      if (record.wip) {
        message.info({
          content: '内容整理中',
          icon: <InfoCircleFilled style={{ color: '#1f4055' }} />
        });
      } else {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        history.push(`/cases/${record.name}`);
      }
    } else {
      setCurrentCase(record);
      setVisible(true);
    }
  };

  return (
    <div className="card-list">
      {data?.map?.((x) => (
        <Card key={x.id} data={x} onClick={() => handleClick(x)} />
      ))}
      {(data?.length || 0) % 3 === 2 && <div></div>}

      <Modal
        className="card-user-modal"
        title="身份信息确认"
        visible={visible}
        onCancel={(e) => {
          e.stopPropagation();
          setVisible(false);
        }}
        footer={null}
        width={640}
      >
        <UserCollection
          homeUrl={homeUrl}
          currentCase={currentCase}
          onClose={() => setVisible(false)}
        />
      </Modal>
    </div>
  );
};

export default CardList;
