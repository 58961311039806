import request from '../../sdk/request';
import env from 'day4-utils/es/env';
import {DataSourceType, EETBindInfo, DataSourceCategory} from "../types/DataSourceTypes";
import {EETType} from "../types/EntityTypes";

const api = env.DAY4_APP_MODELER_META_API;

const dataSourceApi = {
  list () {
    return request<DataSourceType[]>({
      url: `${api}/data_sources`
    })
  },
  eets (dataSource: string) {
    return request<EETType[]>({
      url: `${api}/data_sources/${dataSource}/eets`
    })
  },
  bindEET (bindInfo: EETBindInfo) {
    const {namespace} = bindInfo;
    return request<any>({ // todo add types
      url: `${api}/namespaces/${namespace}/eets`,
      method: "POST",
      data: {
        data_source: bindInfo.data_source,
        name: bindInfo.name
      }
    })
  },
  reload (dataSource: string) {
    return request<any>({
      url: `${api}/data_sources/${dataSource}/_reload`,
      method: "POST"
    })
  },
  exportData (namespace: string) {
    return request<any>({
      url: `${api}/namespaces/${namespace}/_batch`,
      method: "GET"
    }, true).then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${namespace} 元数据`;
      a.click();
    });
  },
  exportTemplate (namespace: string) {
    return request<any>({
      url: `${api}/namespaces/${namespace}/excel_template`,
      method: "GET"
    }, true).then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${namespace} 模板`;
      a.click();
    });
  },

  create (dataSourceType: DataSourceType) {
    return request<DataSourceType>({
      url: `${api}/data_sources`,
      method: "POST",
      data: dataSourceType,
    })
  },

  categories () {
    return request<DataSourceCategory[]>({
      url: `${api}/data_source_types`,
      method: "GET",
    })
  }
}

export default dataSourceApi;
