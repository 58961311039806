import {Day4CustomFC} from "../../types";
import namespaceApi from "../../api/requests/namespaceReqs";

interface YAMLMappingExporterPropType {
  namespace: string,
  text: string
}

const YAMLMappingExporter: Day4CustomFC<YAMLMappingExporterPropType> = ({
  namespace,
  text
}) => {

  const handleClick = (e: any) => {
    e.stopPropagation();
    namespaceApi.exportMapping(namespace);
  }
  
  return (
    <a href='javascript:;' onClick={handleClick}>{text}</a>
  )
}

export default YAMLMappingExporter;
