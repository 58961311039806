import React from 'react';
import { Day4CustomFC } from '../../types';
import './TextRadio.less';

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  value: string;
  onChange: (val: string) => void;
}

const TextRadio: Day4CustomFC<Props> = (props) => {
  const { options, value, onChange } = props;

  const handleChange = (val: string) => {
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <span>
      {options?.map((x) => (
        <div
          key={x.value}
          className={`text-radio ${
            JSON.stringify(value) === JSON.stringify(x.value) ? 'text-radio-selected' : ''
          }`}
          onClick={() => handleChange(x.value)}
        >
          {x.label}
        </div>
      ))}
    </span>
  );
};

export default TextRadio;
