/** @jsxImportSource @emotion/react */
import { X6Diagram } from "..";
import { css } from '@emotion/react';
import CellLib from "./CellLib";
import styles from "./index.module.scss";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ConfigPub from "./ConfigPub";
import { createContext, useState } from "react";

export const SelectedCell = createContext<any>({}); 

export const GraphContainer = createContext<any>({});

const EntityRelation = ({ namespace = 'lzh_test' }) => {

	const [cell, setCell] = useState();

	const [globalGraph, setGlobalGraph] = useState();

	return (
	<div css={css`
		width: 100%;
		height: 100%;
		display: flex;
		position: relation;
	`} >
		<SelectedCell.Provider value={{cell, setCell}}>
			<GraphContainer.Provider value={{globalGraph, setGlobalGraph}}>
				<DndProvider backend={HTML5Backend}>
					<CellLib className={styles.cellContainer}/>
					<X6Diagram namespace={namespace}></X6Diagram>
					<ConfigPub namespace={namespace}></ConfigPub>
				</DndProvider>
			</GraphContainer.Provider>
		</SelectedCell.Provider>
	</div>);
}

EntityRelation.register = {
	attributes: [
		{
			name: 'namespace',
			type: 'string',
		},
	],
	styles: ['width', 'height'],
};

export default EntityRelation;