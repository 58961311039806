import DraftManager from "./DraftManager";

DraftManager.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    }
  ],
  events: [
    {
      type: 'ok'
    }
  ]
}

export default DraftManager;
