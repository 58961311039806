import { Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { graphPosToZoomedPos } from "react-flow-renderer/dist/utils/graph";
import { GraphContainer, SelectedCell } from "..";
import entityApi from "../../../api/requests/entityReqs";
import namespaceApi from "../../../api/requests/namespaceReqs";
import relationApi from "../../../api/requests/relationReqs";
import { DraftsType } from "../../../api/types/CommonTypes";
import styles from "./index.module.scss";

const ConfigPub = ({namespace = ''}) => {
	const selectedCell = useContext(SelectedCell);
	const entityName = selectedCell?.cell?.node?.id;//实体
	const edgeName = selectedCell?.cell?.edge?.id;//连线关系
	const blank = selectedCell?.cell?.blank;//空白处

	const [namespaceInfo, setNamespaceInfo] = useState<any>();
	const [entityInfo, setEntityInfo] = useState<any>();
	const [relationInfo, setRelationInfo] = useState<any>();
	const [neededPublish, setNeededPublish] = useState<any>();

  const {globalGraph, setGlobalGraph} = useContext(GraphContainer);

	// const publishState = {
	// 	'PUBLISH_TODO': "发布中",
	// 	'PUBLISH_DONE': "已发布",
	// 	'DRAFT': "未发布",
	// 	'PUBLISH_UNDO': "取消发布中"
	// };

	// 初始化加载namespace信息
	useEffect(() => {
		namespace && namespaceApi.detail(namespace).then((res: any) => {
			setNamespaceInfo(res?.data);
		});

		namespace && namespaceApi.listDrafts(namespace).then((res: any) => {
			setNeededPublish(res?.data);
		});
	}, [namespace]);

	useEffect(() => {
		(entityName && namespace) 
		? entityApi.detail(namespace, entityName).then((res: any) => {
			setEntityInfo(res?.data);
		})
		: setEntityInfo(undefined);
		
		(edgeName && namespace)
		? relationApi.detail(namespace, edgeName).then((res: any) => {
			setRelationInfo(res?.data);
		})
		: setRelationInfo(undefined);

		(blank && namespace) 
		? namespaceApi.detail(namespace).then((res: any) => {
			setNamespaceInfo(res?.data);
		})
		: setNamespaceInfo(undefined);

	}, [entityName, edgeName, blank, namespace]);

	// 实体的发布
	const handleEntityCommit = (entity: any) => {
		let obj: DraftsType = {
			name: namespace,
			entity_types: [entity],
			relation_types: []
		};
		namespaceApi.commit(obj).then((res: any) => {
			// console.log(res);
			// 成功时更新画布
		});
	}

	// namespace的发布
	const handleNamespaceCommit = (all: any) => {
		let obj: DraftsType = all;
		namespaceApi.commit(obj).then((res: any) => {
			// console.log(res);
			// 成功时更新画布
		});
	}

	// relation的发布
	const handleRelationCommit = (rel: any) => {
		let obj: DraftsType = {
			name: namespace,
			entity_types: [],
			relation_types: [rel]
		};
		namespaceApi.commit(obj).then((res: any) => {
			console.log(res);
			// 成功时更新画布
		});
	}

	// relation的取消发布
	const handleRelUnpublish = (rel: any) => {
		namespaceApi.unpublishRel(namespace, rel.name).then((res: any) => {
			console.log(res);
		});
	}

	// entity的取消发布
	const handleEntityUnpublish = (entity: any) => {
		namespaceApi.unpublishEntity(namespace, entity.name).then((res: any) => {
			console.log(res);
		});
	}

	// namespace的取消发布
	const handleNamespaceUnpublish = () => {
		namespaceApi.unpublishNamespace(namespace).then((res: any) => {
			console.log(res);
		});
	}
	

	return (
		<div className={styles.container}>
			{entityInfo && (
				<div>
					<p className={styles.colorLabel}>
						<span className={styles.stateOne}></span><span>已发布</span>
						<span className={styles.stateTwo}></span><span>未发布</span>
						<span className={styles.stateThree}></span><span>发布中/取消发布中</span>
					</p>
					<p className={styles.title}>Selected Entity Infomation</p>
					<p>实体名称：{entityName}</p>
					<p className={styles.noBlank}>发布状态：{
						entityInfo.state === 'PUBLISH_TODO' 
						? "发布中"
						: entityInfo.state === 'PUBLISH_DONE' 
							? "已发布"
							: entityInfo.state === 'DRAFT' 
								? "未发布"
								: entityInfo.state === 'PUBLISH_UNDO'
									? "取消发布中" 
									: ''
								}
						{entityInfo.state === 'DRAFT' && <Button size={"small"} type="primary" style={{marginLeft: "10px"}} onClick={() => handleEntityCommit(entityInfo)}>发布</Button>}
						{entityInfo.state === 'PUBLISH_DONE' && <Button size={"small"} type="primary" danger style={{marginLeft: "10px"}} onClick={() => handleEntityUnpublish(entityInfo)}>取消发布</Button>}
					</p>
					{entityInfo?.attributes?.length && (
						<div>
							<p className={styles.noBlank}>实体属性：</p>
							{entityInfo?.attributes.map((item: any) => (<p>{item.name}</p>))}
						</div>
					)}
				</div>)
			}
			{namespaceInfo && (
				<div>
					<p className={styles.title}>Namespace Information</p>
					<p>命名空间：{namespaceInfo.name}</p>
					<p>显示名：{namespaceInfo.display}</p>
					<p>描述：{namespaceInfo.desc}</p>
					<p>标签：{namespaceInfo.state}</p>
					<p className={styles.noBlank}>发布状态：{
						namespaceInfo.state === 'PUBLISH_TODO' 
						? "发布中"
						: namespaceInfo.state === 'PUBLISH_DONE' 
							? "已发布"
							: namespaceInfo.state === 'DRAFT' 
								? "未发布"
								: namespaceInfo.state === 'PUBLISH_UNDO'
									? "取消发布中" 
									: ''
					}
					</p>
					{(!!neededPublish?.entity_types?.length || !!neededPublish?.relation_types?.length) && <Button size={"small"} type="primary" style={{marginLeft: "10px"}} onClick={() => handleNamespaceCommit(neededPublish)}>更新发布</Button>}
					{namespaceInfo.state === 'PUBLISH_DONE' && <Button size={"small"} type="primary" danger style={{marginLeft: "10px"}} onClick={() => handleNamespaceUnpublish()}>取消发布</Button>}
					<p className={styles.someblank}>共享模式：{
						namespaceInfo.access_mode === 'UNSHARE' 
						? "未共享" 
						: namespaceInfo.access_mode === 'RDONLY' 
							? "只读" 
							: namespaceInfo.access_mode === 'RDWR' 
								? "读写" 
								: "" 
					}</p>
					<p>创建时间：{namespaceInfo.created_at}</p>
					<p>创建者：{namespaceInfo.created_user}</p>
					<p>修改时间：{namespaceInfo.updated_at}</p>
					<p>修改者：{namespaceInfo.updated_user}</p>
				</div>
			)}
			{relationInfo && (
				<div>
					<p className={styles.colorLabel}>
						<span className={styles.relStateOne}></span><span>已发布</span>
						<span className={styles.relStateTwo}></span><span>未发布</span>
						<span className={styles.relStateThree}></span><span>发布中/取消发布中</span>
					</p>
					<p className={styles.title}>Selected Relation Information</p>
					<p>关系名称：{relationInfo.name}</p>
					<p>类型1：{relationInfo.object_type_name1}</p>
					<p>数量关系：{
						relationInfo.relation_multiplicity === "MANY2MANY" 
						? 'n:n' 
						: relationInfo.relation_multiplicity === "ONE2MANY" 
							? '1:n' 
							: relationInfo.relation_multiplicity === "ONE2ONE" 
								? '1:1' 
								: relationInfo.relation_multiplicity === "MANY2ONE"
									? 'n:1'
									: ''
					}</p>
					<p>类型2：{relationInfo.object_type_name2}</p>
					<p>关系类型：{
						relationInfo.relation_type === 'ASSOCIATION'
						? "弱关系" 
						: relationInfo.relation_type === 'COMPOSITION'
							? "强关系"
							: ''
					}</p>
					<p>关联属性个数：{relationInfo.attributes.length}</p>
					<p>访问方向：{
						relationInfo.relation_direction === 'BI_DIRECTION'
						? "双向"
						: relationInfo.relation_direction === 'LEFT2RIGHT'
							? "单向-正向"
							: relationInfo.relation_direction === 'RIGHT2LEFT'
								? "单向-反向(不可访问)"
								:	''
					}</p>
					<p>状态：{
						relationInfo.state === "PUBLISH_TODO"
						? "发布中"
						: relationInfo.state === "PUBLISH_DONE"
							? "已发布"
							: relationInfo.state === "DRAFT"
								? "未发布"
								: relationInfo.state === "PUBLISH_UNDO"
									? "取消发布中"
									: ''
					}
					{relationInfo.state === 'DRAFT' && <Button size={"small"} type="primary" style={{marginLeft: "10px"}} onClick={() => handleRelationCommit(relationInfo)}>发布</Button>}
					{relationInfo.state === 'PUBLISH_DONE' && <Button size={"small"} type="primary" danger style={{marginLeft: "10px"}} onClick={() => handleRelUnpublish(relationInfo)}>取消发布</Button>}
					</p>
				</div>
			)}
		</div>
	)
};
export default ConfigPub;