import { message } from 'antd';
import copy from 'copy-to-clipboard';

// 拷贝模板内容点击事件
export const template_copy_body = (params: any) => {
  const body: string = params.state.record.body
  copy(body)
  message.success("复制成功")
};

// 拷贝模板内容点击事件
export const template_copy_variables = (params: any) => {
  const variables = params.state.record.variables
  let v: string = ''
  if (variables) {
    v = JSON.stringify(variables)
  }
  copy(v)
  message.success("复制成功")
};

// 点击详情跳转值iGraphQL
export const template_detail = (params: any) => {
  const ns = params.state.match.params.ns
  const tpl = params.state.record

  const pathname = "/namespaces/" + ns + `/graphiql`

  params.appMethods.push({
    pathname: pathname, 
    state: {
      namespace: ns,
      query: tpl.body,
      variables: tpl.variables,
    },
    search: `query=${tpl.body}&variables=${JSON.stringify(tpl.variables)}&template=${params.state.record.name || ''}&desc=${params.state.record.desc || ''}`
  })
}
