import DeleteButton from "./DeleteButton";

DeleteButton.register = {
  attributes: [
    {
      name: 'url',
      type: 'template'
    },
    {
      name: 'method',
      type: 'select',
      options: [
        {
          label: 'GET',
          value: 'GET'
        },
        {
          label: 'POST',
          value: 'POST'
        },
        {
          label: 'PUT',
          value: 'PUT'
        },
        {
          label: 'DELETE',
          value: 'DELETE'
        }
      ]
    },
    {
      name: 'message',
      type: 'template'
    },
    {
      name: 'successMessage',
      type: 'template'
    },
    {
      name: 'buttonText',
      type: 'template'
    },
    {
      name: 'requestBody',
      type: 'json'
    },
    {
      name: 'disabled',
      type: 'boolean'
    }
  ],
  events: [
    {
      type: 'ok'
    }
  ],
  editorConfig: {
    defaultValue: {
      method: 'DELETE',
      buttonText: '删除',
      successMessage: '删除成功',
      disabled: false
    }
  }
}

export default DeleteButton;
