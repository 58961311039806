import EdgeGroup from "./EdgeGroup";
import NodeGroup from "./NodeGroup";
import styles from './index.module.scss';
import classNames from 'classnames'

interface Props {
	className: string;
}

const CellLib: React.FC<Props> = (props) => {
	return (<div className={classNames(styles.container, props.className)}>
		<NodeGroup className={styles.nodeContainer}/>
		{/* <EdgeGroup className={styles.edgeContainer}/> */}
	</div>);
}
export default CellLib;

