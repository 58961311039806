import { AttributesValidator } from '../types';

const checkIdSetting: AttributesValidator = {
  name: 'checkIdSetting',
  desc:
    '查看每一个属性的is_primary，并给出友好的提示（这个实体的id是用什么方式生成的）',
  validate(attrList, knowledge) {
    const primaryKeyAttributes = attrList.filter(attr => {
      // 非id的属性的计数
      return attr.is_primary;
    });
    const pkCount = primaryKeyAttributes.length;
    if (pkCount === 0) {
      // 0个 -> 托管id
      return {
        skip: false,
        validated: true,
        messages: [
          {
            type: 'info',
            message: '当前类型没有选定ID属性。系统将自动为对象生成ID。',
          },
        ],
      };
    } else if (pkCount === 1) {
      // 1个 -> 单属性id，系统会自动设置unique
      const thatAttribute = primaryKeyAttributes[0];
      const { name, unique } = thatAttribute;
      if (!unique) {
        return {
          skip: false,
          validated: true,
          messages: [],
        };
        // return {
        //   skip: false,
        //   validated: false,
        //   messages: [
        //     {
        //       type: 'warning',
        //       message:
        //         '选定了一个ID属性，但是它的值不是唯一的。请考虑设置唯一性，或者增加另一个ID属性。',
        //       referring: thatAttribute,
        //     },
        //   ],
        // };
      } else {
        return {
          skip: false,
          validated: true,
          messages: [],
        };
      }
    } else {
      // 多个 -> 多属性id
      // 如果有id存在，提示会被自动删除
      var id = primaryKeyAttributes.filter(attr => {
        return attr.name === 'id';
      });
      return id
        ? {
            skip: false,
            validated: true,
            messages: [
              {
                type: 'warning',
                message: '你设置了自己的ID属性，托管ID属性id将被自动删除',
                // referring: thatAttribute,
              },
            ],
          }
        : {
            skip: false,
            validated: true,
            messages: [],
          };
    }
  },
};

export default checkIdSetting;
