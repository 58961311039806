import Anchor from './Anchor';

Anchor.register = {
  attributes: [
    {
      name: 'options',
      type: 'json',
    },
    {
      name: 'otherInfoVisible',
      type: 'boolean',
    },
  ],
  editorConfig: {
    defaultValue: {
      options: [
        {
          key: '1',
          title: '菜单1',
        },
        {
          key: '2',
          title: '菜单2',
        },
      ],
      otherInfoVisible: true,
    },
  },
};

export default Anchor;
