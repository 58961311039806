import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { ss } from '../../k2assets-auth';
import './UserCollection.less';
import successSrc from './success.png';
import { CardFields } from './Card';
import request from '../../sdk/request';
import client from '../../sdk/graphql/client';
import { gql } from '@apollo/client';

interface Props {
  currentCase?: CardFields;
  homeUrl?: string;
  onClose: () => void;
}

const UserCollection: React.FC<Props> = (props) => {
  const { currentCase, homeUrl, onClose } = props;
  const [success, setSuccess] = useState(false);
  console.log(process.env.NODE_ENV, 'process.env.NODE_ENV');
  const onFinish = (values: any) => {
    const formData = new FormData();
    formData.append('msid', '2');
    formData.append('mes[text-i1]', values.name);
    formData.append('mes[mobile-i2]', values.telephone);
    formData.append('mes[text-i4]', values.company);
    formData.append('mes[email-i3]', values.email);
    formData.append('mes[textarea-i5]', values.message);

    const request1 = request({
      url: '/index.php',
      params: {
        _m: 'new_message_form',
        _a: 'savemes',
      },
      data: formData,
      silent: true,
      method: 'POST',
    }).then(() => {
      setSuccess(true);
    });

    const request2 = client
      .mutate({
        mutation: gql`
          mutation m1($data: newcontactRequest) {
            createcontact(data: $data) {
              id
              name
            }
          }
        `,
        variables: {
          data: {
            ...values,
            cases: [
              {
                id: currentCase?.id,
              },
            ],
          },
        },
      })
      .then(() => {
        setSuccess(true);
      });

    Promise.any([request1, request2])
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        message.error('提交失败，请稍后再试!');
      });
  };

  const signIn = () => {
    ss.signIn();
  };

  return (
    <div className="user-collection">
      {success ? (
        <div style={{ textAlign: 'center' }}>
          <img src={successSrc} alt="" style={{ marginTop: 40 }} />
          <div style={{ marginTop: 48, fontSize: 24, color: '#1F4055' }}>
            您填写的信息已经收到!
          </div>
          <div style={{ marginTop: 24, fontSize: 18, color: '#1F4055' }}>
            我们的业务代表会根据您留下的联系方式尽快联系您提供更详尽的案例信息,您可以继续浏览K2Data案例展廊
            或者{' '}
            <a
              href={homeUrl}
              style={{ color: '#1F4055', textDecoration: 'underline' }}
            >
              回到首页
            </a>
            。
          </div>
          <Button
            style={{ marginTop: 48 }}
            type="primary"
            size="large"
            onClick={onClose}
          >
            知道了
          </Button>
        </div>
      ) : (
        <>
          <div className="user-collection-mark">
            提交身份确认信息后，待管理员审核完成可继续浏览详细案例
          </div>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            colon={false}
          >
            <Form.Item
              label="姓名"
              name="name"
              rules={[{ required: true, message: '请输入姓名' }]}
            >
              <Input placeholder="请输入姓名" style={{ width: 280 }} />
            </Form.Item>

            <Form.Item
              label="电话"
              name="telephone"
              rules={[
                { required: true, message: '请输入电话' },
                {
                  pattern:
                    /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57]|199)[0-9]{8}$/,
                  message: '请输入正确的电话号码',
                },
              ]}
            >
              <Input placeholder="请输入电话" style={{ width: 280 }} />
            </Form.Item>
            <Form.Item
              label="公司"
              name="company"
              rules={[{ required: true, message: '请输入公司名称' }]}
            >
              <Input placeholder="请输入公司名称" style={{ width: 280 }} />
            </Form.Item>
            <Form.Item
              label="企业邮箱"
              name="email"
              rules={[
                { required: true, message: '请输入企业邮箱' },
                { type: 'email', message: '请输入正确邮箱' },
              ]}
            >
              <Input placeholder="请输入企业邮箱" />
            </Form.Item>
            <Form.Item label="留言" name="message">
              <Input.TextArea
                showCount
                maxLength={100}
                placeholder="欢迎通过留言向我们反馈"
                style={{
                  height: 108,
                }}
              />
            </Form.Item>
            <div className="user-collection-btns">
              <Button onClick={signIn} size="large" type="link">
                已有账号?去登录
              </Button>
              <Form.Item noStyle>
                <Button type="primary" size="large" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default UserCollection;
