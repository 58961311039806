/** @jsxImportSource @emotion/react */
import { Button, Col, message, Modal, Row } from "antd";
import styles from 'day4-components/es/styles';
import { useEffect, useState } from "react";
import entityApi from "../../api/requests/entityReqs";
import namespaceApi from "../../api/requests/namespaceReqs";
import useMyQuery from "../../api/useMyQuery";
import { Day4CustomFC } from "../../types";
import AttributeUpsertForm from "./components/AttributeUpsertForm";
import WarningArea from "./components/WarningArea";
import useAttributesValidation from "./hooks/useAttributesValidation";
import useSortableAttributeEntries from "./hooks/useSortableAttributeEntries";
import './modalUpsertAttributesStyles.less';
import { AttributeType } from "./types";



interface ModalUpsertAttributesPropType {
  namespace: string,
  entity: string,
  showButton: boolean
  disabled?: boolean
  onClose: () => void
  onCancel: () => void
}

const ModalUpsertAttributes: Day4CustomFC<ModalUpsertAttributesPropType> = ({
  namespace,
  entity,
  showButton,
  disabled = false,
  onClose,
  onCancel
}) => {

  const [modalShown, setModalShown] = useState(!showButton);
  // 属性列表
  const [attrList, setAttrList] = useState<AttributeType[]>([
    /*{
      id: 1,
      name: 'attr1',
      display: '属性1',
      type: 'string',
      nullable: true,
      unique: true,
      category: 'SYSTEM',
      is_primary: true
    },
    {
      id: 2,
      name: 'attr2',
      display: '属性2',
      type: 'int',
      nullable: false,
      unique: false,
      category: 'SYSTEM',
      is_primary: false
    },
    {
      id: 3,
      name: 'attr3',
      display: '属性3',
      type: 'datetime',
      nullable: false,
      unique: false,
      category: 'SYSTEM',
      is_primary: false
    },
    ...([4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((id: number) => ({
      id: id,
      name: 'attr' + id,
      display: '属性' + id,
      type: 'datetime',
      nullable: false,
      unique: false,
      category: 'CUSTOM',
      is_primary: false
    } as any)))*/
  ]);

  // 获取左侧拖拽列表
  const [attributeEntries, attrEditing, setAttrEditing] =
    useSortableAttributeEntries(attrList, setAttrList);

  // 获取namespace定义的数据类型
  const { data: dataTypeResponse, isLoading: isDataTypesLoading } =
    useMyQuery(['dataTypes', namespace], namespaceApi.listDataTypes);
  const dataTypes = dataTypeResponse?.data || [];

  // 对属性列表做校验
  const [validated, warnings] = useAttributesValidation(attrList, {
    dataTypes: dataTypes
  });


  // 初始化属性列表 -----------------------------------------------------
  useEffect(() => {
    if (!modalShown) {
      // fetch on every opening
      return
    }
    entityApi.detail(namespace, entity).then(result => {
      if (result) {
        const { attributes = [] } = result.data;
        const list = attributes.map((attr, index) => {
          return {
            id: index,
            ...attr
          }
        });
        setAttrList(list);
      } else {
        console.error('no data returned')
      }
    }).catch(error => {
      console.error('query failed');
    })
  }, [entity, modalShown, namespace])

  // 回调区 -----------------------------------------------------

  const handleCreateNewAttribute = () => {
    // fixme: find a good way to generate new attr
    const newId = attrList.reduce((max, item) => {
      return Math.max(max, +item.id)
    }, 0) + 1;
    const newAttr: AttributeType = {
      id: newId,
      // attribute_id: undefined,  // 留空，表示新属性
      name: 'attr' + newId,
      display: '属性' + newId,
      category: 'CUSTOM',
      type: undefined,
      nullable: true,
      unique: false,
      is_primary: false
    }
    setAttrList([...attrList, newAttr]);
    setAttrEditing(newAttr);
    // 将新创建的节点滚动至可见区域
    setTimeout(() => {
      // 属性节点的id，定义在useSortableAttributeEntries.tsx里面
      document.getElementById(`attribute-entry-${newId}`)?.scrollIntoView();
    }, 200);
  }

  const handleOk = () => {
    // message.success('ready to upsert attributes.');

    // console.log('attribute list -> ', attrList);
    // return; // 调试用

    entityApi.upsertAttributes(namespace, entity, attrList).then(res => {
      if (res?.data) {
        // success
        message.success('属性列表更新成功!');
        setModalShown(false);
        setAttrEditing(null);
        onClose && onClose();
      }
    }).catch(err => {
      // no-op
    });
  }

  const handleCancel = () => {
    setModalShown(false);
    setAttrEditing(null);
    onCancel && onCancel();
  }

  const updateAttr = (attr: AttributeType) => {
    setAttrEditing(attr);
  }

  // 组件片段 ---------------------------------------------------

  const title = <span style={{ fontWeight: 'bold' }}>{entity + ' > 属性编辑'}</span>;

  const leftPaneHeader = (
    <div className={"left-pane-header"}>
      <span className={"left-pane-header-cell"} css={styles.width('7rem')}>名称</span>
      <span className={"left-pane-header-cell"} css={styles.width('6rem')}>显示名</span>
      <span className={"left-pane-header-cell"} css={styles.width('6.5rem')}>值类型与参数</span>
      <span className={"left-pane-header-cell"} css={styles.width('4rem')}>默认值</span>
      <span className={"left-pane-header-cell"} css={styles.width('2.5rem')}>必填</span>
      <span className={"left-pane-header-cell"} css={styles.width('3rem')}>ID属性</span>
      <span className={"left-pane-header-cell"} css={styles.width('3rem')}>唯一</span>
    </div>
  )

  // 表格底部的内容（目前是一个按钮，点击创建新属性）
  const leftPaneFooter = (
    <Button block onClick={handleCreateNewAttribute}>
      <span>+ 创建新属性...</span>
    </Button>
  )

  const footer = (
    <>
      <Button key="ok" type="primary" disabled={!validated}
        onClick={handleOk}>确定</Button>
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  )

  return (
    <>
      {showButton && <Button type="primary" disabled={disabled} onClick={() => setModalShown(true)}>编辑属性列表</Button>}
      <Modal visible={!disabled && modalShown}
        onOk={handleOk} onCancel={handleCancel}
        closable={false} maskClosable={false}
        title={title} footer={footer} width={1000}
        className={'attribute-upsert-modal'}
      >
        <Row style={{ height: 450 }}>
          <Col span={15} className={"left-pane"}>
            {leftPaneHeader}
            {attributeEntries}
            {leftPaneFooter}
          </Col>
          <Col span={9} className={"right-pane"}>
            <div style={{
              height: "100%",
              backgroundColor: "#f0f0f0",
              border: "1px inset #aaa",
              display: "flex",
              flexDirection: "column"
            }}>
              {/* 右侧表单 */}
              <div className="form-area">
                {attrEditing && <>
                  <h4>{attrEditing?.name}</h4>
                  <AttributeUpsertForm
                    locked={attrEditing.category === 'SYSTEM'}
                    dataTypes={dataTypes}
                    key={attrEditing.id}
                    attribute={attrEditing}
                    onChange={updateAttr}
                  />
                </>}
              </div>
              {/* 警告区 */}
              {!!warnings.length &&
                <div className="warning-area">
                  <WarningArea
                    warnings={warnings}
                    style={{
                      flex: "auto",
                      padding: "5px 0 12px 0"
                    }}
                  />
                </div>
              }
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default ModalUpsertAttributes
