import {FC} from "react";

import { CheckOutlined, BorderOutlined } from '@ant-design/icons'

interface TickReadOnlyPropType {
  checked?: boolean
}

/**
 * 输入true/false/undefined，返回 √ / □ / -
 * （三态虽然看起来有些麻烦，确实有必要的）
 * @param checked
 * @constructor
 */
const TickReadOnly: FC<TickReadOnlyPropType> = ({
  checked
}) => {
  if (typeof checked === "undefined") {
    return <span style={{color: '#CCCCCC'}}>-</span>
  }
  return checked
    ? <CheckOutlined style={{color: '#333333'}} />
    : <BorderOutlined style={{color: '#CCCCCC'}} />
}

export default TickReadOnly
