import {Day4CustomFC} from "../../types";
import {useState} from "react";
import {Button, Form, Input, message, Modal} from "antd";
import {useMutation} from "react-query";
import {NamespaceType} from "../../api/types/NamespaceTypes";
import namespaceApi from "../../api/requests/namespaceReqs";
import useMyQuery from "../../api/useMyQuery";
import {Select} from "antd";

interface ModalUpdateNamespacePropType {
  namespace: string,
  onOk: () => void
}

const ModalUpdateNamespace: Day4CustomFC<ModalUpdateNamespacePropType> = ({
  namespace,
  onOk
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  const {
    data: namespaceResponse,
    isLoading
  } = useMyQuery(['namespace-info', namespace], namespaceApi.detail);

  const initialValue = namespaceResponse?.data;

  const mutationUpdateNamespace =
    useMutation((newNs: NamespaceType) => namespaceApi.update(newNs));

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      // 更新时要指定旧namespace的名字
      const newNs: NamespaceType = {
        old_namespaceName: namespace,
        ...values
      }

      const result = await mutationUpdateNamespace.mutateAsync(newNs);
      if (result?.data) {
        message.success('修改成功！');

        form.resetFields();
        setModalVisible(false);
        onOk && onOk();
      }
    } catch (e) {
      // no-op
    }
  }

  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  }

  const footer = (
    <>
      <Button key="ok" type="primary" onClick={handleOk} loading={isLoading}>确定</Button>
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  )

  return (
    <>
      <Button type="primary" onClick={() => setModalVisible(true)}>修改命名空间</Button>
      <Modal visible={modalVisible} footer={footer}
             title={"修改命名空间"} onCancel={handleCancel}
             maskClosable={false}
      >
        <Form
          name="upateNamespace"
          form={form}
          labelCol={{span: 4}}
          initialValues={initialValue}
        >
          <Form.Item
            label="名称"
            name="name"
            rules={[
              {required: true, message: '请输入名称'}
            ]}
          ><Input placeholder={"例如:my_ns1"}/></Form.Item>
          <Form.Item
            label="显示名"
            name="display"
            rules={[
              {required: true, message: '请输入显示名'}
            ]}
          ><Input placeholder={"例如:我的命名空间"}/></Form.Item>

          <Form.Item
            label="共享模式"
            name="access_mode"
          >
            <Select onSelect = {value => {form.setFieldsValue({access_mode: value})}} >
              <Select.Option value="UNSHARE">非共享</Select.Option>
              <Select.Option value="RDONLY">只读</Select.Option>
              <Select.Option value="RDWR">读写</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="描述"
            name="desc"
            rules={[
              {type: "string", max: 128}
            ]}
          ><Input.TextArea rows={3}
                           placeholder={"例如:为XX项目创建"}/></Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ModalUpdateNamespace

