/** @jsxImportSource @emotion/react */
import { Day4CustomFC } from '../../types'
import { TagType } from '../../api/types/TagTypes';
import { Select } from "antd";
import { tagList } from '../../queries/tagQueries';
import react, { useState } from 'react';

import { LocalSetterContext } from 'day4-helper-contexts/es';

type TagSearchPropType = {
	// 无属性
}

const TagSearch: Day4CustomFC<TagSearchPropType> = ({
}) => {
	const [tags, setTags] = useState<TagType[]>([])
	const [values, setValues] = useState<string[]>([])

  const setSetter = react.useContext(LocalSetterContext);

	const onChange  = (v: string[]) => {
	  setValues(v)
    // day4 上下文添加tags state  
		setSetter((draft: any) => {
      draft.tags = v;
    });
	}

	const onDropdownVisibleChange = (open: boolean) => {
		if (open) {
			tagList().then((res) => {
			let v: TagType[] = [];
			if (res?.data) {
				for(let i = 0; i < res?.data.length; i++) {
					v.push(res.data[i]);
				}

				setTags(v);
			}
		})
		}
	}

	return (
    <Select mode="tags"
		  value = {values}
			style = {{width: '237px'}}
			onDropdownVisibleChange = {onDropdownVisibleChange}
			onChange = {onChange}
			placeholder = "选择标签"
			bordered = {true}
			allowClear = {true}
		>
			{
				tags.map((item) => {
					return (
						<Select.Option value={item.keyword} key={item.keyword}>{item.keyword}</Select.Option>
					)
				})
			}
		</Select>
	)
}

export default TagSearch