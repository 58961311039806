import {EntityType} from "../../../api/types/EntityTypes";
import {RelationType} from "../../../api/types/RelationTypes";
import {Edge, Elements, Node, XYPosition} from "react-flow-renderer";
import {ElementsGenerator} from "../types";

const calculatePosition = (index: number, nodeId: string): XYPosition => {
  const row = Math.floor(index / 8);
  const modIndex = index % 8;
  const position = {
    x: modIndex * 340 + 50,
    y: row * 500 + 50,
  };
  return position;
}


const classicElementsGenerator: ElementsGenerator = (
  entities,
  relations,
  positionGuide
) => {
  if (!Array.isArray(entities)) return [];
  const nodes: Node<EntityType>[] = entities.map((item, index) => {
    const {name} = item;
    return {
      id: name, // react-flow-node use `entity_name` as id.
      type: 'entity', // custom react-flow-node type
      position: positionGuide[name] || calculatePosition(index, name),
      data: item,
    };
  });

  if (!Array.isArray(relations)) {
    return nodes;
  }

  const entitySet = new Set(entities.map(et => et.name));

  // console.log(relations);
  const edges: Edge<RelationType>[] = relations
    .filter(rel => {
      const {
        object_type_name1: name1,
        object_type_name2: name2
      } = rel;
      return entitySet.has(name1) && entitySet.has(name2);
    })
    .flatMap((rel) => {
      // 一个双向relation可以产生两条线
      const {
        relation_direction,
        object_type_name1: name1,
        object_type_name2: name2,
        attribute_name1: attr1,
        attribute_name2: attr2,
        relation_multiplicity // todo for further use
      } = rel;

      if (relation_direction === 'BI_DIRECTION') {
        return [
          {
            id: `${name1}.${attr1} --> ${name2}`,
            source: name1,
            sourceHandle: `source@${name1}.${attr1}`,
            target: name2,
            targetHandle: `target@${name2}`
          },
          {
            id: `${name2}.${attr2} --> ${name1}`,
            source: name2,
            sourceHandle: `source@${name2}.${attr2}`,
            target: name1,
            targetHandle: `target@${name1}`
          }
        ]
      } else if (relation_direction === 'LEFT2RIGHT') {
        return [
          {
            id: `${name1}.${attr1} --> ${name2}`,
            source: name1,
            sourceHandle: `source@${name1}.${attr1}`,
            target: name2,
            targetHandle: `target@${name2}`
          }
        ]
      } else if (relation_direction === 'RIGHT2LEFT') {
        return [
          {
            id: `${name2}.${attr2} --> ${name1}`,
            source: name2,
            sourceHandle: `source@${name2}.${attr2}`,
            target: name1,
            targetHandle: `target@${name1}`
          }
        ]
      } else {
        throw new Error(`for dev: unknown relation_direction [${relation_direction}]`);
      }
    });

  return [...nodes, ...edges];
}

export default classicElementsGenerator;
