export const DSType: string[] = [
  "postgres",
  "k2boxng",
]

export enum ACTION_TYPE {
  MODAL_VISIBLE = 'modal_visible',
}

export interface IState {
  modalVisible: boolean,
}

export interface IAction {
  type: ACTION_TYPE,
  payload: any,
}
