import ModalAddNamespace from './ModalAddNamespace'

ModalAddNamespace.register = {
  styles: ['width', 'height'],
  events: [
    {
      type: 'ok'
    }
  ]
}

export default ModalAddNamespace
