/** @jsxImportSource @emotion/react */
import useStyles from 'day4-components/es/hooks/useStyles';
import { display } from 'day4-components/es/styles/layout';
import { Day4CustomFC } from '../../types';

export interface TextProps {
  styles?: any;
  text: string;
  onClick: (params: any) => any;
  fontSize: string;
}

const initialStyles = [display.block];

const CustomText: Day4CustomFC<TextProps> = ({ text, styles, onClick, fontSize }) => {
  const componentCss = useStyles(initialStyles, styles);
  return (
    <span css={componentCss} {...{ onClick }} style={{ fontSize }}>
      {text}
    </span>
  );
};

export default CustomText;
