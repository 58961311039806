import CardList from './CardList';

CardList.register = {
  attributes: [
    {
      name: 'data',
      type: 'json',
    },
    {
      name: 'homeUrl',
      type: 'template',
    },
  ],
};

export default CardList;
