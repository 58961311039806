import env from 'day4-utils/es/env';
import request from '../../sdk/request';
import { DataTypeType, DraftsType } from '../types/CommonTypes';
import { DataSourceType } from '../types/DataSourceTypes';
import { DataSourceBindInfo, NamespaceType } from '../types/NamespaceTypes';
import { OutboxType } from '../types/OutboxTypes';

const api = env.DAY4_APP_MODELER_META_API;
const api2 = env.DAY4_APP_MODELER_GRAPHQL;

const namespaceApi = {
  create(params: NamespaceType) {
    return request<NamespaceType>({
      url: `${api}/namespaces`,
      data: params,
      method: 'POST',
    });
  },
  remove(params: NamespaceType) {
    return request({
      url: `${api}/namespaces/${params.namespace_name}`,
      method: 'DELETE',
    });
  },
  update(params: NamespaceType) {
    const { old_namespaceName, ...restParams } = params;
    return request<NamespaceType>({
      url: `${api}/namespaces/${old_namespaceName}`,
      data: restParams,
      method: 'PUT',
    });
  },
  detail(namespace: string) {
    return request<NamespaceType>({
      url: `${api}/namespaces/${namespace}`,
    });
  },
  list(params?: any) {
    return request<NamespaceType[]>({
      url: `${api}/namespaces`,
      params,
    });
  },
  listDataSource(namespace: string) {
    return request<DataSourceType[]>({
      url: `${api}/namespaces/${namespace}/data_sources`,
    });
  },
  importV2(namespace: string, json: object) {
    // fixme detailed type needed.
    return request<any>({
      url: `${api}/namespaces/${namespace}/v2_import`,
      method: 'POST',
      data: json,
    });
  },
  importV3(namespace: string, json: object) {
    // fixme detailed type needed.
    return request<any>({
      url: `${api}/namespaces/${namespace}/_batch`,
      method: 'POST',
      data: json,
    });
  },
  importExcel(namespace: string, file: any) {
    // fixme detailed type needed.
    return request<any>({
      url: `${api2}/namespaces/${namespace}/data/_import`,
      method: 'POST',
      data: file,
    });
  },
  listDataTypes(namespace: string) {
    return request<DataTypeType[]>({
      url: `${api}/namespaces/${namespace}/data_types`,
    });
  },
  listDrafts(namespace: string) {
    return request<DraftsType>({
      url: `${api}/namespaces/${namespace}/drafts`,
    });
  },
  commit(drafts: DraftsType) {
    const { name: namespace } = drafts;
    if (namespace) {
      return request<any>({
        url: `${api}/namespaces/${namespace}/_commit`,
        method: 'POST',
        data: drafts,
      });
    } else {
      console.error('namespace#commit: no namespace provided!');
      return Promise.reject('namespace#commit: no namespace provided!');
    }
  },
  publishProcess(namespace: string) {
    return request<OutboxType>({
      url: `${api}/namespaces/${namespace}/publish_process`,
    });
  },
  unpublishRel(namespace: string, relationName: string) {
    return request<any>({
      url: `${api}/namespaces/${namespace}/relation_types/${relationName}/_unpublish`,
      method: 'POST',
    });
  },
  unpublishEntity(namespace: string, entityName: string) {
    return request<any>({
      url: `${api}/namespaces/${namespace}/entity_types/${entityName}/_unpublish`,
      method: 'POST',
    });
  },
  unpublishNamespace(namespace: string) {
    return request<any>({
      url: `${api}/namespaces/${namespace}/_unpublish`,
      method: 'POST',
    });
  },

  exportMapping(namespace: string) {
    return request<any>(
      {
        url: `${api}/namespaces/${namespace}/_export_mapping`,
        method: 'GET',
        headers: {
          Accept: 'application/x-yaml',
        },
      },
      true
    ).then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${namespace} mapping.yaml`;
      a.click();
    });
  },
  importMapping(namespace: string, yaml: object) {
    // fixme detailed type needed.
    return request<any>({
      url: `${api}/namespaces/${namespace}/_import_mapping`,
      method: 'POST',
      data: yaml,
    });
  },

  bindDataSource(bindInfo: DataSourceBindInfo) {
    const { namespace } = bindInfo;
    return request<any>({
      // todo add types
      url: `${api}/namespaces/${namespace}/data_sources`,
      method: 'POST',
      data: bindInfo.data_source,
    });
  },
};

export default namespaceApi;
