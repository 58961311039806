import React, { useEffect, useRef } from 'react';
import renderGFM from '../../sdk/markdown/render_gfm';
import { Day4CustomFC } from '../../types';
import './HTMLRender.less';

interface Props {
  html?: string;
}

const HTMLRender: Day4CustomFC<Props> = ({ html }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (html && ref.current) {
      ref.current.querySelectorAll('img.lazy').forEach((img) => {
        // img.loading = 'lazy';
        let imgUrl = img.dataset.src;
        // Only adding width + height for avatars for now
        if (imgUrl.indexOf('/avatar/') > -1 && imgUrl.indexOf('?') === -1) {
          const targetWidth = img.getAttribute('width') || img.width;
          imgUrl += `?width=${targetWidth}`;
        }
        img.src = imgUrl;
        img.removeAttribute('data-src');
        img.classList.remove('lazy');
        img.classList.add('js-lazy-loaded', 'qa-js-lazy-loaded');
      });
      // ref.current.querySelectorAll('a.no-attachment-icon').forEach((el) => {
      //   const alt = el.getElementsByTagName('img')[0].alt;
      //   el.setAttribute('data-content', alt);
      // })

      renderGFM(ref.current);
    }
  }, [html]);

  return html ? (
    <div
      ref={ref}
      className="html-render"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    ></div>
  ) : null;
};
export default HTMLRender;
