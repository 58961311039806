import CopyCode from './CodeBlock';

CopyCode.register = {
  attributes: [
    { name: 'text', type: 'text' },
    { name: 'language', type: 'string' },
    { name: 'showLineNumbers', type: 'boolean' },
    { name: 'highlight', type: 'string' },
  ],
  editorConfig: {
    defaultValue: {
      text: 'curl',
      language: 'shell',
      highlight: '',
    },
  },
};

export default CopyCode;
