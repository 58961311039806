import PublishProcessButton from "./publish_process_button";

PublishProcessButton.register = {
  attributes: [
    {
      name: 'url',
      type: 'template'
    },
    {
      name: 'method',
      type: 'select',
      options: [
        {
          label: 'GET',
          value: 'GET'
        }
      ]
    },
    {
      name: 'message',
      type: 'template'
    },
    {
      name: 'buttonText',
      type: 'template'
    },
    {
      name: 'requestBody',
      type: 'json'
    },
    {
      name: 'disabled',
      type: 'boolean'
    }
  ],
  events: [
    {
      type: 'ok'
    }
  ],
  editorConfig: {
    defaultValue: {
      method: 'GET',
      buttonText: '发布进度',
      disabled: false
    }
  }
}

export default PublishProcessButton;