import request from '../../sdk/request';
import env from 'day4-utils/es/env';
import {DataFrameSubmitType, DataFrameType} from "../types/DataFrameTypes";

const api = env.DAY4_APP_MODELER_META_API;

const dataFrameApi = {
  create (params: DataFrameSubmitType) {
    const {namespace} = params;
    return request<DataFrameType>({
      url: `${api}/namespaces/${namespace}/data_frames`,
      method: "POST",
      data: params
    });
  },
  remove (params: DataFrameType) {
    const {namespace, name: df} = params;
    return request({
      url: `${api}/namespaces/${namespace}/data_frames/${df}`,
      method: "DELETE"
    });
  },
  update (params: DataFrameType) {
    const {old_name: df, namespace, ...restParams} = params;
    return request<DataFrameType>({
      url: `${api}/namespaces/${namespace}/data_frames/${df}`,
      method: "PUT",
      data: restParams
    });
  },
  detail (namespace: string, data_frame: string) {
    return request<DataFrameType>({
      url: `${api}/namespaces/${namespace}/data_frames/${data_frame}`,
    })
  },
  list (namespace: string) {
    return request<DataFrameType>({
      url: `${api}/namespaces/${namespace}/data_frames`
    });
  }
}

export default dataFrameApi;
