/* eslint-disable */
import Oidc from 'oidc-client';
Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

window.loggingout = false
export default class SecurityService {

    constructor(authority_url, oidc_callback_url) {
        this.mgr = new Oidc.UserManager({
            userStore: new Oidc.WebStorageStateStore(),
            authority: authority_url,
            client_id: 'k2box-auth-code-client',
            redirect_uri: oidc_callback_url + 'callback.html',
            response_type: 'code',
            scope: 'openid offline',
            post_logout_redirect_uri: oidc_callback_url + 'callback-signout.html',
            silent_redirect_uri: oidc_callback_url + 'silent-renew.html',
            automaticSilentRenew: true,
            filterProtocolClaims: true,
            loadUserInfo: false,
            response_mode: 'query'
        })

        var _self = this;
        this.mgr.events.addAccessTokenExpired(function() {
            _self.signOut()
        });
        this.mgr.events.addUserLoaded(function(user) {
            window.sid = user.profile.sid
            window.localStorage.setItem("sid", window.sid)
            window.username = user.profile.sub
            window.permissions = user.profile.permissions
            window.access_token = user.access_token
            window.id_token = user.id_token
            window.token_type = user.token_type
        });

    }

    sessionChanged() {
        return window.sid && window.sid != window.localStorage.getItem("sid")
    }

    // Get the user who is logged in
    getUser() {
        let self = this
        return new Promise((resolve, reject) => {
            self.mgr.getUser().then(function(user) {
                if (user == null) {
                    // self.signIn()
                    return resolve(null)
                } else {
                    window.sid = user.profile.sid
                    window.localStorage.setItem("sid", window.sid)
                    window.username = user.profile.sub
                    window.permissions = user.profile.permissions
                    window.access_token = user.access_token
                    window.id_token = user.id_token
                    window.token_type = user.token_type

                    return resolve(user)
                }
            }).catch(function(err) {
                console.log(err)
                return reject(err)
            });
        })
    }

    // Redirect of the current window to the authorization endpoint.
    signIn() {
        window.sessionStorage.setItem('login_redirect_url', window.location)
        this.mgr.signinRedirect().catch(function(err) {
            console.error(err)
            if (err.message.indexOf('404') > -1) {
                alert('OAuth2配置无效, 请联系管理员!')
            }
            window.location.href = '/';
        })
    }

    // Redirect of the current window to the end session endpoint
    signOut() {
        if (window.loggingout) {
            return
        }
        window.loggingout = true

        window.localStorage.removeItem("ssoSid")
        window.localStorage.removeItem("ssoToken")
        window.localStorage.removeItem("ssoTokenType")
        this.mgr.signoutRedirect()
    }
}
