import {RelationBasicSetting} from "../../../api/types/RelationTypes";
import {ValidationContextType} from "../../ModalAddEntityAttributes/types";
import {useMemo} from "react";

type RelationBasicSettingValidationHookType = (
  basicSetting: RelationBasicSetting,
  knowledge: ValidationContextType
) => boolean

const useRelationBasicSettingValidation: RelationBasicSettingValidationHookType = (
  basicSetting,
  knowledge
) => {
  const allPropsAreSet = useMemo(() => {
    return Object.keys(basicSetting).every((key) => {
      return !!basicSetting[key as keyof RelationBasicSetting];
    })
  }, [basicSetting]);

  return allPropsAreSet;
}

export default useRelationBasicSettingValidation;
