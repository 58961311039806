import { Button, Form, Input, message, Modal, Radio } from "antd";
import GraphiQL from 'graphiql';
import { useState } from "react";
import { useMutation } from "react-query";
import templateApi from "../../../api/requests/templateReqs";
import { GraphqlParamType } from "../../../api/types/DataServiceTypes";
import { TemplateType } from "../../../api/types/TemplateTypes";
import { formItemNormalizer, formItemTransformer } from "../../../sdk/utils";
import { Day4CustomFC } from "../../../types";

interface ModalSaveTemplatePropType {
  namespace: string,
  param: GraphqlParamType | null,
  name?: string,
  desc?: string,
  onOk?: () => void,
  setEditingState?: (value: string) => void
}

const ModalSaveTemplate: Day4CustomFC<ModalSaveTemplatePropType> = ({
  namespace,
  param,
  name,
  desc,
  setEditingState = () => { }
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm<FormDataType>();
  const mutationSaveTemplate =
    useMutation((newTemplate: TemplateType) => templateApi.create(newTemplate));

  const mutationUpdateTemplate =
    useMutation((newTemplate: TemplateType) => templateApi.update(newTemplate));

  const [oldName, setOldName] = useState(name);
  const [oldDesc, setOldDesc] = useState(desc);

  const handleSave = () => {
    // e.stopPropagation();
    setModalVisible(true);
    setEditingState("editing");
  }

  const handleOk = async () => {
    if (!param || !param.query) {
      message.error('GraphQL查询已过期，请重新发送请求');
      setModalVisible(false);
      form.resetFields();
      setEditingState("ready");
    }
    try {
      const { option, name, desc } = await form.validateFields();
      let submitting: TemplateType = {
        namespace: namespace,
        body: param!.query,
        variables: param!.variables,
        category: 'GraphQL',
      }
      // 修改保存
      if (option === 1) {
        if (name !== oldName) {
          submitting.name = name;
        }
        if (desc !== oldDesc) {
          submitting.desc = desc;
        }
        const result = await mutationUpdateTemplate.mutateAsync({ ...submitting, old_name: oldName });
        // console.log(window.location.href);

        // 修改完成刷新页面
        let href = window.location.href;
        if (href.indexOf('template=') > -1) {
          href = href.replace(/template=[^&]+/, `template=${name}`);
        } else {
          href += `&template=${name}`
        }
        if (href.indexOf('desc=') > -1) {
          href = href.replace(/desc=[^&]+/, `desc=${desc}`);
        } else {
          href += `&desc=${desc}`
        }
        window.location.href = href;

        if (result?.data) {
          message.success("更新成功");
          setOldName(name);
          setOldDesc(desc);
          setModalVisible(false);
          form.resetFields();
          setEditingState("ready");
        }
      }
      // 新增
      if (option === 2) {
        submitting = {
          ...submitting,
          name: name,
          desc: desc
        }
        const result = await mutationSaveTemplate.mutateAsync(submitting);
        if (result?.data) {
          message.success("保存成功");
          setModalVisible(false);
          form.resetFields();
          setEditingState("ready");
        }
      }
    } catch (e) {
      console.error("ModalSaveTemplate: ", e);
    }
  }
  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
    setEditingState("ready");
  }

  const footer = (
    <>
      <Button key="ok" type="primary" onClick={handleOk}>确定</Button>
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  );

  const handleChange = (changedValues: any, allValues: any) => {
    if (changedValues.option === 1) {
      form.setFieldsValue({ name: oldName, desc: oldDesc });
    } else if (changedValues.option === 2) {
      form.setFieldsValue({ name: param?.operationName || '', desc: '' });
    }
  };

  return (
    <>
      {(param && namespace) && (<GraphiQL.Button label="保存模板" title="save query" onClick={() => { handleSave() }}></GraphiQL.Button>)}
      {/* <GraphiQL.Button label="Save" title="Save" onClick={() => {handleSave()}}></GraphiQL.Button> */}
      <Modal visible={modalVisible} title="保存模板" footer={footer} closable={false} maskClosable={false}>
        <Form
          name="saveTemplate"
          form={form}
          initialValues={{
            variables: param?.variables ? JSON.stringify(param.variables, null, 2) : ''
          }}
          labelCol={{ span: 4 }}
          onValuesChange={handleChange}
        >
          <Form.Item
            label="保存选项"
            name="option"
            rules={[
              { required: true, message: '请选择保存选项' }
            ]}
          >
            <Radio.Group>
              <Radio value={1}>原模板修改</Radio>
              <Radio value={2}>另存为</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="名称"
            name="name"
            normalize={formItemNormalizer.trim}
            rules={[
              { required: true, message: '请输入名称', transform: formItemTransformer.trim }
            ]}
          ><Input placeholder={"例如:contacts_01"} /></Form.Item>
          <Form.Item
            label="描述"
            name="desc"
            rules={[{ required: true, type: "string", max: 128 }]}
          ><Input.TextArea rows={3} placeholder={"例如:联系人列表"} /></Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ModalSaveTemplate;
