import CheckboxGroup from './CheckboxGroup';

CheckboxGroup.register = {
  attributes: [
    {
      name: 'value',
      type: 'StringValue',
      isTemplate: true,
    },
    {
      name: 'text',
      type: 'template',
    },
    {
      name: 'icon',
      type: 'select',
      options: [
        {
          label: '行业分类',
          value: 'hangyefenlei',
        },
        {
          label: '业务场景',
          value: 'yewuchangjing',
        },
        {
          label: '工业对象',
          value: 'gongyeduixiang',
        },
      ],
    },
    {
      name: 'options',
      type: 'json',
    },
  ],
};

export default CheckboxGroup;
