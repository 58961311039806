import TagSearch from "./TagSearch"

TagSearch.register = {
  attributes: [
  ],
  events: [
  ],
  editorConfig: {
    defaultValue: {
    }
  }
}


export {TagSearch}