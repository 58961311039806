import templateApi from "../api/requests/templateReqs";
import {getByPath} from "../sdk/utils";

export function templateList ({dependencies}: any) {
  const namespace = getByPath(dependencies, [0, 1]);
  if (!namespace) {
    return null;
  }
  return templateApi.list(namespace);
}
