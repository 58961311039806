import { useEffect, useState } from 'react';
import env from 'day4-utils/es/env';
import { ss } from '../../k2assets-auth';
import { Day4CustomFC } from '../../types';

const NeedAuth: Day4CustomFC = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    if (env.DAY4_APP_AUTH_ENABLED === 'true') {
      ss.getUser()
        .then((user) => {
          if (user) {
            setIsLogin(true);
          } else {
            ss.signIn();
          }
        })
        .catch(() => {
          window.location.href = '/';
        });
    }
  }, []);

  return isLogin && children ? <>{children}</> : null;
};

export default NeedAuth;
