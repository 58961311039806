import ReloadButton from "./ReloadButton";

ReloadButton.register = {
  attributes: [
    {
      name: 'dataSource',
      type: 'template'
    },
    {
      name: 'method',
      type: 'select',
      options: [
        {
          label: 'GET',
          value: 'GET'
        },
        {
          label: 'POST',
          value: 'POST'
        },
        {
          label: 'PUT',
          value: 'PUT'
        },
        {
          label: 'DELETE',
          value: 'DELETE'
        }
      ]
    },
    {
      name: 'buttonText',
      type: 'template'
    }
  ],
  events: [
    {
      type: 'ok'
    }
  ],
  editorConfig: {
    defaultValue: {
      method: 'POST',
      buttonText: '刷新'
    }
  }
}

export {ReloadButton}
