import React from 'react';
import { Day4CustomFC } from '../types';

export const Example: Day4CustomFC<any> = ({ title = 'example' }) => {
  return <p>Hello, {title}</p>;
};

Example.register = {
  attributes: [
    {
      name: 'title',
      type: 'string',
    },
  ],
};
