type IndexType = string | number;

export function getByPath (obj: any, pathArr: IndexType | IndexType[]): any {
  if (typeof pathArr === 'undefined') {
    return obj
  } else if (!obj) {
    return null
  }
  let result = obj;
  if (Array.isArray(pathArr)) {
    for (let i = 0; i < pathArr.length; i++) {
      const key = pathArr[i];
      result = result[key];
      if ((typeof result === 'undefined') || result === null) {
        return null;
      }
    }
    return result;
  } else {
    return obj[pathArr];
  }
}

const trim = (s: string) => s.trim()
const asJson = (s: string) => {
  let result = {};
  try {
    result = JSON.parse(s);
  } catch (e) {/* no-op */}
  return result;
}

export const formItemNormalizer = Object.freeze({
  trim,
  asJson
})

export const formItemTransformer = Object.freeze({
  trim,
  asJson
})

export function firstUpperCase(s: string) : string {
    return s[0].toLocaleUpperCase().concat(s.slice(1))
} 
