import {useEffect, useState} from "react";
import {PositionGuide, SizeGuide} from "../types";
import namespaceApi from "../../../api/requests/namespaceReqs";

/**
 * hook to get stored positions for diagram elements
 * currently, positions are restored from localforage.
 * @param namespace
 */
const useSavedNode = (namespace: string) => {
  const [positions, setPositions] = useState<PositionGuide>({})
  const [size, setSize] = useState<SizeGuide>({});

  useEffect(() => {

    // use IIFE to invoke localForage.getItem synchronously.
    // (async function () {
    //   const positionGuide = await localForage.getItem<PositionGuide>('positionGuide@' + namespace);
    //   if (positionGuide && typeof positionGuide === 'object') {
    //     setPositions(positionGuide);
    //   } else {
    //     setPositions(_initial_position_for_dicastal);
    //   }
    //
    //   // console.log(positions);
    // })();

    namespaceApi.detail(namespace).then(res => {
      const labels = res?.data?.labels || {};
      setPositions(labels['positionGuide'] || {});
      setSize(labels['sizeGuide'] || {});
    });

  }, [namespace])

  return { positions, size };
}

export default useSavedNode;
