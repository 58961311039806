import SimpleLink from './SimpleLink'

import env from 'day4-utils/es/env';

const defaultHref = env.DAY4_APP_MODELER_DOCS_URL || '#';

SimpleLink.register = {
  attributes: [
    { name: 'text', type: 'template' },
    { name: 'href', type: 'template' },
    { name: 'icon', type: 'icon' },
  ],
  editorConfig: {
    defaultValue: {
      text: 'link to...',
      href: defaultHref
    }
  }
}

export default SimpleLink;
