import Video from './Video';

Video.register = {
  attributes: [
    {
      name: 'url',
      type: 'template',
    },
    {
      name: 'width',
      type: 'number',
    },
    {
      name: 'height',
      type: 'number',
    },
  ],
};

export default Video;
