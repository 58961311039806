import ModalUpdateEntity from "./ModalUpdateEntity";

ModalUpdateEntity.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'entity',
      type: "template"
    },
    {
      name: 'disabled',
      type: "boolean"
    }
  ],
  events: [
    {
      type: 'ok'
    }
  ],
  editorConfig: {
    defaultValue: {
      disabled: false
    }
  }
}

export default ModalUpdateEntity;
