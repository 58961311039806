import {Day4CustomFC} from "../../types";

import {Button, message, Upload, UploadProps} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import {useState} from "react";
import {useMutation} from "react-query";
import namespaceApi from "../../api/requests/namespaceReqs";

import {CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons'

const { Dragger } = Upload;

interface YAMLMappingImporterPropType {
  namespace: string
}

const YAMLMappingImporter: Day4CustomFC<YAMLMappingImporterPropType> = ({
  namespace
}) => {

  const [fileName, setFileName] = useState('');
  // the file valid for uploading.
  const [valid, setValid] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  // data to upload (using POST)
  const [fileForUpload, setFileForUpload] = useState<FormData | null>(null);

  const [msg, setMsg] = useState('');

  const uploadMutation = useMutation((file: object) => namespaceApi.importMapping(namespace, file), {
    onSuccess: (data) => {
      if (data && data.code === 0) {
        message.success('导入成功！');
        setMsg('导入成功');
      }
    },
    onError: (e: any) => {
        setMsg(e.info);
    }
  });

  // --------------------------------------------------------

  const checkFileContentTypeAndUseManualUpload: typeof uploadGraggerProps.beforeUpload = (file, fileList) => {
    // console.log(file, fileList)
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      if (e?.target) {
        setFileName(file.name);
        let formData: FormData = new FormData(); 
        formData.append('file', fileList[0]);
        setFileForUpload(formData);
        setValid(true);
        setErrorMessage('');
      } else {
        setFileName('');
        setFileForUpload(null);
        setValid(null);
        setErrorMessage('');
      }
    }
    return false;
  }

  const handleUpload = () => {
    if (fileForUpload) {
      uploadMutation.mutate(fileForUpload);
    } else {
      // no-op
    }
  }

  const handleClear = () => {
    setValid(null);
    setFileName('');
    setErrorMessage('');
    setMsg('');
    setFileForUpload(null);
  }

  // ---------------------------------------------------------

  const uploadGraggerProps: UploadProps = {
    showUploadList: false,
    // see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
    accept: '.yaml,.json',
    beforeUpload: checkFileContentTypeAndUseManualUpload
  }

  const UploadHint = () => errorMessage
    ? (<span><CloseCircleOutlined /> {fileName}</span>)
    : (<span><CheckCircleOutlined /> {fileName}</span>);

  return (
    <div>
      <Dragger {...uploadGraggerProps} style={{marginBottom: "8px"}}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          {
            fileName ? <UploadHint/> : 'Only yaml file is supported.'
          }
        </p>
      </Dragger>
      {errorMessage && (<p style={{color: 'red'}}>{errorMessage}</p>)}
      <p style={{color: `${msg === '导入成功' ? '#333333' : 'red'}`}}>{msg}</p>
      <div>
        <Button type="primary"
                onClick={handleUpload}
                disabled={valid === false}
                loading={uploadMutation.isLoading}
                style={{marginRight: '5px'}}
        >
          <span>确定</span>
        </Button>
        <Button type="default"
                onClick={handleClear}
                disabled={uploadMutation.isLoading}
        >
          <span>重置</span>
        </Button>
      </div>
    </div>
  )

}

export default YAMLMappingImporter;
