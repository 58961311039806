import {Modal, Button, Form, message, Select, Input, InputNumber, Radio} from 'antd'
import {Day4CustomFC} from "../../types";
import React, {useReducer, useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {LocalSetterContext} from "day4-helper-contexts/es";
import {IState, ACTION_TYPE} from "./typings";
import {DataSourceCategory, DataSourceType} from "../../api/types/DataSourceTypes";
import dataSourceApi from "../../api/requests/dataSourcesReqs";
import {addDS} from "./redux";
import useMyQuery from "../../api/useMyQuery";
import dsCSS from "./ds.module.scss";

export interface ModalAddDataSourceProType {
}

const ModalAddDataSource: Day4CustomFC<ModalAddDataSourceProType> = ({
}) => {

  const [form] = Form.useForm()
  const stateSetter = React.useContext(LocalSetterContext)
  const queryClient = useQueryClient();

  const [isShowDataSourceCategoryList, setIsShowDataSourceCategoryList] = useState(true)
  const [isShowCreateDataSourceForm, setIsShowCreateDataSourceForm] = useState(false)
  const [selectedDataSourceCategory, setSelectedDataSourceCategory] = useState<DataSourceCategory>()
  const [isShowCancelBtn, setIsShowCancelBtn] = useState(true)
  const [isShowCompleteBtn, setIsShowCompleteBtn] = useState(false)
  const [isShowPrevBtn, setIsShowPrevBtn] = useState(false)

  // 数据源模式：1 实例模式，无需填写数据库名称 2 数据库模式：需要填写数据库名称
  // 只有数据源类型是dbms时，才展示出来
  const [dataSourceMode, setDataSourceMode] = useState("")

  const onSelectedDataSourceCategory = (c: DataSourceCategory) => {
    if (!c.supported) {
     message.info("正在实现中...")
     return
    }
    // 如果上一次选中的data source type 不等于当前选中的, 重新刷新下表单
    if (selectedDataSourceCategory?.name !== c.name) {
      form.resetFields();
    }
    setSelectedDataSourceCategory(c)
    setIsShowDataSourceCategoryList(false)
    setIsShowCreateDataSourceForm(true)

    setIsShowCancelBtn(false)
    setIsShowCompleteBtn(true)
    setIsShowPrevBtn(true)
  }

  const initState:IState = {
    modalVisible: false,
  }
  const [databaseState, dispatch] = useReducer(addDS, initState);

  const mutationCreateDS = useMutation((ds: DataSourceType) => dataSourceApi.create(ds), {
    onSuccess: () => {

      queryClient.fetchQuery(['externalDatabaseList'], dataSourceApi.list)
        .then(function (data) {
          // 把数据传递给day4组件 (rdbList)
          stateSetter((params:any)=>{
            params["dataSources"] = data
            console.log("update resource database list success ....")
          })
        }).catch(function (err) {
        console.log("reset query resource database error: "+ err)
      })

    }
  });

  const handleShowModal = () => {
    dispatch({type: ACTION_TYPE.MODAL_VISIBLE, payload: true})
  }

  const handleCancel = () => {
    form.resetFields();
    setSelectedDataSourceCategory(undefined)
    setIsShowDataSourceCategoryList(true)
    setIsShowCreateDataSourceForm(false)
    setIsShowCancelBtn(true)
    setIsShowCompleteBtn(false)
    setIsShowPrevBtn(false)
    dispatch({type: ACTION_TYPE.MODAL_VISIBLE, payload: false})
  }
  const handleOk = async () => {
    try {
      // 从表单中取出字段
      const values = await form.validateFields();
      if (selectedDataSourceCategory?.config?.is_only_external) {
        values['is_external'] = true
      } else {
        values['is_external'] =  values['is_external'] === 'true'
      }
      values['type'] = selectedDataSourceCategory?.value
      console.log(values)
      const result = await  mutationCreateDS.mutateAsync(values,)
      if (result?.data) {
        message.success("新增成功")
        form.resetFields()
        setSelectedDataSourceCategory(undefined)
        setIsShowDataSourceCategoryList(true)
        setIsShowCreateDataSourceForm(false)
        setIsShowCancelBtn(true)
        setIsShowCompleteBtn(false)
        setIsShowPrevBtn(false)
        dispatch({type: ACTION_TYPE.MODAL_VISIBLE, payload: false})
      }
    } catch (e) {
      // no-op
    }
  }

  const handlePrev = () => {
    setIsShowCreateDataSourceForm(false)
    setIsShowDataSourceCategoryList(true)
    setIsShowPrevBtn(false)
    setIsShowCompleteBtn(false)
    setIsShowCancelBtn(true)
  }

  const footer = (
    <>
      <Button key="prev" type="dashed" onClick={handlePrev} hidden={!isShowPrevBtn}>上一步</Button>
      <Button key="ok" type="primary" onClick={handleOk} hidden={!isShowCompleteBtn}>完成</Button>
      <Button key="cancel" type="dashed" onClick={handleCancel} hidden={!isShowCancelBtn}>取消</Button>
    </>
  )

   const DataSourceCategory = () => {
    const { data: dataSourceCategoryListResponse } = useMyQuery(['datasources'], dataSourceApi.categories);
    const dataSourceCategoryList = dataSourceCategoryListResponse?.data;
    // 按标签分下类
    const dataSourceCategoryMap = new Map<string, DataSourceCategory[]>()
    dataSourceCategoryList?.map(c => {
      let v = dataSourceCategoryMap.get(c.tag)
      if (v) {
        v.push(c)
      } else {
        v = new Array<DataSourceCategory>()
        v.push(c)
      }
      dataSourceCategoryMap.set(c.tag, v)
  })

    return (
      <div  hidden={!isShowDataSourceCategoryList}>
         {dataSourceCategoryMap && Array.from(dataSourceCategoryMap.entries()).map(([tag, list]) => (
        <div className={dsCSS.dataSourceTypeGroup}>
            <div>

            <div className={dsCSS.dataSourceTypeGroupTab}>
              <div className={dsCSS.dataSourceTypeGroupTabName}>{tag}</div>
            </div>
            <span style={{top: "-1px", position:"absolute", color: "red", left: "90px"}}/>

            {list && list.map(c => (
            <div className={c.supported ? dsCSS.dataSourceTypeItem: dsCSS.dataSourceTypeItemNotSupport} onClick={()=>onSelectedDataSourceCategory(c)}>
              <img src={c.icon} className={dsCSS.dataSourceTypeItemImg}/>
              <span className={dsCSS.dataSourceTypeItemName}>{c.name}</span>
            </div>))}
            </div>
          </div>

          ))}
      </div>
    )
  }

  return (
    <>
      <Button
        type={"primary"}
        onClick={handleShowModal}>新增数据源</Button>

      <Modal visible={databaseState.modalVisible}
             title={'新增数据源'}
             onCancel={handleCancel}
             maskClosable={false}
             destroyOnClose={true}
             footer={footer}
             width='43%'
             bodyStyle={{overflowY: 'auto', maxHeight:'670px'}}
      >
        {DataSourceCategory()}
 
        <Form
          name='CreateDatasource'
          form={form}
          labelCol={{span: 6}}
          preserve={false}
          hidden={!isShowCreateDataSourceForm}
        >

          {/* dbms类型的数据源才展示数据源模式 */}
          {selectedDataSourceCategory?.config?.is_dbms &&
          <Form.Item
            initialValue = {"instance_mode"}
            label="数据源模式"
            name="mode"
            rules={[
              {required: true, message: '数据源模式'}
            ]}
          >
            <Radio.Group defaultValue={"instance_mode"} onChange={(e)=>{setDataSourceMode(e.target.value)}}>
              <Radio value="instance_mode">实例模式</Radio>
              <Radio value="database_mode">数据库模式</Radio>
            </Radio.Group>

          </Form.Item>
          }

          <Form.Item
            label="数据源名称"
            name="name"
            rules={[
              {required: true, message: '数据源名称不能为空'}
            ]}
          ><Input placeholder={""}/>
          </Form.Item>


          <Form.Item
            label="数据源类型"
            name="type"
          >
            <label>{selectedDataSourceCategory?.name}</label>
          </Form.Item>

          <Form.Item label="数据源描述"
                     name="desc">
            <Input placeholder={""} />
          </Form.Item>

          {selectedDataSourceCategory?.config?.is_hidden_host_port !== true &&
          <>
          <Form.Item label="host"
                     name="host"
                     rules={[
                       {required: true, message: 'host不能为空'}
                     ]}>
            <Input placeholder={""}/>
          </Form.Item>

          <Form.Item label="port"
                     name="port"
                     rules={[
                       {required: true, message: 'port不能为空'}
                     ]}>

            <InputNumber size={"middle"}/>
          </Form.Item>
          </>}

          {selectedDataSourceCategory?.config?.is_dbms && dataSourceMode === "database_mode" &&

          <Form.Item label="数据库名称"
                     name="database_name"
                     rules={[
                       {required: true, message: '数据库名称不能为空'}
                     ]}>
          <Input placeholder={""}/>
          </Form.Item>}

      {selectedDataSourceCategory?.config?.authentication_mode === "none" ?
      <></>
      : selectedDataSourceCategory?.config?.authentication_mode === "token" ?
      <Form.Item label="认证"
                     name="credentail"
                     rules={[
                       {required: true, message: '认证不能为空'}
                     ]}>
            <Input placeholder={""}/>
      </Form.Item>
      :
      <>
      <Form.Item label="用户名"
                name="username"
                rules={[
                  {required: true, message: '用户名不能为空'}
                ]} hidden={selectedDataSourceCategory?.config?.authentication_mode !== "userPassword"}>
      <Input placeholder={""}/>
    </Form.Item>
      <Form.Item label="密码"
                name="password"
                rules={[
                  {required: true, message: '密码不能为空'}
                ]} hidden={selectedDataSourceCategory?.config?.authentication_mode !== "userPassword"}>
      <Input placeholder={""}/>
    </Form.Item>
    </>}
        
          <Form.Item
            initialValue="false"
            label="外部数据源"
            name="is_external"
            rules={[
              {required: true, message: ''}
            ]}
          >
            {selectedDataSourceCategory?.config?.is_only_external ?
            <label>是</label>
            :
            <Select
            	style = {{width: '77px'}}
              placeholder={''}
            >
             	<Select.Option value='true'>是</Select.Option>
             	<Select.Option value='false'>否</Select.Option>
            </Select>
            }
          </Form.Item>
        
        </Form>
      </Modal>
    </>
  )
}

export default ModalAddDataSource
