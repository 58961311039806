import namespaceApi from "../../api/requests/namespaceReqs";

import {Modal, Button, Form, message, Input} from 'antd'
import {Day4CustomFC} from "../../types";
import {useState} from "react";
import {useMutation} from "react-query";
import {NamespaceType} from "../../api/types/NamespaceTypes";
import useHistoryContext from "day4-editor/es/hooks/useHistoryContext";


interface ModalAddNamespacePropType {
  onOk: () => void
}

// todo add history push target

const ModalAddNamespace: Day4CustomFC<ModalAddNamespacePropType> = ({
  onOk
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  const history = useHistoryContext();

  const mutationCreateNamespace =
    useMutation((newNs: NamespaceType) => namespaceApi.create(newNs));

  /* how to make a good form? */

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const result = await mutationCreateNamespace.mutateAsync(values);
      if (result?.data) {
        message.success('创建成功！');
        setModalVisible(false);

        history.push(`/namespaces/${result.data.name}/overview`)
      }
    } catch (e) {
      // no-op
    }
  }

  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  }

  const footer = (
      <>
        <Button key="ok" type="primary" onClick={handleOk}>新建</Button>
        <Button key="cancel" onClick={handleCancel}>取消</Button>
      </>
  )

  return (
    <>
      <Button type="primary" onClick={() => setModalVisible(true)}>新建工作空间</Button>
      <Modal visible={modalVisible} footer={footer}
             title={"新建工作空间"} onCancel={handleCancel}
             maskClosable={false}
      >
        <Form
          name="createNamespace"
          form={form}
          labelCol={{span: 4}}
          // initialValues={{
          //   name: 'new-ns',
          //   display: "默认命名空间",
          //   desc: '命名空间的描述'
          // }}
        >
          <Form.Item
            label="名称"
            name="name"
            rules={[
              {required: true, message: '请输入名称'}
            ]}
          ><Input placeholder={"例如:my_ns1"}/></Form.Item>
          <Form.Item
            label="显示名"
            name="display"
            rules={[
              {required: true, message: '请输入显示名'}
            ]}
          ><Input placeholder={"例如:我的命名空间"}/></Form.Item>
          <Form.Item
            label="描述"
            name="desc"
            rules={[
              {type: "string", max: 128}
            ]}
          ><Input.TextArea rows={3}
              placeholder={"例如:为XX项目创建"}/></Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ModalAddNamespace
