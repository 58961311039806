import {EntityType} from "../../../api/types/EntityTypes";
import {RelationType} from "../../../api/types/RelationTypes";
import {Edge, Elements, Node, XYPosition} from "react-flow-renderer";
import {
  AugmentedEntityType,
  AugmentedRelationType,
  ElementsGenerator, NodeHighlightType
} from "../types";

const calculatePosition = (index: number, nodeId: string): XYPosition => {
  const row = Math.floor(index / 8);
  const modIndex = index % 8;
  const position = {
    x: modIndex * 340 + 50,
    y: row * 500 + 50,
  };
  return position;
}

const cleanerRelationElementsGenerator: ElementsGenerator = (
  entities,
  relations,
  positionGuide,
  options = {}
) => {
  const {viewMode, search, focusedEdge} = options;

  if (!Array.isArray(entities)) return[];

  let relationList: AugmentedRelationType[];
  if (!Array.isArray(relations)) {
    relationList = [];
  } else {
    const entitySet = new Set(entities.map(et => et.name));
    // console.log(entitySet);
    relationList = relations
      .filter(rel => {
        // note: 某些关系对应的实体不存在？？要把它们过滤掉，否则图会报错
        //   经查，问题源于数据不完全加载。但是关系与实体不对应的问题
        //   仍然可能出现。
        const {
          object_type_name1: name1,
          object_type_name2: name2
        } = rel;
        return entitySet.has(name1) && entitySet.has(name2);
      })
      .map(rel => {
        const {
          relation_direction,
          object_type_name1: name1,
          object_type_name2: name2,
          attribute_name1: attr1,
          attribute_name2: attr2
        } = rel;

        let canonicalExpression: string;
        if (relation_direction === 'BI_DIRECTION') {
          canonicalExpression = `${name1}.${attr1} <-> ${name2}.${attr2}`
        } else if (relation_direction === 'LEFT2RIGHT') {
          canonicalExpression = `${name1}.${attr1} --> ${name2}`
        } else if (relation_direction === 'RIGHT2LEFT') {
          canonicalExpression = `${name2}.${attr2} --> ${name1}`
        } else {
          throw new Error(`for dev: unknown relation_direction [${relation_direction}]`);
        }

        return {
          ...rel,
          canonicalExpression
        }
    });
  }

  const cache = relationList.reduce((res: Record<string, RelationType[]>, item) => {
    const {
      object_type_name1: name1,
      object_type_name2: name2
    } = item;

    res[name1] = [...(res[name1] || []), item]
    res[name2] = [...(res[name2] || []), item]
    return res;
  }, {})

  const {source, target} = focusedEdge || {};

  const nodes: Node<AugmentedEntityType>[] = entities.map((item, index) => {
    const {name} = item;
    let highlight: NodeHighlightType = null;
    if (name === source) {
      highlight = 'source'
    } else if (name === target) {
      highlight = 'target'
    } else if (search) {
      if (name.indexOf(search) !== -1) {
        highlight = 'filter'
      } else {
        highlight = 'filter-out'
      }
    }
    return {
      id: name, // react-flow-node use `entity_name` as id.
      type: 'dynamic_handle_opaque_entity', // custom react-flow-node type
      position: positionGuide[name] || calculatePosition(index, name),
      data: {
        ...item,
        viewMode,
        highlight,
        relations: cache[name] || []
      }
    };
  });

  const edges: Edge<AugmentedRelationType>[] = relationList
    .map(rel => {
      // 一个关系对应一条线
      const {
        canonicalExpression,
        object_type_name1: name1,
        object_type_name2: name2,
        relation_multiplicity // todo for further use
      } = rel;

      if (!canonicalExpression) {
        throw new Error('for dev: canonicalExpression not generated!');
      }

      return {
        id: canonicalExpression,
        source: name1,
        sourceHandle: `source@${name1}(${canonicalExpression})`,
        target: name2,
        targetHandle: `target@${name2}(${canonicalExpression})`,
        style: {
          strokeWidth: 3,
          stroke: 'rgba(170,181,243,0.49)',
          cursor: "pointer"
        }
      }
    })

  return [...nodes, ...edges];
}

export default cleanerRelationElementsGenerator;
