/** @jsxImportSource @emotion/react */
import {
  Button, Col, Divider, Input,
  message,
  Modal, Row,
  Select, Steps
} from "antd";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import entityApi from "../../api/requests/entityReqs";
import { ObjectType } from "../../api/types/CommonTypes";
import { DimentionRelationshipType } from "../../api/types/DimentionRelationship";
import { EntityAttributeType } from "../../api/types/EntityTypes";
import {
  RelationMultiplicityType,
  RelationTypeType
} from "../../api/types/RelationTypes";
import { Day4CustomFC } from "../../types";
import useNamespaceObjectQuery from "./hooks/useNamespaceObjectQuery";
import useEntityType1Validation from "./hooks/useObject1Validation";
import './modalAddRelationStyles.less';

const { Step } = Steps;


interface ModalAddDimentionRelationshipPropType {
  namespace: string,
  // 左边的那个对象类型（用于初始化）
  type1: string,
  // 右边的那个对象类型（可能会有）
  type2: string,
  type2_is_external: boolean,
  attribute2: EntityAttributeType,
  buttonLabel: string,
  showButton: boolean,
  disabled?: boolean,
  onOk: () => void
}

interface RelationBrief {
  type1: ObjectType,
  type_name1: string,
  type2: ObjectType,
  type_name2: string,
  multiplicity: RelationMultiplicityType
}

const { Option, OptGroup } = Select;

const ModalAddDimentionRelationship: Day4CustomFC<ModalAddDimentionRelationshipPropType> = ({
  namespace,
  type1,
  type2,
  type2_is_external,
  attribute2,
  showButton,
  buttonLabel,
  disabled = false,
  onOk
}) => {
  // debug
  console.log("基于foreign key ", attribute2)

  // Stats -----------------------------------------------
  const [modalVisible, setModalVisible] = useState(!showButton);
  // 上方的控件，提示左右类（1、2）以及数量关系
  const [relationBrief, setRelationBrief] = useState<RelationBrief>({
    type1: 'ENTITY_TYPE',
    type_name1: type1,
    type2: type2_is_external ? 'EXTERNAL_ENTITY_TYPE' : "ENTITY_TYPE",
    type_name2: type2,
    multiplicity: 'ONE2MANY'
  });
  // 关系的名称
  const [relationName, setRelationName] = useState<string>('');
  // 关系的类型(默认是WEAK)
  const [relationType, setRelationType] = useState<RelationTypeType>('VIRTUAL');
  // 访问属性1：对象1->对象2的访问属性(会初始化成对象2的类名)
  const [relAttr1, setRelAttr1] = useState<string>('');
  // 访问属性2：对象2->对象1的访问属性(会初始化成对象1的类名)
  const [relAttr2, setRelAttr2] = useState<string>('');
  // 命名空间中的所有对象的列表
  const [objectsInGroup] = useNamespaceObjectQuery(namespace);
  // 当前步骤
  const [current, setCurrent] = useState(0);

  // 计算出来的数据 ------------------------------------------------
  // const validated = useRelationBasicSettingValidation(relationBasicSetting, {});
  const object1 = () => {
    var g = objectsInGroup.find(group => {
      return group.type === relationBrief.type1
    })

    return g?.items.find(item => {
      return item.name === relationBrief.type_name1
    })
  }

  const validated = true
  const [et1IsValidated, et1InvalidHint] = useEntityType1Validation(attribute2, object1())
  // 关系本身的设置(用于API提交)
  const relationBasicSetting: DimentionRelationshipType = {
    relationship_name: relationName,
    object_type_name1: relationBrief.type_name1,
    attribute_name1: relAttr1,
  }



  interface SelectionOption<L, V> {
    label: L,
    value: V,
    enable: boolean
  }

  const relationTypeOptions: SelectionOption<string, RelationTypeType>[] = [
    {
      label: `当删除${relationBrief.type_name1 || '...'}的对象时，不影响${relationBrief.type_name2 || '...'}的对象`,
      value: 'VIRTUAL',
      enable: relationBrief.type1 === "EXTERNAL_ENTITY_TYPE" || relationBrief.type2 === "EXTERNAL_ENTITY_TYPE",
    },
    {
      label: `当删除${relationBrief.type_name1 || '...'}的对象时，将${relationBrief.type_name2 || '...'}对象的引用置空`,
      value: 'SET_NULL',
      enable: relationBrief.type1 === "ENTITY_TYPE" && relationBrief.type2 === "ENTITY_TYPE"
    },
    {
      label: `当被${relationBrief.type_name2 || '...'}引用时，不允许删除${relationBrief.type_name1 || '...'}的对象`,
      value: 'RESTRICT',
      enable: relationBrief.type1 === "ENTITY_TYPE" && relationBrief.type2 === "ENTITY_TYPE"
    },
    {
      label: `当删除${relationBrief.type_name1 || '...'}的对象时，同时删除${relationBrief.type_name2 || '...'}对象`,
      value: 'CASCADE',
      enable: relationBrief.type1 === "ENTITY_TYPE" && relationBrief.type2 === "ENTITY_TYPE"
    }
  ]

  const mutationCreateRelation =
    useMutation((basicSetting: DimentionRelationshipType) => entityApi.convertAttributeToRelational(namespace, relationBrief.type_name2, relAttr2, basicSetting));

  // 数据初始化与联动 -----------------------------------------------------
  useEffect(() => {
    setRelationName(`rel_${relationBrief.type_name1}__${relationBrief.type_name2}`);
    setRelAttr1(relationBrief.type_name2);
    setRelAttr2(relationBrief.type_name1);
  }, [setRelationName, relationBrief])

  // 回调区 ---------------------------------------------------------
  const handleSelectObject1 = (value: string) => {
    const [type, type_name] = value.split('#');
    if (type === 'EXTERNAL_ENTITY_TYPE') {
      setRelationType("VIRTUAL")
    }
    // 设置select
    setRelationBrief({
      ...relationBrief,
      type1: type as ObjectType,
      type_name1: type_name
    });
    console.log("object 1 changed to", type as ObjectType, type_name)
  }

  const handleSelectObject2 = (value: string) => {
    const [type, type_name] = value.split('#');
    if (type === 'EXTERNAL_ENTITY_TYPE') {
      setRelationType("VIRTUAL")
    }
    setRelationBrief({
      ...relationBrief,
      type2: type as ObjectType,
      type_name2: type_name
    });
  }

  const handleSelectRelationMx = (value: RelationMultiplicityType) => {
    setRelationBrief({
      ...relationBrief,
      multiplicity: value
    });
    if (value === 'MANY2MANY') {
      // 多对多没有强关系，所以手动调整过来
      // setRelationType('VIRTUAL');
    }
  }

  const resetModal = () => {
    setRelationBrief({
      type1: 'ENTITY_TYPE',
      type_name1: type1 || '',
      type2: 'ENTITY_TYPE',
      type_name2: type2 || '',
      multiplicity: 'ONE2MANY'
    });
    setRelationName('');
    setRelationType('VIRTUAL');
    setRelAttr1('');
    setRelAttr2('');
  }



  const handleOk = async () => {
    // for dev use
    // console.log('relation info -> ', relationBasicSetting);
    // return 'dicastal_sophia_HntNIakobF_equipment_class_equipment_specification';
    try {
      if (!validated) {
        return;
      }
      const basicSetting = { ...relationBasicSetting };
      const result = await mutationCreateRelation.mutateAsync(basicSetting);

      if (result?.data) {
        message.success('创建成功');

        resetModal();
        setModalVisible(false);
        onOk && onOk();
        return result.data.name;
      }
    } catch (e) {
      // no-op
    }
  }

  const handleCancel = () => {
    resetModal();
    setModalVisible(false);
  }

  const handleNext = () => {
    setCurrent(current + 1)
  }

  const handlePrev = () => {
    setCurrent(current - 1)
  }



  const Object1IDAttribute = () => {
    if (!et1IsValidated) {
      return <div>{et1InvalidHint}</div>
    } else {
      return <Select
        style={{ width: "100%" }}
        value={object1()?.identity_type.attributes[0].name}
      >
        <OptGroup label={"ID属性"}>
          {object1()?.identity_type.attributes.map(item => (
            <Option key={item.name} value={item.name}>
              <div className={"entity-list-item-name"}>{item.name} ({item.type})</div>
              <div className={"entity-list-item-desc"}>{item.display}</div>
            </Option>
          ))}
        </OptGroup>
      </Select>
    }
  }


  const Step1Content = (
    <>
      <Row>
        <Col span={24}>
          标记 {type2} 的属性 {attribute2.name}({attribute2.type}) 关联到：
        </Col>
      </Row>
      {/* 目标实体类型 */}
      <Row style={{ marginTop: 8 }} align="middle">
        <Col className="acc-desc" span={4}>
          <div className="acc-desc-label">● 实体类型：</div>
        </Col>
        <Col span={20}>
          <Select
            placeholder={'请选择关联的实体类型'}
            value={(relationBrief.type_name1 && (relationBrief.type1 + '#' + relationBrief.type_name1)) || undefined}
            onChange={handleSelectObject1}
            style={{ width: "100%" }}
          >
            {objectsInGroup.map(group => (
              <OptGroup label={group.display}>
                {group.items?.length && group.items.map(item => (
                  <Option
                    value={group.type + '#' + item.name}
                    key={item.name}
                  >
                    <div className={"entity-list-item-name"}>{item.name}</div>
                    <div className={"entity-list-item-desc"}>{item.display}</div>
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Col>
      </Row>
      {/* ID属性 */}
      <Row style={{ marginTop: 8 }} align="middle">
        <Col className="acc-desc" span={4}>
          <div className="acc-desc-label">● ID属性：</div>
        </Col>
        <Col span={20}>
          {Object1IDAttribute()}
        </Col>
      </Row>
    </>
  )


  const Step2Content = (
    <>
      <Row className="left-n-right" gutter={4} align={'middle'} justify="center">
        <Col span={10}>
          <Select
            placeholder={'请选择对象类型'}
            value={(relationBrief.type_name1 && (relationBrief.type1 + '#' + relationBrief.type_name1)) || undefined}
            onChange={handleSelectObject1}
            dropdownMatchSelectWidth className={'select-block-style'}
            disabled={true}
          >
            {objectsInGroup.map(group => (
              <OptGroup label={group.display}>
                {group.items?.length && group.items.map(item => (
                  <Option value={group.type + '#' + item.name}
                    key={item.name}>
                    <div className={"entity-list-item-name"}>{item.name}</div>
                    <div className={"entity-list-item-desc"}>{item.display}</div>
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
          <Input value={`属性:${object1()?.identity_type.attributes[0].name}`} disabled={true}></Input>
        </Col>
        <Col span={4}>
          {"<-----------------"}
        </Col>
        <Col span={10}>
          <Select
            disabled
            placeholder={'请选择对象类型'}
            value={(relationBrief.type_name2 && (relationBrief.type2 + '#' + relationBrief.type_name2)) || undefined}
            onChange={handleSelectObject2}
            dropdownMatchSelectWidth className={'select-block-style'}>
            {objectsInGroup.map(group => (
              <OptGroup label={group.display}>
                {group.items?.length && group.items.map(item => (
                  <Option value={group.type + '#' + item.name}
                    key={item.name}>
                    <div className={"entity-list-item-name"}>{item.name}</div>
                    <div
                      className={"entity-list-item-desc"}>{item.display}</div>
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
          <Input value={`属性: ${attribute2.name}`} disabled={true}></Input>
        </Col>
      </Row>
      <Divider orientation="left" plain={true} >关系名称</Divider>
      <Row className="relation-config-content" gutter={24}>
        <Col className="acc-n-attr" span={24}>
          <section className="acc-panel">
            {/*<h3>关系特征</h3>*/}
            <Row style={{ marginBottom: 8 }} gutter={8}>
              <Col className="acc-desc" span={4}>
                <div className="acc-desc-label">● 关系的名称：</div>
              </Col>
              <Col className="acc-input" span={20}>
                <Input placeholder={'请输入关系名称'}
                  onChange={event => setRelationName(event.target.value)}
                  value={relationName} />
              </Col>
            </Row>
          </section>
        </Col>
      </Row>
      <Divider orientation="left" plain={true} >新建访问属性</Divider>
      <Row className="relation-config-content" gutter={24}>
        <Col className="acc-n-attr" span={24}>
          <section className="acc-panel">
            <Row style={{ marginBottom: 8 }} gutter={8}>
              <Col className="acc-desc" span={16}>
                <div className="acc-desc-label">●
                  每一个{relationBrief.type_name1 || '...'}类型的对象，通过如下属性，访问
                  <span>{relationBrief.multiplicity.endsWith('2MANY') ? '多个' : '一个'}</span>
                  {relationBrief.type_name2 || '...'}类型的对象：
                </div>
              </Col>
              <Col className="acc-input" span={8}>
                <Input placeholder={'请输入访问属性名'}
                  value={relAttr1}
                  onChange={e => setRelAttr1(e.target.value)}
                />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col className="acc-desc" span={16}>
                <div className="acc-desc-label">●
                  每一个{relationBrief.type_name2 || '...'}类型的对象，通过如下属性，访问
                  <span>{relationBrief.multiplicity.startsWith('MANY2') ? '多个' : '一个'}</span>
                  {relationBrief.type_name1 || '...'}类型的对象：
                </div>
              </Col>
              <Col className="acc-input" span={8}>
                <Input placeholder={'请输入访问属性名'}
                  value={relAttr2}
                  onChange={e => setRelAttr2(e.target.value)}
                />
              </Col>
            </Row>
          </section>
        </Col>
      </Row>
    </>
  )

  const steps = [
    {
      title: '选择关联目标',
      description: "",
      content: Step1Content,
    },
    {
      title: '配置关系',
      description: "",
      content: Step2Content,
    },
  ]


  // 组件片段 ---------------------------------------------------

  const footer = (
    <>
      {current === steps.length - 1 && (
        <Button key="ok" type="primary" onClick={handleOk} disabled={!validated}>
          确定
        </Button>
      )}
      {current < steps.length - 1 && (
        <Button key="next" type="primary" onClick={handleNext} disabled={!et1IsValidated}>
          下一步
        </Button>
      )}
      {current > 0 && (
        <Button onClick={handlePrev}>
          上一步
        </Button>
      )}
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  )

  return (
    <>
      {showButton && <Button type="primary" disabled={disabled}
        onClick={() => setModalVisible(true)}>{buttonLabel}</Button>}
      <Modal
        visible={!disabled && modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        title={"创建维度关系"}
        footer={footer}
        width={800}
        className={'relation-creation-modal'}
      >
        <Row style={{ marginBottom: 16 }} justify="center">
          <Col span={12}>
            <Steps current={current} size="small">
              {steps.map(item => (
                <Step key={item.title} title={item.title} description={item.description} />
              ))}
            </Steps>
          </Col>
        </Row>

        <div>
          {steps[current].content}
        </div>
      </Modal>
    </>
  )
}

export default ModalAddDimentionRelationship;
