import dataSourceApi from "../api/requests/dataSourcesReqs"
import {getByPath} from "../sdk/utils";

export function dataSourceList () {
  return dataSourceApi.list();
}

export function dataSourceEET ({dependencies}: any) {
  const dataSource = getByPath(dependencies, [0, 1]);
  if (!dataSource) {
    return null;
  }
  return dataSourceApi.eets(dataSource);
}
