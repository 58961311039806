import {Day4CustomFC} from "../../types";
import env from 'day4-utils/es/env';
import dataSourceApi from "../../api/requests/dataSourcesReqs";

/**
 * copied & hacked from './JsonDataExporterV3.tsx'
 * day4对于链接的处理有一些问题，导致Link不能得到正确的链接。
 * 此处使用的是替代方法。
 *
 * 另外，使用链接下载的方式，与常规rest接口返回数据格式不同，用request
 * 获取数据会报错。
 */


interface ExcelTemplateExporterV3PropType {
  namespace: string,
  text: string
}

const ExcelTemplateExporterV3: Day4CustomFC<ExcelTemplateExporterV3PropType> = ({
  namespace,
  text
}) => {

  const handleClick = (e: any) => {
    e.stopPropagation();
    dataSourceApi.exportTemplate(namespace);
  }
  
  return (
    <a href='javascript:;' onClick={handleClick}>{text}</a>
  )
}

export default ExcelTemplateExporterV3;
