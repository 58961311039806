
import {ACTION_TYPE, IAction, IState} from "./typings";
export  const addDS = (state: IState, action: IAction): IState => {
  const {type, payload} = action;
  switch (type) {
    case ACTION_TYPE.MODAL_VISIBLE:
      return {
        ...state,
        modalVisible: payload as boolean
      }
    default:
      return state;
  }
}

