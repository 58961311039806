import namespaceApi from "../api/requests/namespaceReqs";
import {getByPath} from "../sdk/utils";

export function namespaceList () {
  return namespaceApi.list();
}

export function namespaceDetail ({dependencies}: any) {
  const namespace = getByPath(dependencies, [0, 1]);
  if (!namespace) {
    return null;
  }
  return namespaceApi.detail(namespace);
}

export function namespaceDataSourceList ({dependencies}: any) {
  const namespace = getByPath(dependencies, [0, 1]);
  if (!namespace) {
    return null;
  }
  return namespaceApi.listDataSource(namespace);
}
