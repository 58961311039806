import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Input, InputNumber, Select, Tooltip } from "antd";
import { FC, useCallback, useMemo } from "react";
import { ItemInterface } from "react-sortablejs";
import { DataTypeType } from "../../../api/types/CommonTypes";
import { EntityAttributeType } from "../../../api/types/EntityTypes";


interface AttributeUpsertFormPropType {
  dataTypes: DataTypeType[],
  attribute: EntityAttributeType & ItemInterface,
  onChange: (value: EntityAttributeType & ItemInterface) => void,
  locked?: boolean
}

const LabelFor: FC<{ name: string }> = ({ name, children }) => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
      minHeight: '27px',
      lineHeight: '27px'
    }}>
      <span style={{ width: "4em", textAlign: "right", flex: 'none', marginRight: 12 }}>{name} : </span>
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  )
}

/**
 * 用于修改对象的一个属性的表单。会动态地反馈编辑的结果。
 * @param dataTypes
 * @param attribute
 * @param onChange 更新一个attr
 * @param locked **重要！**决定了表单是否被锁定
 */
const AttributeUpsertForm: FC<AttributeUpsertFormPropType> = ({
  dataTypes,
  attribute,
  onChange,
  locked = false
}) => {
  const { name, display, type, nullable, unique, is_primary, type_options: options, default_value } = attribute;

  const selectedTypeInfo = dataTypes.find(dtype => {
    return dtype.name === type;
  })



  const update = useCallback((key: keyof typeof attribute, value: any) => {
    // open this log to see updating details.
    // console.log('attribute updated: ', key, value, attribute)
    onChange?.({
      ...attribute,
      [key]: value
    })
  }, [attribute, onChange])

  interface InitialTypeOptionDict { [attributeType: string]: InitialTypeOption }
  interface InitialTypeOption { [optionName: string]: any }
  const initialOptionsDict: InitialTypeOptionDict = useMemo(() => {
    const result: InitialTypeOptionDict = {};
    dataTypes.forEach(dtype => {
      const { name, options } = dtype;
      const initialOption = (options || []).reduce((res: InitialTypeOption, item) => {
        res[item.name] = item.defaultValue;
        return res;
      }, {});
      result[name] = initialOption;
    })
    return result;
  }, [dataTypes])

  const updateDataType = useCallback((value) => {
    // console.log('attribute type changed: ', value);
    onChange?.({
      ...attribute,
      type: value,
      type_options: initialOptionsDict[value]
    })
  }, [attribute, initialOptionsDict, onChange])

  const updateTypeOptions = useCallback((key: string, value: any) => {
    // console.log('option updated: ', key, value)
    update('type_options', {
      ...options || {},
      [key]: value
    })
  }, [update, options]);


  const updateDefaultValue = useCallback((type: string, value: any) => {
    console.log('default value updated: ', type, value)
    if (type === null) {
      update('default_value', null)
    } else {
      update('default_value', {
        ...default_value,
        type: type,
        value: value
      })
    }
  }, [update, default_value]);

  // 联动
  const updateDataType2 = useCallback((value) => {
    // console.log('attribute type changed: ', value);
    var newDefaultValue = default_value
    if (name === 'id' && value === 'int') {
      newDefaultValue = {
        type: "AUTO_INC",
        value: null,
      }
    }
    if (name === 'id' && value === 'string') {
      newDefaultValue = {
        type: "RANDOM",
        value: null,
      }
    }
    onChange?.({
      ...attribute,
      type: value,
      type_options: initialOptionsDict[value],
      default_value: newDefaultValue
    })
  }, [attribute, initialOptionsDict, onChange])

  // 临时方案：根据属性过滤可用的数据类型
  const availableDataTypesForAttribute = () => {
    if (name === 'id') {
      return dataTypes.filter((dt) => {
        return dt.name === 'int' || dt.name === "string"
      })
    }
    return dataTypes
  }

  // 根据属性过滤可用的默认值设置
  const defaultValueOptions = [
    {
      name: "NONE",
      display: "",
      desc: "无",
    },
    {
      name: "AUTO_INC",
      display: "AUTO_INC",
      desc: "自增",
    },
    {
      name: "RANDOM",
      display: "RANDOM",
      desc: "随机字符串",
    },
  ]
  const availableDefaultValueOptionsForAttribute = () => {
    var result = [
      {
        name: "NONE",
        display: "",
        desc: "无",
      },
    ]
    if (type === 'int') {
      result.push({
        name: "AUTO_INC",
        display: "AUTO_INC",
        desc: "自增",
      })
    }
    if (name === 'id' && type === "string") {
      result.push({
        name: "RANDOM",
        display: "RANDOM",
        desc: "随机字符串",
      })
    }
    return result
  }

  // 子类型与其它选项生成
  const optionsForParticularType = useMemo(() => {
    if (selectedTypeInfo?.options?.length) {
      return selectedTypeInfo.options.map((option, index) => {
        const { name: optionName, desc: optionDesc, type: inputType } = option;
        // option example
        /*
        * {
            "name": "length",
            "desc": "字符串类型的字符长度",
            "defaultValue": 255,
            "type": "number",
            "min": 0,
            "max": 255
          }
        * */

        // note options could be null, so you must coalesce it!!
        if (inputType === 'number') { // 用数字输入框
          return (
            <LabelFor name={optionDesc} key={index}>
              <InputNumber value={options?.[optionName] ?? 0} disabled={locked}
                onChange={value => updateTypeOptions(optionName, value)}
              />
            </LabelFor>
          )
        } else if (inputType === 'array') { // 用tag输入框
          return (
            <LabelFor name={optionDesc} key={index}>
              <Select mode={"tags"} disabled={locked}
                value={options?.[optionName] || []}
                onChange={valueArr => updateTypeOptions(optionName, valueArr)}
                tokenSeparators={[",", "，"]}
                style={{ width: '100%' }} open={false}
              />
            </LabelFor>
          )
        } else if (inputType === 'select') { // 用下拉选框
          return (
            <LabelFor name={optionDesc} key={index}>
              <Select value={options?.[optionName]}
                style={{ width: '100%' }} disabled={locked}
                onChange={value => updateTypeOptions(optionName, value)}
              >
                {(option?.values || []).map((v: { name: string, value: any }) => {
                  return (
                    <Select.Option key={v.value} value={v.value}>{v.name}</Select.Option>
                  )
                })}
              </Select>
            </LabelFor>
          )
        } else { // 默认使用一个输入框
          return (
            <LabelFor name={optionDesc} key={index}>
              <Input value={options?.[optionName] || ''} disabled={locked}
                onChange={e => updateTypeOptions(optionName, e.target.value)} />
            </LabelFor>
          )
        }
      })
    } else {
      return false;
    }
  }, [selectedTypeInfo, locked, options, updateTypeOptions])

  return (
    <div>
      <LabelFor name={'名称'}>
        <Input value={name} onChange={(e) => update('name', e.target.value)} disabled={locked} />
      </LabelFor>
      <LabelFor name={'显示名'}>
        <Input value={display} onChange={(e) => update('display', e.target.value)} />
      </LabelFor>
      <LabelFor name={'值类型'}>
        <Select value={type} onChange={value => updateDataType2(value)}
          disabled={locked && name !== 'id'}
          dropdownMatchSelectWidth style={{ width: '100%' }}
        >
          {availableDataTypesForAttribute(name).map(dType => {
            const { name, display } = dType;
            return (
              <Select.Option value={name} key={name}>
                <div className={'type-options'}>
                  <span className={'type-name'}>{name}</span>
                  <span className={'type-code'}>{display}</span>
                </div>
              </Select.Option>
            )
          })}
        </Select>
      </LabelFor>
      {optionsForParticularType}
      <LabelFor name={'必填'}>
        {/* 翻转了一下: nullable === false 表示必填 */}
        <Checkbox checked={!nullable} onChange={e => update('nullable', !e.target.checked)} disabled={locked} />
      </LabelFor>
      <LabelFor name={'唯一'}>
        <Checkbox checked={unique} onChange={e => update('unique', e.target.checked)} disabled={locked} />
      </LabelFor>
      <LabelFor name={'ID属性'}>
        <Checkbox checked={is_primary} onChange={e => update('is_primary', e.target.checked)}
          style={{ marginRight: 5 }} disabled={locked}
        />
        <Tooltip placement={'top'} title={'可以指定0至多个属性作为ID'}>
          <QuestionCircleOutlined />
        </Tooltip>
      </LabelFor>
      <LabelFor name={'默认值'}>
        {/* <Input value={default_value} onChange={(e) => update('default_value', e.target.value)}/> */}
        <Select value={default_value ? default_value.type : null} onChange={value => updateDefaultValue(value, null)}
          disabled={locked || type !== "int"}
          dropdownMatchSelectWidth style={{ width: '100%' }}
        >
          {availableDefaultValueOptionsForAttribute().map(dv => {
            const { name, display, desc } = dv;
            return (
              <Select.Option value={name} key={name}>
                <div className={'type-options'}>
                  <span className={'type-name'}>{display}</span>
                  <span className={'type-code'}>{desc}</span>
                </div>
              </Select.Option>
            )
          })}
        </Select>
      </LabelFor>
    </div>
  )
}

export default AttributeUpsertForm;
