import { Affix as AntdAffix, AffixProps } from 'antd';
import React from 'react';
import { Day4CustomFC } from '../../types';

const Affix: Day4CustomFC<AffixProps> = (props) => {
  const { children, ...restProps } = props;
  return <AntdAffix {...restProps}>{children}</AntdAffix>;
};

export default Affix;
