/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Collapse } from "antd";
import copy from 'copy-to-clipboard';
import useStyles from 'day4-components/es/hooks/useStyles';
import GraphiQL from 'graphiql';
// @ts-ignore: no types available
import GraphiQLExplorer from "graphiql-explorer";
import "graphiql/graphiql.css";
import { buildClientSchema, GraphQLSchema } from "graphql";
import { useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalCopyCode } from '..';
import dataServiceApi from "../../api/requests/dataServiceReqs";
import {
  DataFrameDataType,
  GraphqlParamType
} from "../../api/types/DataServiceTypes";
import { Day4CustomFC } from '../../types';
import DataFrameViewer from "./components/DataFrameViewer";
import ModalSaveDataFrame from "./components/ModalSaveDataFrame";
import ModalSaveTemplate from './components/ModalSaveTemplate';
import { getDefaultScalarArgValue, makeDefaultArg } from "./CustomArgs";




const { Panel } = Collapse;
const noop = () => { };

interface GraphiQLProps {
  namespace: string;
  query?: string;
  defaultQuery?: string;
  variables?: string;
  styles?: any;
  templateName?: string,
  templateDesc?: string,
  defaultVariables?: string;
  showGraphelExampleCode?: boolean;
  graphqlExampleButtonLabel: string,
  graphqlExampleShellCode: string,
  showDataframeExampleCode?: boolean;
  dataframeExampleButtonLabel: string,
  dataframeExampleShellCode: string,
}

const gqlExplorerPanelTitleStyleHack = css`
  .history-title-bar {
    height: initial;
  }
  .doc-explorer-title-bar {
    height: initial;
  }
  .graphiql-explorer-root .graphiql-operation-title-bar span input {
    width: auto !important;
    max-width: 10em !important;
  }
`

const MyGraphiQL: Day4CustomFC<GraphiQLProps> = ({
  namespace,
  query,
  defaultQuery,
  variables,
  defaultVariables,
  templateName,
  templateDesc,
  styles,
  showGraphelExampleCode,
  graphqlExampleButtonLabel,
  graphqlExampleShellCode,
  // showDataframeExampleCode,
  // dataframeExampleButtonLabel,
  // dataframeExampleShellCode,
}) => {
  // 处理默认值
  showGraphelExampleCode = showGraphelExampleCode ? showGraphelExampleCode : true
  // showDataframeExampleCode = showDataframeExampleCode ? showDataframeExampleCode : true


  const componentCss = useStyles([], styles);
  const location: any = useLocation();

  // 从模板中跳转过来时，接收参数
  if (location.state && location.state.query) {
    defaultQuery = location.state.query
  }
  if (location.state && location.state.variables) {
    variables = JSON.stringify(location.state.variables)
  }

  // graphiQL instance ref
  const gref = useRef<GraphiQL>(null);

  const param = {
    query: window.location.href.split('query=').length === 2 ? window.location.href.split('query=')[1].split('&')[0] : '',
    variables: window.location.href.split('variables=').length === 2 ? window.location.href.split('variables=')[1].split('&')[0] : ''
  };

  // used for data frame saving.
  const [lastSuccessParam, setLastSuccessParam] = useState<GraphqlParamType | null>(param);

  const [successQuery, setSuccessQuery] = useState<GraphqlParamType | null>(param);
  // used for data frame preview.
  const [dataFrame, setDataFrame] = useState<DataFrameDataType | null>(null);
  // "ready" or "editing"
  // 用于解决一个Bug，当collapse-panel上有一个按钮触发modal的时候，
  // 后续鼠标的点击会导致collapse被误触发。
  const [editingState, setEditingState] = useState('ready');
  // used for oneGraph-explorer
  const [isExplorerOpen, setExplorerOpen] = useState(false);

  const [schema, setSchema] = useState<GraphQLSchema | null>(null);
  const [internalQuery, setInternalQuery] = useState(query || defaultQuery || '');
  const [dynamicVariables, setDynamicVariables] = useState(variables || defaultVariables || '');
  // dataframe是否能够正常执行
  const [isRun, setIsRun] = useState(false);
  // query是否正常执行
  const [isqueryRun, setIsqueryRun] = useState(false);


  // callbacks ----------------------------------------------------

  const fetcher = useCallback(
    (graphQLParams: GraphqlParamType) => {
      const { query } = graphQLParams;
      // console.log('gqlQuery -> ', query);
      // fix: graphiql will introspect the schema
      //   using something like `query IntrospectionQuery`,
      //   so skip these queries conversion.
      const isIntrospectionQuery = /^\s+query IntrospectionQuery/m.test(query);
      if (!isIntrospectionQuery) {
        dataServiceApi.dataFramePreview(namespace, graphQLParams)
          .then(res => res!.data)
          .then(data => {
            setIsRun(true);
            setLastSuccessParam(graphQLParams);
            setDataFrame(data);
          }).catch(err => {
            setIsRun(false);
            // todo err 有三种情况
            // 1. gql错误
            // 2. 参数错误
            // 3. 转换异常
            // 这三种情况可以通过info和message来判断，从而定制
            // 显示内容。打算通过在preview组件上增加error实现。
            console.error("dataframe error: ", err);
            setDataFrame(null);
          });
      }
      // for graphiQL use
      const result = dataServiceApi.gql(namespace, graphQLParams).then((res: any) => {
        if (typeof res === 'undefined') {
          setIsqueryRun(false);
          throw new Error('no data');
        }
        if (res?.data && !isIntrospectionQuery) {
          setSuccessQuery(graphQLParams);
          setIsqueryRun(true);
        } else {
          setIsqueryRun(false);
        }
        return res;
      });

      // build schema for oneGraph-explorer
      if (isIntrospectionQuery) {
        return result.then(res => {
          setSchema(buildClientSchema(res?.data));
          return res;
        })
      } else {
        return result;
      }
    },
    [namespace]
  );

  const handleToggleExplorer = () => {
    // message.success('ready to open explorer!');
    setExplorerOpen(!isExplorerOpen);
  }

  const dataframeToolbar = isRun ? (
    < ModalSaveDataFrame
      namespace={namespace}
      param={lastSuccessParam}
      setEditingState={setEditingState}
    />
  ) : (<></>);

  const handleEditQuery = (s: string | undefined) => {
    if (typeof s === 'undefined') {
      return;
    }
    // 动态修改url的参数
    let href = window.location.href;
    if (href.indexOf('query=') > -1) {
      href = href.replace(/query=[^&]*/, `query=${s}`);
    } else if (href.indexOf('graphiql?') > -1) {
      href += `&query=${s}`
    } else {
      href += `?query=${s}`
    }
    window.location.href = href;
    // 是否写入缓存？
    setInternalQuery(s);
  }

  const handleEditVariables = (s: string | undefined) => {
    if (typeof s === 'undefined') {
      return;
    }
    // 动态修改url的参数
    let href = window.location.href;
    if (href.indexOf('variables=') > -1) {
      href = href.replace(/variables=[^&]*/, `variables=${s}`);
    } else if (href.indexOf('graphiql?') > -1) {
      href += `&variables=${s}`
    } else {
      href += `?variables=${s}`
    }
    window.location.href = href;
    setDynamicVariables(s);
  }

  const handleShare = () => {
    copy(window.location.href);
  }

  return (
    /* note: you need this class-name for GqlExplorer to style correctly. */
    <div css={[componentCss, gqlExplorerPanelTitleStyleHack]} className="graphiql-container">
      <GraphiQLExplorer
        schema={schema}
        query={internalQuery}
        onEdit={handleEditQuery}
        onRunOperation={(operationName: string) => { gref.current?.handleRunQuery(operationName) }}
        getDefaultScalarArgValue={getDefaultScalarArgValue}
        makeDefaultArg={makeDefaultArg}

        explorerIsOpen={isExplorerOpen}
        onToggleExplorer={handleToggleExplorer}
      />
      <GraphiQL
        ref={gref}
        fetcher={fetcher}
        query={internalQuery}
        defaultQuery={defaultQuery}
        onEditQuery={handleEditQuery}
        onEditVariables={handleEditVariables}
        variables={dynamicVariables}
      >
        <GraphiQL.Toolbar>
          <GraphiQL.Button
            onClick={gref.current?.handlePrettifyQuery || noop}
            label="格式化"
            title="Prettify Query (Shift-Ctrl-P)"
          />
          <GraphiQL.Button
            onClick={gref.current?.handleToggleHistory || noop}
            label="历史"
            title="Show History"
          />
          <GraphiQL.Button
            onClick={handleToggleExplorer}
            label="Explorer"
            title="Toggle Explorer"
          />
          <GraphiQL.Button
            onClick={handleShare}
            label="分享"
            title="Share By Copying Link"
          />
          <ModalSaveTemplate
            namespace={namespace}
            param={successQuery}
            setEditingState={setEditingState}
            name={templateName}
            desc={templateDesc}
          />
          <ModalCopyCode
            showButton={showGraphelExampleCode}
            buttonLabel={graphqlExampleButtonLabel}
            shellCode={graphqlExampleShellCode}
          />
        </GraphiQL.Toolbar>
        <GraphiQL.Footer>
          <Collapse collapsible={editingState === 'editing' ? 'header' : undefined}>
            <Panel header={"Dataframe视图"} key={1} extra={dataframeToolbar}>
              <DataFrameViewer dataFrame={dataFrame} />
            </Panel>
          </Collapse>
        </GraphiQL.Footer>
      </GraphiQL>
    </div>
  );
};

export default MyGraphiQL;
