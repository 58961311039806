import ModalAddTags from "./ModalAddTags"

ModalAddTags.register = {
  attributes: [
		{
      name: 'ns',
      type: 'template'
    },
		{
      name: 'resourceType',
      type: 'template'
    },
		{
      name: 'resourceName',
      type: 'template'
    },
		{
      name: 'initialValue',
      type: 'TagType[]',
    },
  ],
  events: [
  ],
  editorConfig: {
    defaultValue: {
      initialValue: []
    }
  },
}


export {ModalAddTags}