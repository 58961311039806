import ModalBindDataSource from "./ModalBindDataSource";

ModalBindDataSource.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'showButton',
      type: "boolean"
    }
  ],
  events: [
    {
      type: 'ok'
    }
  ],
  editorConfig: {
    defaultValue: {
      showButton: true
    }
  }

}

export default ModalBindDataSource
