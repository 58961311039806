import {Day4CustomFC} from "../../types";
import {Button, message} from "antd";
import {useState} from "react";
import dataSourceApi from "../../api/requests/dataSourcesReqs";

interface ReloadButtonPropType {
  dataSource: string,
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
  buttonText?: string,
  onOk: () => void
}

const ReloadButton: Day4CustomFC<ReloadButtonPropType> = ({
  dataSource,
  method = 'POST',
  buttonText = '刷新',
  onOk
}) => {
  const [loading, setLoading] = useState(false);

  const handleReload = () => {
    setLoading(true);
    dataSourceApi.reload(dataSource).then(res => {
      message.success('刷新成功！');
      onOk && onOk();
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <Button onClick={handleReload}
            loading={loading}
            disabled={!dataSource}
    >{buttonText}</Button>
  )
}

export default ReloadButton;
