import { gql } from '@apollo/client';
import client from '../sdk/graphql/client';

const QUERY_CASES = gql`
query cases_by_filter($title: String, $industries: industryComplexFilter, $business_scenes: business_sceneComplexFilter, $suitables: suitableComplexFilter, $order_by: [casesSort]) {
  cases(filter: {
    and: {
      title_like_: $title,
      or: {
        industry: $industries,
        business_scene: $business_scenes,
        suitable: $suitables
      }
    },
    status: "PUBLISHED"
  }, orderBy: $order_by) {
    id
    name
    title
    description
    creator
    create_time
    last_update_time
    wip
    industry {
      id
      label
    }
    business_scene{
      id
      label
    }
    suitable {
      id
      label
    }
    background {
      uri
    }
  }
}
`;

export async function caseList({ dependencies }: any) {
  const [searchInput, industries, businessScenes, suitables, orderBy] =
    dependencies;

  const variables: any = {};

  if (searchInput?.value) {
    variables.title = searchInput?.value;
  }

  if (industries?.value?.length > 0) {
    variables.industries = {
      or: industries.value.map((id: number) => ({ id })),
    };
  }

  if (businessScenes?.value?.length > 0) {
    variables.business_scenes = {
      or: businessScenes.value.map((id: number) => ({ id })),
    };
  }

  if (suitables?.value?.length > 0) {
    variables.suitables = { or: suitables.value.map((id: number) => ({ id })) };
  }

  if (orderBy?.value) {
    variables.order_by = orderBy?.value;
  }

  return client.query({
    query: QUERY_CASES,
    fetchPolicy: 'no-cache',
    variables,
  });
}
