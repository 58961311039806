import ClassicEntityNode from './EntityNode'

import {FC} from "react";
import {
  AugmentedEntityType,
  Color,
  ReactFlowCustomNodePropType
} from "../types";
import DynamicHandleOpaqueEntityNode from "./DynamicHandleOpaqueEntityNode";

type CustomNodeType = FC<ReactFlowCustomNodePropType<AugmentedEntityType>>

// 自定义节点的注册表。key是节点类型（type）
export const nodeRegistry: Record<string, CustomNodeType> = {
  entity: ClassicEntityNode,
  dynamic_handle_opaque_entity: DynamicHandleOpaqueEntityNode
}

// 自定义节点在小地图（minimap）中的颜色，key是节点类型（type）
export const minimapNodeColors: Record<string, Color> = {
  entity: 'rgb(220,220,255)',
  dynamic_handle_opaque_entity: 'rgb(220,220,255)'
}
