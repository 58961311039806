import React from 'react';
import PopGroup from './PopGroup';
import { ReactComponent as HangyeSvg } from '../../svgs/hangyefenlei.svg';
import { ReactComponent as YewuSvg } from '../../svgs/yewuchangjing.svg';
import { ReactComponent as GongyeSvg } from '../../svgs/gongyeduixiang.svg';
import { Day4CustomFC } from '../../types';
import { Checkbox } from 'antd';

import { Option } from './PopGroup';

const iconMap = {
  hangyefenlei: HangyeSvg,
  yewuchangjing: YewuSvg,
  gongyeduixiang: GongyeSvg,
};

interface Props {
  value?: any[];
  onChange?: (v: any[]) => void;
  icon?: keyof typeof iconMap;
  text?: string;
  options?: Option[];
}

const CheckboxGroup: Day4CustomFC<Props> = (props) => {
  const { value, onChange, icon, text = 'text', options } = props;

  const handleChange = (val: any[]) => {
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <Checkbox.Group value={value} onChange={handleChange}>
      <PopGroup
        value={value}
        title={text}
        icon={icon && iconMap[icon]}
        options={options}
      />
    </Checkbox.Group>
  );
};

export default CheckboxGroup;
