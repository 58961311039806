/** @jsxImportSource @emotion/react */
import styles from 'day4-components/es/styles';
import {useEffect, useState} from 'react'
import {Day4CustomFC} from '../../types';

import {Button, Form, Input, message, Modal, Radio} from 'antd'
import {useMutation} from "react-query";
import {EntityType} from "../../api/types/EntityTypes";
import entityApi from "../../api/requests/entityReqs";
import ModalUpsertAttributes from "./ModalUpsertAttributes";

import {DoubleRightOutlined} from '@ant-design/icons'
import {ModalBindEET} from "../index";

interface ModalAddEntityPropType {
  namespace: string,
  onOk: () => void
}

const ModalAddEntity: Day4CustomFC<ModalAddEntityPropType> = ({
  namespace,
  onOk
}) => {
  // 实体类创建弹窗
  const [isEntityCreationModalShown, setEntityCreationModalShown] = useState(false);
  // 实体属性列表弹窗
  const [isAttributeUpsertionModalShown, setAttributeUpsertionModalShown] = useState(false);
  // 绑定外部实体类弹窗
  const [isEETBindingModalShown, setEETBindingModalShown] = useState(false);

  const [entityToUpsertAttributes, setEntityToUpsertAttributes] = useState<string | null>(null);

  // 接口获取模版类型的值
  const [templateType, setTemplateType] = useState<any>([]);

  // 用户表单中选中的模板类型
  const [entityTemplateValue, setEntityTemplateValue] = useState('');

  const [form] = Form.useForm();

  const mutationCreateEntity =
    useMutation((newEntity: EntityType) => entityApi.create(newEntity));

  const mutationGetEntityTemplate =
  useMutation(() => entityApi.entityTemplate());

  const handleOk = async () => {
    try {
      // 表单校验，验证通过返回表单值，失败则抛出异常
      const {fileType, entityTemplate, ...values} = await form.validateFields();
      const templateObj: any = templateType.filter((item: any) => item.name === entityTemplateValue)[0];
      values.attributes = templateObj.system_attributes;
      values.annotations = templateObj.annotations;
      // 执行mutation，使用异步方法，成功则返回ResponseBody，失败则抛出异常
      const result = await mutationCreateEntity.mutateAsync({
        namespace,
        ...values,
        labels: {}
      });
      // result可能为undefined。如有data，表示成功
      if (result?.data) {
        message.success('创建成功！');

        form.resetFields();
        setEntityCreationModalShown(false);
        // 调用Day4-event
        onOk && onOk();
        return result.data.name;
      }
    } catch (e) {
      // no-op
    }
  }

  const showDialog = async () => {
    setEntityCreationModalShown(true);
    setEntityTemplateValue('');
    const res = await mutationGetEntityTemplate.mutateAsync();
    setTemplateType(res?.data as any || []);
  }

  const handleGoOnUpsertingAttributes = async () => {
    const entity = await handleOk();

    // for dev
    // const entity = 'equipment';
    // setEntityCreationModalShown(false);

    if (entity) {
      setEntityToUpsertAttributes(entity);
      setAttributeUpsertionModalShown(true);
    }
  }

  const handleCancel = () => {
    form.resetFields();
    setEntityCreationModalShown(false);
    setEETBindingModalShown(false);
  }

  const handleGoExternal = () => {
    setEntityCreationModalShown(false);
    setEETBindingModalShown(true);
  }

  const handleBindEETOk = () => {
    setEETBindingModalShown(false);
    onOk && onOk();
  }

  // 设置弹框中的模板类型的默认值
  useEffect(() => {
    setEntityTemplateValue(templateType[0]?.name || '');
  }, [templateType]);

  const title = (
    <div css={[
      styles.display.flex,
      styles.alignItems.center
    ]}>
      <span css={[
        styles.fontWeight.bold
      ]}>创建实体类型</span>
      <Button key="goExternal" type="link"
              onClick={handleGoExternal}
              css={[
        styles.marginLeft('auto')
      ]}
      >绑定外部实体类<DoubleRightOutlined /></Button>
    </div>
  )

  const footer = (
    <>
      <Button key="ok" type="primary" onClick={handleOk}>创建实体类型</Button>
      <Button key="goOn" type="primary" onClick={handleGoOnUpsertingAttributes}>...并编辑属性</Button>
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  )

  return (
    <>
      <Button type="primary" onClick={() => {showDialog()}}>创建实体类型</Button>
      <Modal visible={isEntityCreationModalShown}
             title={title} footer={footer}
             closable={false} maskClosable={false}
      >
        <Form
          name="createEntity"
          form={form}
          labelCol={{span: 4}}
          // initialValues={{
          //   name: 'new-entity',
          //   display: '默认实体类',
          //   desc: '实体类描述'
          // }}
        >
          <Form.Item
            label="名称"
            name="name"
            rules={[
              {required: true, message: '请输入名称'}
            ]}
          ><Input placeholder={"例如:contacts_01"}/></Form.Item>
          <Form.Item
            label="显示名"
            name="display"
            rules={[
              {required: true, message: '请输入显示名'}
            ]}
          ><Input placeholder={"例如:联系人"}/></Form.Item>
          <Form.Item
            label="描述"
            name="desc"
            rules={[
              {type: "string", max: 128}
            ]}
          ><Input.TextArea rows={3}
              placeholder={"例如:联系人列表"}/></Form.Item>
          <Form.Item
            label="模板"
            name="entityTemplate"
            rules={[
              {type: "string", max: 128}
            ]}
          > <Radio.Group onChange={(e: any) => {
            setEntityTemplateValue(e.target.value);
          }} value={entityTemplateValue}>
            {templateType.map((item: any) => (<Radio value={item.name}>{item.name}</Radio>))}
          </Radio.Group></Form.Item>
            {/* {entityTemplateValue ===  '文件对象' && <Form.Item
              label="文件类型"
              name="fileType"
              rules={[
                {type: "string", max: 128}
              ]}
            > <Radio.Group onChange={(e: any) => {
              // console.log(e.target.value);
            }}>
              <Radio value={'text'}>文本</Radio>
              <Radio value={'img'}>图片</Radio>
              <Radio value={'video'}>视频</Radio>
            </Radio.Group></Form.Item>} */}
        </Form>
      </Modal>

      {(isAttributeUpsertionModalShown && entityToUpsertAttributes)
      && <ModalUpsertAttributes
        namespace={namespace}
        showButton={false}
        entity={entityToUpsertAttributes}
        onClose={() => setAttributeUpsertionModalShown(false)}
        onCancel={() => setAttributeUpsertionModalShown(false)}
      />}

      {isEETBindingModalShown
      && <ModalBindEET
        namespace={namespace}
        showButton={false}
        onOk={handleBindEETOk}
        onCancel={() => {setEETBindingModalShown(false)}}
      />}
    </>
  )
}

export default ModalAddEntity;
