import UML from './UML';

/**
 * 实体关系图。和数据库表结构是有区别的，
 * 区别主要在于对“关系”的显示上。实体关系图中，
 * 关系用节点之间的连线表示，而在数据库表结构中，
 * 关系有关联表与外键两种实现方式。
 *
 * 其他区别详见开发文档
 *
 * 相比于v2，v3增加了“访问属性”的概念。
 * 这使得两个对象的连接，是访问属性到对象的连接。
 *
 * 注意：v3中强调，一个namespace内，各种对象的name不会冲突。
 * 所以会采用name作为图中节点的id
 */
export default UML;
