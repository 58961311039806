import {Day4CustomFC} from "../../types";
import useMyQuery from "../../api/useMyQuery";
import namespaceApi from "../../api/requests/namespaceReqs";
import {useMemo, useState} from "react";
import {Button, message, Table} from "antd";
import {
  DraftsType,
  ETDraftType,
  RelationDraftType
} from "../../api/types/CommonTypes";
import {useMutation, useQueryClient} from "react-query";

interface DraftManagerPropType {
  namespace: string,
  onOk: () => void
}

interface TableRowType {
  key: string,
  name: string,
  desc?: string,
  display?: string,
  type: 'entity' | 'relation' | 'attribute',
  parentPath?: string[],
  children?: TableRowType[]
}

const DraftManager: Day4CustomFC<DraftManagerPropType> = ({
  namespace,
  onOk
}) => {
  const {data: draftResponse} = useMyQuery(['drafts', namespace], namespaceApi.listDrafts);
  const drafts = draftResponse?.data;

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const tableColumns = [
    {
      title: '类型',
      dataIndex: 'type',
      key: 'key',
      width: '12em'
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'key'
    },
    {
      title: '显示名',
      dataIndex: 'display',
      key: 'key'
    }
  ]

  const queryClient = useQueryClient();
  const mutationCommit = useMutation((drafts: DraftsType) => namespaceApi.commit(drafts), {
    onSuccess: () => queryClient.invalidateQueries('drafts')
  });

  // 计算出来的数据 ---------------------------------------------------------

  // tableData 是一个树形结构，以children为节点展开
  // 设计上，父节点选择时，子节点全选；子节点选择时，父节点关联选中。
  const tableData = useMemo<TableRowType[]>(() => {
    if (!drafts) {
      return []
    }
    const {entity_types, relation_types} = drafts;
    // entity
    const entity_data: TableRowType[] = (entity_types || []).map((et: ETDraftType) => {
      const {name, attributes} = et;
      return {
        ...et,
        type: 'entity',
        key: `entity#${name}`,
        children: attributes?.map(attr => {
          const {name: attrName} = attr;
          return {
            ...attr,
            type: 'attribute',
            key: `entity_attribute#${name}#${attrName}`,
            parentPath: [`entity#${name}`]
          }
        }) || undefined
      }
    });
    // relation
    const relation_data: TableRowType[] = (relation_types || []).map((rel: RelationDraftType) => {
      const {name, attributes} = rel;
      return {
        ...rel,
        type: 'relation',
        key: `relation#${name}`,
        children: attributes?.map(attr => {
          const {name: attrName} = attr;
          return {
            ...attr,
            type: 'attribute',
            key: `relation_attribute#${name}#${attrName}`,
            parentPath: [`relation#${name}`]
          }
        }) || undefined
      }
    });

    return [...entity_data, ...relation_data]
  }, [drafts]);

  // 所有可供提交的对象（实体/关系/属性），形成一个字典
  const draftsMap = useMemo<Record<string, ETDraftType | RelationDraftType>>(() => {
    const result: Record<string, ETDraftType | RelationDraftType> = {};
    if (drafts?.entity_types?.length) {
      drafts.entity_types.forEach((et: ETDraftType) => {
        const {name} = et;
        const key = `entity#${name}`;
        result[key] = et;
      })
    }
    if (drafts?.relation_types?.length) {
      drafts.relation_types.forEach((rel: RelationDraftType) => {
        const {name} = rel;
        const key = `relation#${name}`;
        result[key] = rel;
      })
    }
    return result;
  }, [drafts]);

  // validated是前端对于选项的校验
  const validated = useMemo(() => {
    if (!selectedRowKeys?.length) {
      return false;
    }
    const selectedKeySet = new Set(selectedRowKeys);
    const unselectedEntities = Object.keys(draftsMap)
      .filter(key => !selectedKeySet.has(key))
      .map(key => draftsMap[key]);
    const selectedRelations = selectedRowKeys
      .filter(key => key.startsWith('relation#'))
      .map(key => draftsMap[key]);

    // todo implement this logic
    // 选中的关系，不能包含未选中的实体（以及其他对象）
    return true;
  }, [selectedRowKeys, draftsMap])

  // 用于提交的数据。是drafts根据selectedKeys的过滤
  // drafts有一种特殊的“遍历”算法。
  const draftsToCommit = useMemo<DraftsType>(() => {
    if (!drafts) {
      return {
        name: namespace,
        entity_types: null,
        relation_types: null
      }
    }

    // 'traverse' the drafts and collect selected items
    // note: pay attention to `null` here! In backend, empty array is represented as null!
    const selected = new Set<string>(selectedRowKeys);
    const {entity_types, relation_types} = drafts;
    const result: DraftsType = {
      name: namespace,
      // entity
      entity_types: entity_types?.filter(et => {
        const {name} = et;
        const key = `entity#${name}`;
        return selected.has(key);
      })?.map(et => {
        const {name, attributes} = et;
        const filteredAttributes = attributes?.filter(attr => {
          const {name: attrName} = attr;
          const key = `entity_attribute#${name}#${attrName}`;
          return selected.has(key);
        }) || null;
        return {
          ...et,
          attributes: filteredAttributes
        }
      }) || null,
      // relation
      relation_types: relation_types?.filter(rel => {
        const {name} = rel;
        const key = `relation#${name}`;
        return selected.has(key);
      })?.map(rel => {
        const {name, attributes} = rel;
        const filteredAttributes = attributes?.filter(attr => {
          const {name: attrName} = attr;
          const key = `relation_attribute#${name}#${attrName}`;
          return selected.has(key);
        }) || null;
        return {
          ...rel,
          attributes: filteredAttributes
        }
      }) || null
    }

    return result;
  }, [namespace, selectedRowKeys, drafts])

  // 回调区 -----------------------------------------------------------------

  const handleCommit = async () => {
    if (validated) {
      // message.success('ready to commit. See console.log');
      // console.log(draftsToCommit);
      mutationCommit.mutateAsync(draftsToCommit)
        .then(() => {
          message.success('发布成功！');
          onOk && onOk();
        }).catch(err => {
          // no-op
      })
    }
  }

  const selectionStrategy = {
    checkStrictly: false,

    // DO NOT OPEN THIS LINE!
    // selectedRowKeys: selectedRowKeys,

    // 虽然只选了根节点，但是上层的节点也要选择
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      // console.log(selectedRowKeys);
      // console.log(selectedRows);
      const keys: string[] = Array.from(new Set<string>([
        ...selectedRowKeys,
        ...selectedRows.flatMap((row: TableRowType) => row.parentPath || [])
      ]));
      setSelectedRowKeys(keys);
    }
  }

  // render ----------------------------------------------------------------

  return (
    <div>
      {/*<h1>The future draft manager for namespace [{namespace}]</h1>*/}
      <Button onClick={handleCommit} type={'primary'}
              disabled={!validated} loading={mutationCommit.isLoading}
      >发布</Button>
      <Table
        columns={tableColumns}
        dataSource={tableData}
        rowSelection={selectionStrategy}
        pagination={false}
      ></Table>
    </div>
  )
}

export default DraftManager
