import {ObjectType} from "../../../api/types/CommonTypes";
import useMyQuery from "../../../api/useMyQuery";
import entityApi from "../../../api/requests/entityReqs";

export type ObjectNameGroup = {
  type: ObjectType,
  display: string,
  items: {
    name: string,
    display: string
  }[]
}[]

type NamespaceQueryHookType = (namespace: string) => [result: ObjectNameGroup]

const useNamespaceObjectQuery: NamespaceQueryHookType = (namespace) => {
  const result: ObjectNameGroup = [];
  // Entity
  const {data: entityDataResponse} = useMyQuery(['entities', namespace], entityApi.list, {
    enabled: !!namespace
  });
  const entityData = (entityDataResponse?.data || []).filter(et => !et.is_external);
  result.push({
    type: 'ENTITY_TYPE',
    display: '实体类型',
    items: entityData.map(et => ({
      name: et.name,
      display: et.display || et.name
    }))
  })
  // fix: add EXTERNAL_ENTITY_TYPE
  const eetData = (entityDataResponse?.data || []).filter(et => et.is_external);
  result.push({
    type: 'EXTERNAL_ENTITY_TYPE',
    display: '外部实体类',
    items: eetData.map(et => ({
      name: et.name,
      display: et.display || et.name
    }))
  })
  // todo VO

  return [result];
}

export default useNamespaceObjectQuery;

