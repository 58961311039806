export const spriteIcon = (icon, className = '') => {
  const classAttribute = className.length > 0 ? `class="${className}"` : '';

  return `<svg ${classAttribute}><use xlink:href="${window.gon?.sprite_icons}#${icon}" /></svg>`;
};

/**
 * Method to round values to the nearest half (0.5)
 *
 * Eg; roundToNearestHalf(3.141592) = 3, roundToNearestHalf(3.41592) = 3.5
 *
 * Refer to spec/frontend/lib/utils/common_utils_spec.js for
 * more supported examples.
 *
 * @param {Float} number
 * @returns {Float|Number}
 */
 export const roundToNearestHalf = (num) => Math.round(num * 2).toFixed() / 2;
