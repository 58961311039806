import Tag from './Tag';

Tag.register = {
  attributes: [
    {
      name: 'text',
      type: 'template',
    },
  ],
  editorConfig: {
    defaultValue: {
      text: 'Tag',
    },
  },
};

export default Tag;

