import {ReactFlowState, useStoreState} from "react-flow-renderer";
import useMyQuery from "../../../api/useMyQuery";
import namespaceApi from "../../../api/requests/namespaceReqs";
import useDebounce from "react-use/esm/useDebounce";
import produce from "immer";
import {useState} from "react";
import {equals} from 'ramda'
import {PositionGuide} from "../types";

const usePositionSaving = (namespace: string) => {

  const [oldPositionGuide, setOldPositionGuide] = useState<PositionGuide>({});

  // get internal state from the react-flow (diagram).
  const nodes = useStoreState((state: ReactFlowState) => state.nodes);

  // 获取namespace信息（主要用它的labels）
  const {
    data: namespaceResponse,
    refetch: refetchNamespaceInfo
  } = useMyQuery(['namespace-info', namespace], namespaceApi.detail, {
    refetchOnWindowFocus: false
  });

  const namespaceInfo = namespaceResponse?.data;

  useDebounce(
    () => {
      if (!namespaceInfo) { return; }
      // 保存新的 position
      // console.log(nodes);
      const positionGuide = nodes.reduce((res: PositionGuide, item) => {
        const {id} = item;
        res[id] = item.__rf.position;
        return res;
      }, {});

      /* note: this seems like a design flaw where highlight
           info are stored inside nodes, so that every time the user
           hover the mouse on the node, will the saving process be called.

         So here I use ramda#equals to prevent excessive savings.
       */
      if (equals(positionGuide, oldPositionGuide)) {
        return;
      } else {
        setOldPositionGuide(positionGuide);
      }

      /* old ways to save positionGuide */
      // localforage.setItem<Record<string, XYPosition>>('positionGuide@' + namespace, positionGuide);

      const newNamespaceInfo = produce(namespaceInfo, (nsInfo) => {
        if (!nsInfo.labels) {
          nsInfo.labels = {}
        }
        nsInfo.labels['positionGuide'] = positionGuide;
        nsInfo.old_namespaceName = nsInfo.name;
      });
      namespaceApi.update(newNamespaceInfo).then(() => {
        return refetchNamespaceInfo({
          throwOnError: false,
          cancelRefetch: true
        })
      }).catch(err => {
        console.error(err);
      });
    },
    2000,
    [namespace, nodes]
  );

  return [namespaceInfo, nodes];
}

export default usePositionSaving;
