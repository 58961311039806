import { Button, Form, message } from "antd";
import env from 'day4-utils/es/env';
import moment from "moment";
import { useState } from "react";
import { useMutation } from "react-query";
import dataFrameApi from "../../../api/requests/dataFrameReqs";
import { DataFrameSubmitType } from "../../../api/types/DataFrameTypes";
import { GraphqlParamType } from "../../../api/types/DataServiceTypes";
import { Day4CustomFC } from "../../../types";

interface ModalSaveDataFramePropType {
  namespace: string,
  /* 包含了执行gql的query和variables */
  // todo: add gql headers if necessary.
  param: GraphqlParamType | null,
  onOk?: () => void,
  // 向上传递editingState
  setEditingState?: (value: string) => void
}

interface FormDataType {
  name: string,
  display: string,
  desc: string,
  variables: string
}

const ModalSaveDataFrame: Day4CustomFC<ModalSaveDataFramePropType> = ({
  namespace,
  param,
  onOk = () => { },
  setEditingState = () => { }
}) => {

  const [modalVisible, setModalVisible] = useState(false);

  const [form] = Form.useForm<FormDataType>();

  const mutationSaveDataFrame =
    useMutation((newDataFrame: DataFrameSubmitType) => dataFrameApi.create(newDataFrame));

  const handleSave = (e: any) => {
    e.stopPropagation();
    setModalVisible(true);
    setEditingState("editing");
  }

  const handleDownload = async (e: any) => {
    e.stopPropagation();
    saveFile(`${env.DAY4_APP_MODELER_GRAPHQL}/namespaces/${namespace}/data/_export?query=${param!.query}&variables=${JSON.stringify(param!.variables)
      }`, moment().format('YYYY-MM-DD HH:mm:ss') + '.csv');
  }

  const handleOk = async () => {
    if (!param || !param.query) {
      message.error('GraphQL查询已过期，请重新发送请求');
      setModalVisible(false);
      form.resetFields();
      setEditingState("ready");
    }

    try {
      const { name, display, desc, variables } = await form.validateFields();

      const varsStringTrimmed = variables.trim();

      const submitting: DataFrameSubmitType = {
        namespace: namespace,
        name,
        display: display.trim(),
        desc,
        query: param!.query,
        variables: varsStringTrimmed === '' ? {} : JSON.parse(varsStringTrimmed)
      }

      const result = await mutationSaveDataFrame.mutateAsync(submitting);
      if (result?.data) {
        message.success('已保存DataFrame: ' + name);
        setModalVisible(false);
        form.resetFields();
        setEditingState("ready");
      }
    } catch (e) {
      // no-op
      console.error("ModalSaveDataFrame: ", e);
    }
  }

  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
    setEditingState("ready");
  }

  function saveFile(url: string, name = 'download.txt') {
    let link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
  }

  const footer = (
    <>
      <Button key="ok" type="primary" onClick={handleOk}>确定</Button>
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  );

  // JSON校验。阻止不合法JSON字符串提交，另外允许空字符串
  const simpleJsonValidator = (rule: any, value: string) => {
    if (value.trim() === '') {
      return Promise.resolve();
    }
    try {
      JSON.parse(value);
    } catch (e) {
      return Promise.reject('illegal JSON format');
    }
    return Promise.resolve();
  }
  return (
    <>
      <Button size="small" type="default"
        disabled={!param || !namespace}
        style={{ marginLeft: '15px' }}
        onClick={handleDownload}
      >下载</Button>
    </>
  )
}

export default ModalSaveDataFrame;

