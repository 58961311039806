import {useEffect, useMemo, useState} from 'react';
import useMyQuery from '../../api/useMyQuery';

import relationApi from "../../api/requests/relationReqs";
import entityApi from "../../api/requests/entityReqs";

import {Elements, XYPosition, Node, Edge} from "react-flow-renderer";
import {EntityType} from "../../api/types/EntityTypes";
import {RelationType} from "../../api/types/RelationTypes";
import * as localForage from "localforage";

import _initial_position_for_dicastal from "./_initial_position_for_dicastal";

const calculatePosition = (index: number, nodeId: string): XYPosition => {
  const row = Math.floor(index / 8);
  const modIndex = index % 8;
  const position = {
    x: modIndex * 340 + 50,
    y: row * 500 + 50,
  };
  return position;
}

/**
 * hook to get react-flow's elements.
 * see https://reactflow.dev/docs/getting-started/
 * @param namespace the namespace to query
 * @returns isLoading, elements, setElements, within an object.
 */
const useQueryElements = (namespace: string) => {

  const [elements, setElements] = useState<Elements>([]);
  const [positions, setPositions] = useState<Record<string, XYPosition>>({})

  const { data: entitiesResponse, isLoading: isEntityLoading } = useMyQuery(['entities', namespace], entityApi.list, {
    refetchOnWindowFocus: false,
    // see: https://react-query.tanstack.com/guides/dependent-queries
    enabled: !!namespace
  });

  const entities = entitiesResponse?.data;

  const { data: relationsResponse, isLoading: isRelationLoading } = useMyQuery(['relations', namespace], relationApi.list, {
    refetchOnWindowFocus: false,
    // see: https://react-query.tanstack.com/guides/dependent-queries
    enabled: !!namespace
  });

  const relations = relationsResponse?.data;

  useEffect(() => {
    // todo get from backend

    // use IIFE to invoke localForage.getItem synchronously.
    (async function () {
      const positionGuide = await localForage.getItem<Record<string, XYPosition>>('positionGuide@' + namespace);
      if (positionGuide && typeof positionGuide === 'object') {
        setPositions(positionGuide);
      } else {
        setPositions(_initial_position_for_dicastal);
      }

      // console.log(positions);
    })();
  }, [namespace])

  useEffect(() => {
    if (!Array.isArray(entities)) return;
    const nodes: Node<EntityType>[] = entities.map((item, index) => {
      const {name} = item;
      // if (positions[name]) {
      //   console.log('cached:' + name);
      // }
      return {
        id: name, // react-flow-node use `entity_name` as id.
        type: 'entity', // custom react-flow-node type
        position: positions[name] || calculatePosition(index, name),
        data: item,
      };
    });


    if (!Array.isArray(relations)) {
      setElements(nodes);
    } else {
      // console.log(relations);
      const edges: Edge<RelationType>[] = relations
        .flatMap((rel) => {
          // 一个双向relation可以产生两条线
          const {
            relation_direction,
            object_type_name1: name1,
            object_type_name2: name2,
            attribute_name1: attr1,
            attribute_name2: attr2,
            relation_multiplicity // todo for further use
          } = rel;

          if (relation_direction === 'BI_DIRECTION') {
            return [
              {
                id: `${name1}.${attr1} --> ${name2}`,
                source: name1,
                sourceHandle: `source@${name1}.${attr1}`,
                target: name2,
                targetHandle: `target@${name2}`
              },
              {
                id: `${name2}.${attr2} --> ${name1}`,
                source: name2,
                sourceHandle: `source@${name2}.${attr2}`,
                target: name1,
                targetHandle: `target@${name1}`
              }
            ]
          } else if (relation_direction === 'LEFT2RIGHT') {
            return [
              {
                id: `${name1}.${attr1} --> ${name2}`,
                source: name1,
                sourceHandle: `source@${name1}.${attr1}`,
                target: name2,
                targetHandle: `target@${name2}`
              }
            ]
          } else if (relation_direction === 'RIGHT2LEFT') {
            return [
              {
                id: `${name2}.${attr2} --> ${name1}`,
                source: name2,
                sourceHandle: `source@${name2}.${attr2}`,
                target: name1,
                targetHandle: `target@${name1}`
              }
            ]
          } else {
            throw new Error(`for dev: unknown relation_direction [${relation_direction}]`);
          }
        });

      // console.log(nodes, edges);
      setElements([...nodes, ...edges]);
    }

  }, [entities, positions, relations]);

  return {
    isLoading: isEntityLoading || isRelationLoading,
    elements,
    setElements
  };
};

export default useQueryElements;
