import {Day4CustomFC} from "../../types";
import {Button, message, Modal} from "antd";

import {ExclamationCircleOutlined} from '@ant-design/icons'
import request from "../../sdk/request";

interface DeleteButtonPropType {
  url: string,
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
  message: string,
  successMessage: string,
  buttonText?: string,
  requestBody?: any,
  disabled: boolean,
  onOk: () => void
}

const DeleteButton: Day4CustomFC<DeleteButtonPropType> = ({
  url,
  method = 'DELETE',
  message: messageProp,
  successMessage,
  buttonText = '删除',
  requestBody,
  disabled,
  onOk
}) => {

  const {confirm} = Modal;

  const handleClick = () => {
    confirm({
      title: messageProp,
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      onOk () {
        request({
          url: url,
          method: method,
          data: requestBody
        }).then(() => {
          message.success(successMessage)
          // 调用Day4 event
          onOk && onOk();
        }).catch(err => {
          // no-op
        })
      }
    })
  }

  return (
    <Button type={"primary"} danger
            disabled={disabled}
            onClick={handleClick}
    >{buttonText}</Button>
  )
}

export default DeleteButton;
