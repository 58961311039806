import CustomText from './CustomText';

CustomText.register = {
  attributes: [
    {
      name: 'text',
      type: 'template',
    },
    {
      name: 'fontSize',
      type: 'string',
    },
  ],
  styles: [
    'width',
    'border',
    'borderRadius',
    'backgroundColor',
    'textColor',
    'lineHeight',
    'verticalAlign',
    'fontFamily',
    'fontStyle',
    'fontWeight',
    'textAlignment',
    'cursor',
    'whitespace',
    'wordBreak',
  ],
  editorConfig: {
    defaultValue: {
      text: 'Text',
    },
  },
  events: [
    {
      type: 'click',
    },
  ],
};

export default CustomText;
