import {Edge, Elements} from "react-flow-renderer";
import {useEffect, useState} from "react";

import useMyQuery from "../../../api/useMyQuery";
import entityApi from "../../../api/requests/entityReqs";
import relationApi from "../../../api/requests/relationReqs";

import {ViewMode} from "../types";
import useSavedPositions from "./useSavedPositions";
import classicElementsGenerator from "../elementsGenerators/classic";
import cleanerRelationElementsGenerator
  from "../elementsGenerators/cleanerRelation";

/**
 * hook to get react-flow's elements.
 * see https://reactflow.dev/docs/getting-started/
 * @param namespace the namespace to query
 * @param viewMode the viewMode of the diagram to toggle
 * @param search search filter
 * @param focusedEdge current hovered edge
 * @returns isLoading, elements, setElements, within an object.
 */
const useQueryElements = (namespace: string, viewMode: ViewMode, search: string, focusedEdge: Edge | null) => {

  const [elements, setElements] = useState<Elements>([]);

  const { positions, size } = useSavedPositions(namespace);

  // ----------------------------------------------------------
  // fetch entity and relation data
  // ----------------------------------------------------------

  const {
    data: entitiesResponse,
    isLoading: isEntityLoading,
    refetch: refetchEntities
  } = useMyQuery(['entities', namespace], entityApi.list, {
    refetchOnWindowFocus: false,
    // see: https://react-query.tanstack.com/guides/dependent-queries
    enabled: !!namespace
  });

  const entities = entitiesResponse?.data;

  const {
    data: relationsResponse,
    isLoading: isRelationLoading,
    refetch: refetchRelations
  } = useMyQuery(['relations', namespace], relationApi.list, {
    refetchOnWindowFocus: false,
    // see: https://react-query.tanstack.com/guides/dependent-queries
    enabled: !!namespace
  });

  const relations = relationsResponse?.data;

  const isLoading = isEntityLoading || isRelationLoading;

  // -----------------------------------------------------------
  // generating diagram elements (according to viewMode!)
  // -----------------------------------------------------------

  useEffect(() => {
    if (!isLoading) { // important: Loading causes incomplete result.
      if (viewMode === 'expanded-cross') {
        setElements(classicElementsGenerator(entities, relations, positions));
      } else if (viewMode === 'expanded' || viewMode === 'collapsed') {
        setElements(cleanerRelationElementsGenerator(entities, relations, positions, {
          viewMode,
          search,
          focusedEdge
        }))
      } else {
        throw new Error(`for dev: ${viewMode} is not supported`);
      }
    }
  }, [isLoading, viewMode, search, focusedEdge, positions, entities, relations]);

  return {
    isLoading,
    elements,
    setElements,
    refetchEntities,
    refetchRelations
  };
};

export default useQueryElements;
