import entityApi from '../api/requests/entityReqs';
import {getByPath} from "../sdk/utils";
import relationApi from "../api/requests/relationReqs";
import {RelationType} from "../api/types/RelationTypes";
import {EntityType} from "../api/types/EntityTypes";
import {EntityDataTypes, DataColumn, Schema} from "../api/types/EntityDataTypes";

export async function entityList ({dependencies}: any) {
  const namespace = getByPath(dependencies, [0, 1]);
  if (!namespace) {
    return null;
  }
  const entityResponse = await entityApi.list(namespace);
  const result = entityResponse?.data || []
  return result.sort(nameComparator);
}

export async function entityListWithRelations ({dependencies}: any) {
  const namespace = getByPath(dependencies, [0, 1]);
  if (!namespace) {
    return null;
  }
  const entityReponse = await entityApi.list(namespace);
  const entityList = entityReponse?.data || [];

  const relationResponse = await relationApi.list(namespace);
  const relationList = relationResponse?.data || [];

  // attach relation on entity, using a dict as interchange
  const cache = relationList.reduce((res: Record<string, RelationType[]>, item) => {
    const {
      object_type_name1: name1,
      object_type_name2: name2
    } = item;
    res[name1] = [...(res[name1] || []), item]
    // fixme self relation should count once.
    if (name1 !== name2) {
      res[name2] = [...(res[name2] || []), item]
    }
    return res;
  }, {})

  const resultList = entityList.map(entity => {
    const {name} = entity;
    return ({
      ...entity,
      relations: cache[name] || []
    })
  })

  return resultList.sort(nameComparator);
}

export async function entityDetail ({dependencies}: any) {
  const namespace = getByPath(dependencies, [0, 1]);
  const entity = getByPath(dependencies, [1, 1]);

  if (!namespace || !entity) {
    return null;
  }

  const entityResponse = await entityApi.detail(namespace, entity);

  return entityResponse?.data;
}


export async function dataOfEntity({dependencies}:any) {

  const namespaceName = getByPath(dependencies, [0, 1])
  const entityName = getByPath(dependencies, [1,1])
  if (!namespaceName || !entityName) {
    return null
  }

  const entityResponse = await entityApi.detail(namespaceName, entityName);

  if (!entityResponse || !entityResponse.data) {
    return null
  }

  const entityInfo = entityResponse.data
  const dataResponse = await entityApi.fetchEntityData(entityInfo)

  // 将数据进行转换为 table视图渲染的数据结构
  if (!dataResponse || !dataResponse.data) {
    return null
  }
  const  entityDataTypes  = dataResponse.data
  /**
   * columns = [
     {
        title: id
        dataindex: id
        key:id
     },
     {
      title: name
      dataindex: name
      key:name
      }
   ]
   */


  const schema = entityDataTypes.schema;
  const columns = new Array<DataColumn>()

  const attrs =  entityInfo.attributes
  if (!attrs || attrs.length == 0) {
    return null
  }

  for (let sha of schema) {
    let column = {key: '', dataIndex: '', title: ''}
    for (let attr of attrs) {
      if (attr.name == sha.name) {
        column.key = attr.name
        column.dataIndex = attr.name
        column.title = attr.display? attr.display : attr.name
        break
      }
    }
    columns.push(column)
  }

  console.log(columns)

  /**
   data = [
     {
      key:0
      id: xxx
      name: 产线1
     },
     {
        key:1
        id: yyyy
        name: 产线2
     }

   ]
   */

  const rows = dataResponse.data.rows // [['ALypKi2nR', '产线1'], ['bcxfKi2n3', '产线2']]
  console.log(rows)
  const source = []
  for (let ri in rows) {
    let cell = new Map()
    cell.set('key', ri)
    for (let ci in columns) { // ['ALypKi2nR', '产线1']
      cell.set(columns[ci].key, rows[ri][ci])
    }
    // 把Map 转换为 对象
    source.push(Object.fromEntries(cell.entries()))

  }

  console.log(source)

  return {'columns': columns, 'dataSource': source}

}

// ---------------------------------------------------
function nameComparator (a: EntityType, b: EntityType) {
  const aa = a.name;
  const bb = b.name;
  if (aa < bb) {
    return -1;
  } else if (aa > bb) {
    return 1
  } else {
    return 0
  }
}
