/** @jsxImportSource @emotion/react */
import Icon from '@ant-design/icons';
import { Input, Button } from 'antd';
import useStyles from 'day4-components/es/hooks/useStyles';
import { useEffect, useState } from 'react';
import { Day4CustomFC } from '../../types';
import { ReactComponent as SearchIcon } from './search.svg';

export interface InputProps {
  styles?: any;
  value?: string;
  onChange?: (value: any) => void;
}

const SearchInput: Day4CustomFC<InputProps> = ({ onChange, value, styles }) => {
  const componentCss = useStyles([], styles);
  const [val, setVal] = useState<string | undefined>(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setVal(e.target.value);
  };

  const handleSearch = () => {
    if (onChange) {
      onChange(val);
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <span style={{ display: 'flex' }}>
      <Input
        css={componentCss}
        value={val}
        onChange={handleChange}
        allowClear
        prefix={<Icon component={SearchIcon} />}
        size="large"
        style={{ borderRadius: '100px' }}
        onKeyDown={handleKeyDown}
        placeholder="请输入关键词"
      />
      <Button
        style={{ marginLeft: 16, width: 90, borderRadius: '100px' }}
        type="primary"
        size="large"
        onClick={handleSearch}
      >
        搜索
      </Button>
    </span>
  );
};

export default SearchInput;
