import TextRadio from './TextRadio';

TextRadio.register = {
  attributes: [
    {
      name: 'value',
      type: 'JsonValue',
    },
    {
      name: 'options',
      type: 'json',
    },
  ],
};

export default TextRadio;
