/** @jsxImportSource @emotion/react */
import {
  Button, Checkbox, Col, Divider, Input,
  message,
  Modal, Radio, Row,
  Select, Space
} from "antd";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import relationApi from "../../api/requests/relationReqs";
import { ObjectType } from "../../api/types/CommonTypes";
import {
  RelationBasicSetting,
  RelationMultiplicityType,
  RelationTypeType
} from "../../api/types/RelationTypes";
import { Day4CustomFC } from "../../types";
import useNamespaceObjectQuery from "./hooks/useNamespaceObjectQuery";
import useRelationBasicSettingValidation from "./hooks/useRelationBasicSettingValidation";
import { ModalUpsertRelationAttributes } from "./index";
import './modalAddRelationStyles.less';


interface ModalAddRelationshipPropType {
  namespace: string,
  // 左边的那个对象类型（用于初始化）
  type1: string,
  // 右边的那个对象类型（可能会有）
  type2: string,
  showButton: boolean,
  disabled?: boolean,
  onOk: () => void
}

interface RelationBrief {
  type1: ObjectType,
  type_name1: string,
  type2: ObjectType,
  type_name2: string,
  multiplicity: RelationMultiplicityType
}

const { Option, OptGroup } = Select;

const ModalAddRelationship: Day4CustomFC<ModalAddRelationshipPropType> = ({
  namespace,
  type1,
  type2,
  showButton,
  disabled = false,
  onOk
}) => {
  const [modalVisible, setModalVisible] = useState(!showButton);
  const [isRelationAttributeUpsertionModalShown, setRelationAttributeUpsertionModalShown] = useState<boolean>(false);
  const [relationToUpsertAttributes, setRelationToUpsertAttributes] = useState<string | null>(null);

  // 上方的控件，提示左右类（1、2）以及数量关系
  const [relationBrief, setRelationBrief] = useState<RelationBrief>({
    type1: 'ENTITY_TYPE',
    type_name1: type1,
    type2: 'ENTITY_TYPE',
    type_name2: type2,
    multiplicity: 'ONE2MANY'
  });

  // 关系的名称
  const [relationName, setRelationName] = useState<string>('');
  // 关系的类型(默认是WEAK)
  const [relationType, setRelationType] = useState<RelationTypeType>('RESTRICT');
  // 对象1->对象2的访问属性(会初始化成对象2的类名)
  const [accAttr1, setAccAttr1] = useState<string>('');
  // 对象2->对象1的访问属性(会初始化成对象1的类名)
  const [accAttr2, setAccAttr2] = useState<string>('');

  const [coverTypeName1, setCoverTypeName1] = useState(false);
  const [isShowCoverOption1, setIsShowCoverOption1] = useState(false);

  const [coverTypeName2, setCoverTypeName2] = useState(false);
  const [isShowCoverOption2, setIsShowCoverOption2] = useState(false);

  // 命名空间中的所有对象的列表
  const [objectsInGroup] = useNamespaceObjectQuery(namespace);

  // 计算出来的数据 ------------------------------------------------

  // 关系本身的设置(用于提交)
  const relationBasicSetting: RelationBasicSetting = {
    namespace: namespace,
    name: relationName,

    type1: relationBrief.type1, // fixme add more type
    object_type_name1: relationBrief.type_name1,
    attribute_name1: accAttr1,

    type2: relationBrief.type2, // fixme add more type
    object_type_name2: relationBrief.type_name2,
    attribute_name2: accAttr2,

    relation_direction: "BI_DIRECTION", // fixme implement this to have more direction
    relation_multiplicity: relationBrief.multiplicity,
    relation_type: relationType
  }

  const validated = useRelationBasicSettingValidation(relationBasicSetting, {});

  interface SelectionOption<L, V> {
    label: L,
    value: V,
    enable: boolean
  }

  const relationTypeOptions: SelectionOption<string, RelationTypeType>[] = [
    {
      label: `当删除${relationBrief.type_name1 || '...'}的对象时，不影响${relationBrief.type_name2 || '...'}的对象`,
      value: 'VIRTUAL',
      enable: relationBrief.type1 === "EXTERNAL_ENTITY_TYPE" || relationBrief.type2 === "EXTERNAL_ENTITY_TYPE",
    },
    {
      label: `当删除${relationBrief.type_name1 || '...'}的对象时，将${relationBrief.type_name2 || '...'}对象的引用置空`,
      value: 'SET_NULL',
      enable: relationBrief.type1 === "ENTITY_TYPE" && relationBrief.type2 === "ENTITY_TYPE"
    },
    {
      label: `当被${relationBrief.type_name2 || '...'}引用时，不允许删除${relationBrief.type_name1 || '...'}的对象`,
      value: 'RESTRICT',
      enable: relationBrief.type1 === "ENTITY_TYPE" && relationBrief.type2 === "ENTITY_TYPE"
    },
    {
      label: `当删除${relationBrief.type_name1 || '...'}的对象时，同时删除${relationBrief.type_name2 || '...'}对象`,
      value: 'CASCADE',
      enable: relationBrief.type1 === "ENTITY_TYPE" && relationBrief.type2 === "ENTITY_TYPE"
    }
  ]

  const mutationCreateRelation =
    useMutation((basicSetting: RelationBasicSetting) => relationApi.create(basicSetting));

  // 数据初始化与联动 -----------------------------------------------------

  useEffect(() => {
    setRelationName(`rel_${relationBrief.type_name1}__${relationBrief.type_name2}`);
    setAccAttr1(relationBrief.type_name2);
    setAccAttr2(relationBrief.type_name1);
  }, [setRelationName, relationBrief])

  // 回调区 ---------------------------------------------------------
  const handleSelectObject1 = (value: string) => {
    const [type, type_name] = value.split('#');
    if (type === 'ENTITY_TYPE') {
      setIsShowCoverOption1(false);
    } else if (type === 'EXTERNAL_ENTITY_TYPE') {
      setIsShowCoverOption1(false);
      setRelationType("VIRTUAL")
    }
    // 设置select
    setRelationBrief({
      ...relationBrief,
      type1: type as ObjectType,
      type_name1: type_name
    });
  }

  const handleSelectObject2 = (value: string) => {
    const [type, type_name] = value.split('#');
    if (type === 'ENTITY_TYPE') {
      setIsShowCoverOption2(false);
    } else if (type === 'EXTERNAL_ENTITY_TYPE') {
      setIsShowCoverOption2(false);
      setRelationType("VIRTUAL")
    }
    setRelationBrief({
      ...relationBrief,
      type2: type as ObjectType,
      type_name2: type_name
    });
  }

  const handleSelectRelationMx = (value: RelationMultiplicityType) => {
    setRelationBrief({
      ...relationBrief,
      multiplicity: value
    });
    if (value === 'MANY2MANY') {
      // 多对多没有强关系，所以手动调整过来
      // setRelationType('VIRTUAL');
    }
  }

  const handleSwitchBetweenObject1AndObject2 = () => {
    // 点击“左右对换”按钮时，只将左右颠倒，数量关系不颠倒
    const { type1, type_name1, type2, type_name2, multiplicity } = relationBrief;
    setRelationBrief({
      type1: type2,
      type_name1: type_name2,
      type2: type1,
      type_name2: type_name1,
      multiplicity
    })
  }

  const resetModal = () => {
    setRelationBrief({
      type1: 'ENTITY_TYPE',
      type_name1: type1 || '',
      type2: 'ENTITY_TYPE',
      type_name2: type2 || '',
      multiplicity: 'ONE2MANY'
    });
    setRelationName('');
    setRelationType('RESTRICT');
    setAccAttr1('');
    setAccAttr2('');
    setCoverTypeName1(false);
    setCoverTypeName2(false);
    setIsShowCoverOption1(false);
    setIsShowCoverOption2(false);
  }

  const handleOk = async () => {

    // for dev use
    // console.log('relation info -> ', relationBasicSetting);
    // return 'dicastal_sophia_HntNIakobF_equipment_class_equipment_specification';

    try {
      if (!validated) {
        return;
      }
      const basicSetting = { ...relationBasicSetting };
      if (coverTypeName1) {
        basicSetting.rewrite_on_attribute_name1_conflict = coverTypeName1
      }
      if (coverTypeName2) {
        basicSetting.rewrite_on_attribute_name2_conflict = coverTypeName2
      }
      const result = await mutationCreateRelation.mutateAsync(basicSetting);

      if (result?.data) {
        message.success('创建成功！');

        resetModal();
        setModalVisible(false);
        onOk && onOk();
        return result.data.name;
      }
    } catch (e) {
      // no-op
    }
  }

  const handleGoOnUpsertingAttributes = async () => {
    const relation = await handleOk();

    console.log('relation to upsert attrs: ', relation);

    if (relation) {
      setRelationToUpsertAttributes(relation);
      setRelationAttributeUpsertionModalShown(true);
    }
  }

  const handleCancel = () => {
    resetModal();
    setModalVisible(false);
  }

  const handleUpsertClose = () => {
    setRelationAttributeUpsertionModalShown(false);
    onOk && onOk();
  }

  // 是否覆盖第一个访问属性名
  const handleTypeName1 = (e: any) => {
    setCoverTypeName1(e.target.checked);
  }

  // 是否覆盖第二个访问属性名
  const handleTypeName2 = (e: any) => {
    setCoverTypeName2(e.target.checked);
  }

  // 组件片段 ---------------------------------------------------

  const footer = (
    <>
      <Button key="ok" type="primary" onClick={handleOk}
        disabled={!validated}>创建关系</Button>
      <Button key="goOn" type="primary" onClick={handleGoOnUpsertingAttributes}
        disabled={!validated}>...并编辑属性</Button>
      <Button key="cancel" onClick={handleCancel}>取消</Button>
    </>
  )

  return (
    <>
      {showButton && <Button type="primary" disabled={disabled}
        onClick={() => setModalVisible(true)}>创建关系</Button>}
      <Modal visible={!disabled && modalVisible}
        onOk={handleOk} onCancel={handleCancel}
        closable={false} maskClosable={false}
        title={"创建关系"} footer={footer}
        width={800} className={'relation-creation-modal'}
      >
        <Row className="left-n-right" gutter={4} align={'middle'}>
          <Col span={9}>
            <Select
              placeholder={'请选择对象类型'}
              value={(relationBrief.type_name1 && (relationBrief.type1 + '#' + relationBrief.type_name1)) || undefined}
              onChange={handleSelectObject1}
              dropdownMatchSelectWidth className={'select-block-style'}>
              {objectsInGroup.map(group => (
                <OptGroup label={group.display}>
                  {group.items?.length && group.items.map(item => (
                    <Option value={group.type + '#' + item.name}
                      key={item.name}>
                      <div className={"entity-list-item-name"}>{item.name}</div>
                      <div
                        className={"entity-list-item-desc"}>{item.display}</div>
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Col>
          <Col span={3}>
            <Select
              value={relationBrief.multiplicity}
              onChange={handleSelectRelationMx}
              dropdownMatchSelectWidth className={'select-block-style'}>
              <Option value={'ONE2MANY'}>1:n</Option>
              <Option value={'ONE2ONE'}>1:1</Option>
              <Option value={'MANY2MANY'}>n:n</Option>
              {/*<Option value={'MANY2ONE'}>n:1</Option>*/}
            </Select>
          </Col>
          <Col span={9}>
            <Select
              placeholder={'请选择对象类型'}
              value={(relationBrief.type_name2 && (relationBrief.type2 + '#' + relationBrief.type_name2)) || undefined}
              onChange={handleSelectObject2}
              dropdownMatchSelectWidth className={'select-block-style'}>
              {objectsInGroup.map(group => (
                <OptGroup label={group.display}>
                  {group.items?.length && group.items.map(item => (
                    <Option value={group.type + '#' + item.name}
                      key={item.name}>
                      <div className={"entity-list-item-name"}>{item.name}</div>
                      <div
                        className={"entity-list-item-desc"}>{item.display}</div>
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Col>
          <Col span={3}>
            <Button type={'link'} size={'small'}
              onClick={handleSwitchBetweenObject1AndObject2}>左右对换</Button>
          </Col>
        </Row>
        <Row className="relation-config-content" gutter={24}>
          <Col className="acc-n-attr" span={24}>
            <section className="acc-panel">
              {/*<h3>关系特征</h3>*/}
              <Row style={{ marginBottom: 8 }} gutter={8}>
                <Col className="acc-desc" span={4}>
                  <div className="acc-desc-label">● 关系的名称：</div>
                </Col>
                <Col className="acc-input" span={20}>
                  <Input placeholder={'请输入关系名称'}
                    onChange={event => setRelationName(event.target.value)}
                    value={relationName} />
                </Col>
              </Row>
              <Row style={{ marginBottom: 8 }} gutter={8}>
                <Col className="acc-desc" span={4}>
                  <div className="acc-desc-label">● 关系的类型：</div>
                </Col>
                <Col className="acc-input" span={20}>
                  <Radio.Group
                    value={relationType}
                    onChange={(e) => setRelationType(e.target.value)}>
                    <Space direction={"vertical"}>
                      {relationTypeOptions.map(option => (
                        <Radio value={option.value} disabled={!option.enable} key={option.value}>
                          <div className={"type-option-label"}>{option.label}</div>
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
              <Divider />
              <Row style={{ marginBottom: 8 }} gutter={8}>
                <Col className="acc-desc" span={isShowCoverOption1 ? 12 : 16}>
                  <div className="acc-desc-label">●
                    每一个{relationBrief.type_name1 || '...'}类型的对象，通过如下属性，访问
                    <span>{relationBrief.multiplicity.endsWith('2MANY') ? '多个' : '一个'}</span>
                    {relationBrief.type_name2 || '...'}类型的对象：
                  </div>
                </Col>
                <Col className="acc-input" span={8}>
                  <Input placeholder={'请输入访问属性名'}
                    value={accAttr1}
                    onChange={e => setAccAttr1(e.target.value)}
                  />
                </Col>
                {isShowCoverOption1 && <Col className="acc-input" span={4}>
                  <Checkbox onChange={handleTypeName1}>覆盖已有属性</Checkbox>
                </Col>}
              </Row>
              <Row gutter={8}>
                <Col className="acc-desc" span={isShowCoverOption2 ? 12 : 16}>
                  <div className="acc-desc-label">●
                    每一个{relationBrief.type_name2 || '...'}类型的对象，通过如下属性，访问
                    <span>{relationBrief.multiplicity.startsWith('MANY2') ? '多个' : '一个'}</span>
                    {relationBrief.type_name1 || '...'}类型的对象：
                  </div>
                </Col>
                <Col className="acc-input" span={8}>
                  <Input placeholder={'请输入访问属性名'}
                    value={accAttr2}
                    onChange={e => setAccAttr2(e.target.value)}
                  />
                </Col>
                {isShowCoverOption2 && <Col className="acc-input" span={4}>
                  <Checkbox onChange={handleTypeName2}>覆盖已有属性</Checkbox>
                </Col>}
              </Row>
            </section>
          </Col>
        </Row>
      </Modal>
      {(isRelationAttributeUpsertionModalShown && relationToUpsertAttributes)
        && <ModalUpsertRelationAttributes
          namespace={namespace}
          showButton={false}
          relation={relationToUpsertAttributes}
          onClose={handleUpsertClose}
        />}
    </>
  )
}

export default ModalAddRelationship;
