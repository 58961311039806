import ModalAddEntity from './ModalAddEntity'
import ModalUpsertAttributes from "./ModalUpsertAttributes";

ModalAddEntity.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    }
  ],
  styles: ['width', 'height'],
  events: [
    {
      type: 'ok'
    }
  ]
}

ModalUpsertAttributes.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'entity',
      type: 'template'
    },
    {
      name: 'showButton',
      type: 'boolean'
    },
    {
      name: 'disabled',
      type: 'boolean'
    }
  ],
  events: [
    {
      type: 'close'
    }
  ],
  editorConfig: {
    defaultValue: {
      showButton: false,
      disabled: false
    }
  }
}

export {ModalAddEntity, ModalUpsertAttributes}
