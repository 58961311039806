import {useMemo} from "react";
import checkNameCollision from "../../ModalAddEntityAttributes/validators/checkNameCollision";
import checkEachAttribute from "../../ModalAddEntityAttributes/validators/checkEachAttribute";
import checkIdSetting from "../../ModalAddEntityAttributes/validators/checkIdSetting";
import isItBlankEntity from "../../ModalAddEntityAttributes/validators/isItBlankEntity";
import snakeCaseOrCamelCase from "../../ModalAddEntityAttributes/validators/snakeCaseOrCamelCase";
import {AttributesValidator, AttributeType, Warning, ValidationContextType} from "../types";

/**
 * 输入属性列表，根据一系列判断逻辑，综合得出属性设置是否有
 * 问题，是否可以提交。
 *
 * @param attrList
 */
export default function useAttributesValidation(
  attrList: AttributeType[],
  knowledge: ValidationContextType
): [boolean, Warning[]] {
  // 校验规则集
  const rules = useMemo<AttributesValidator>(() => {
    return grouping('top-level', [
      // isItBlankEntity,
      checkNameCollision,
      checkEachAttribute,
      // checkIdSetting,
      grouping('recommended', [
        snakeCaseOrCamelCase,
        // more to come if needed
      ]),
      grouping('informative', [
        // more to come if needed
      ])
    ]);
  }, []);

  const result = rules.validate(attrList, knowledge);
  // console.log(result);

  return [
    result.validated,
    result.messages
  ]
}

/**
 * 组合子。将多个validator组成一组
 * @param groupName
 * @param validators
 */
function grouping (groupName: string, validators: AttributesValidator[]): AttributesValidator {
  return {
    name: groupName,
    validate(attrList, knowledge) {
      const collected: Warning[] = [];
      let validationResult = true;
      let validator = null;
      try {
        for (let i = 0; i < validators.length; i++) {
          validator = validators[i];
          // console.log('validator: ' + validator.name)
          const {skip, validated, messages} = validator.validate(attrList, knowledge);

          validationResult = validationResult && validated;
          collected.push(...messages);
          if (skip) { break; }
        }
        return {
          skip: false, // grouping always proceeds
          validated: validationResult,
          messages: collected
        }
      } catch (e) {
        // something serious happen. Stop immediately
        console.error(`error found when validating group [${groupName}] at validator [${validator?.name}]`, e);
        return {
          skip: false,
          validated: false,
          messages: collected
        }
      }
    }
  }
}


