/** @jsxImportSource @emotion/react */
import { CopyBlock, dracula } from "react-code-blocks";
import { Day4CustomFC } from "../../types";

interface CopyCodePropType {
  language: string,
  text: string,
}

const CopyCode: Day4CustomFC<CopyCodePropType> = ({
  language,
  text,
}) => {
  return (
    <CopyBlock
      language={language}
      text={text}
      showLineNumbers={false}
      theme={dracula}
      wrapLines={true}
      codeBlock
    />
  )
}

export default CopyCode;
