import React from 'react';
import { Day4CustomFC } from '../../types';
import './Tag.less';

interface Props {
  text?: string;
  className?: string;
}

const Tag: Day4CustomFC<Props> = ({text, className}) => {
  return (
    <div className={`custom-tag ${className || ''}`}>{text}</div>
  )
}

export default Tag;