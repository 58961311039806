import Affix from './Affix';
import ChildrenRenderer from 'day4-modules/es/blocks/core/ChildrenRenderers';

Affix.register = {
  attributes: [
    {
      name: 'offsetBottom',
      type: 'number',
    },
    {
      name: 'offsetTop',
      type: 'number',
    },
  ],
  isExpectChildren: true,
  ChildrenRenderer,
};

export default Affix;
