import JsonDataImporterV2 from './JsonDataImporterV2'
import JsonDataImporterV3 from './JsonDataImporterV3'
import JsonDataExporterV3 from "./JsonDataExporterV3";
import ExcelTemplateExporterV3 from "./ExcelTemplateExporterV3";
import JsonDataImporterExcel from './JsonDataImporterExcel';

import YAMLMappingExporter from './YAMLMappingExporter'
import YAMLMappingImporter from './YAMLMappingImporter'

JsonDataImporterV2.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    }
  ],
  styles: [
    'width',
    'height'
  ]
}

JsonDataImporterV3.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    }
  ],
  styles: [
    'width',
    'height'
  ]
}

JsonDataImporterExcel.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    }
  ],
  styles: [
    'width',
    'height'
  ]
}

JsonDataExporterV3.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'text',
      type: 'string'
    }
  ],
  editorConfig: {
    defaultValue: {
      text: '点击导出V3元数据'
    }
  }
}

ExcelTemplateExporterV3.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'text',
      type: 'string'
    }
  ],
  editorConfig: {
    defaultValue: {
      text: '点击导出Excel模版'
    }
  }
}

YAMLMappingExporter.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'text',
      type: 'string'
    }
  ],
  editorConfig: {
    defaultValue: {
      text: '点击导出mapping'
    }
  }
}

YAMLMappingImporter.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'text',
      type: 'string'
    }
  ],
  editorConfig: {
    defaultValue: {
      text: '点击导入mapping'
    }
  }
}

export {
  JsonDataImporterV2,
  JsonDataImporterV3,
  JsonDataExporterV3,
  JsonDataImporterExcel,
  ExcelTemplateExporterV3,

  YAMLMappingExporter,
  YAMLMappingImporter,
}
