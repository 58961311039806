import { Table } from "antd";
import {
  DataFrameDataType,
  DataFrameSchema
} from "../../../api/types/DataServiceTypes";
import { Day4CustomFC } from "../../../types";

interface DataFrameViewerPropType {
  dataFrame: DataFrameDataType | null
}

const DataFrameViewer: Day4CustomFC<DataFrameViewerPropType> = ({
  dataFrame
}) => {
  if (dataFrame === null) {
    return (
      <code>未执行查询...</code>
    )
  }

  const { schema, rows } = dataFrame;

  const columns = schema.map((s: DataFrameSchema) => {
    if (typeof s === 'string') {
      return {
        title: s,
        dataIndex: s,
        key: s
      }
    } else {
      const { name } = s;
      return {
        title: name,
        dataIndex: name,
        key: name
      }
    }
  });

  const dataSource = rows.map((row: any[]) => {
    const result: any = {};
    for (let i = 0; i < row.length; i++) {
      const col = columns[i];
      const key = col.key;
      const value = row[i];
      result[key] = value;
    }
    return result;
  });

  return (
    <div style={{ width: '100%' }}>
      <Table size={"small"} columns={columns} dataSource={dataSource} scroll={{ x: '100%' }} />
    </div>
  )
}

export default DataFrameViewer;
