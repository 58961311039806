import GraphiQL from './GraphiQL';

GraphiQL.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'template'
    },
    {
      name: 'defaultQuery',
      type: 'text',
    },
    {
      name: 'defaultVariables',
      type: 'text',
    },
    {
      name: 'templateName',
      type: 'text',
    },
    {
      name: 'templateDesc',
      type: 'text',
    },
    {
      name: 'showGraphqlExample',
      type: 'boolean',
    },
    {
      name: 'graphqlExampleButtonLabel',
      type: 'string',
    },
    {
      name: 'graphqlExampleShellCode',
      type: 'string',
    },
    // {
    //   name: 'showDataframeExample',
    //   type: 'boolean',
    // },
    // {
    //   name: 'dataframeExampleButtonLabel',
    //   type: 'string',
    // },
    // {
    //   name: 'dataframeExampleShellCode',
    //   type: 'string',
    // }
  ],
  styles: ['width', 'height'],
};

export default GraphiQL;
