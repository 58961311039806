/** @jsxImportSource @emotion/react */
import useStyles from 'day4-components/es/hooks/useStyles';
import { Day4CustomFC } from '../../types';

interface Props {
  width: number;
  maxWidth: number;
  minWidth: number;
  styles: any;
}

const CustomWidthContainer: Day4CustomFC<Props> = (props) => {
  const { width, children, maxWidth, minWidth = 200, styles } = props;
  const componentCss = useStyles([], styles);
  return (
    <div
      css={componentCss}
      style={{ width, minHeight: 20, maxWidth, minWidth }}
    >
      {children}
    </div>
  );
};

export default CustomWidthContainer;
