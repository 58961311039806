/** @jsxImportSource @emotion/react */
import styles from "day4-components/es/styles";
import {ItemInterface, ReactSortable} from "react-sortablejs";
import {EntityAttributeType} from "../../../api/types/EntityTypes";
import {ReactNode, useState} from "react";
import useDebounce from "react-use/esm/useDebounce";
import {AttributeType} from "../types";
import TickReadOnly from "../components/TickReadOnly";

type useSortableAttributeEntriesType = (
  attrList: AttributeType[],
  setAttrList: (attrList: AttributeType[]) => void
) => [
  ReactNode,
  AttributeType | null,
  (attrEditing: AttributeType | null) => void,
  boolean
]

/**
 * 输入属性列表，输出属性列表渲染之后的结果
 * @param attrList
 * @param setAttrList
 */
const useSortableAttributeEntries: useSortableAttributeEntriesType = (
  attrList,
  setAttrList
) => {

  const [isDragging, setDragging] = useState(false);
  const [attrEditing, setAttrEditing] = useState<(EntityAttributeType & ItemInterface) | null>(null);

  // optimization: sync attrList according to attrEditing change, debounced.
  useDebounce(() => {
    if (!attrEditing) {
      return
    }
    const {id} = attrEditing;
    setAttrList(attrList.map(attr => {
      return attr.id === id ? attrEditing : attr
    }))
  }, 200, [
    // note: you cannot add `attrList` to deps, because that will cause loop.
    attrEditing
  ]);

  const attributeEntries = (
    <ReactSortable
      className={'left-pane-table-body'}
      list={attrList}
      setList={setAttrList}
      handle={".drag-handle"}
      animation={150}
      onStart={() => setDragging(true)}
      onEnd={() => setDragging(false)}
    >
      {// 左侧列表项
        attrList.map(item => {
          const {
            id, name, display, type, nullable, unique, is_primary
          } = item;
          return (
            <div key={id} className={"attribute-entry"} id={`attribute-entry-${id}`}
                 onClick={() => setAttrEditing(item)}
                 style={{
                   backgroundColor: id === attrEditing?.id
                     ? '#cde6ff' : (isDragging ? '#ffffff' : undefined)
                 }}>
              <span className="drag-handle">:::</span>
              <span className="attribute-entry-data" css={styles.width('7rem')}>
                <span className="label">{name}</span>
              </span>
              <span className="attribute-entry-data" css={styles.width('6rem')}>
                <span className="label">{display}</span>
              </span>
              <span className="attribute-entry-data" css={styles.width('6.5rem')}>{type}</span>
              <span className="attribute-entry-data" css={styles.width('4rem')}>{"***"}</span>
              <span className="attribute-entry-data" css={styles.width('3rem')}>
                <TickReadOnly checked={!nullable}/>
              </span>
              <span className="attribute-entry-data" css={styles.width('3rem')}>
                <TickReadOnly checked={!!is_primary}/>
              </span>
              <span className="attribute-entry-data" css={styles.width('3rem')}>
                <TickReadOnly checked={!!unique}/>
              </span>
              <span className="delete-button" css={[styles.marginLeft('auto'), styles.paddingX('4px')]}
                    onClick={(e) => {
                      // outer div also listens.
                      e.stopPropagation();
                      setAttrList(attrList.filter(attr => attr.id !== id));
                      setAttrEditing(null);
                    }}
              >&times;</span>
            </div>
          )
        })}
    </ReactSortable>
  )

  return [attributeEntries, attrEditing, setAttrEditing, isDragging];
}

export default useSortableAttributeEntries;
