import CopyCode from './CopyCode';

CopyCode.register = {
  attributes: [
    { name: 'language', type: 'string' },
    { name: 'text', type: 'string' },
  ],
  editorConfig: {
    defaultValue: {
      language: 'shell',
      text: 'curl',
    },
  },
};

export default CopyCode;
