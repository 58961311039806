import {
  AttributesValidator,
  AttributeType,
  Warning
} from "../types";

const checkNameCollision: AttributesValidator = {
  name: 'checkNameCollision',
  desc: '检查属性的命名有没有冲突',
  validate (attrList, knowledge) {
    const nameCount = attrList.reduce(
      (counting: Record<string, AttributeType[]>, item) => {
        const {name} = item;
        counting[name] = [...(counting[name] || []), item];
        return counting;
      }, {});
    let validated = true;
    const messages: Warning[] = [];
    // summarize
    Object.keys(nameCount).forEach(n => {
      if (nameCount[n].length > 1) {
        validated = false;
        messages.push({
          type: 'error',
          message: '属性名重名: ' + n,
          referring: nameCount[n]
        })
      }
    });

    return {
      skip: false,
      validated,
      messages
    }
  }
}

export default checkNameCollision;
