export default {
  "equipment_specification": {
    "x": 2703,
    "y": 203
  },
  "data_xray_result": {
    "x": -1990,
    "y": 443
  },
  "equipment_actual": {
    "x": -1223,
    "y": -305
  },
  "material_lot": {
    "x": -634,
    "y": 446
  },
  "QA_test_property": {
    "x": 807,
    "y": 1958
  },
  "material_sublot": {
    "x": -1238,
    "y": 454.99999999999994
  },
  "product_segment": {
    "x": 2039,
    "y": 1166
  },
  "process_segment": {
    "x": 1847,
    "y": 203
  },
  "material_produced_actual": {
    "x": 276,
    "y": 16
  },
  "equipment_class": {
    "x": 2076,
    "y": -567
  },
  "product_segment_dependency": {
    "x": 1607,
    "y": 2005
  },
  "material_class": {
    "x": -1089,
    "y": 1232
  },
  "QA_test_specification": {
    "x": 111,
    "y": 1939.0000000000002
  },
  "equipment_segment_specification": {
    "x": 1175,
    "y": -335
  },
  "QA_test_dependency": {
    "x": -667,
    "y": 1915.9999999999998
  },
  "material_definition": {
    "x": -178,
    "y": 1008
  },
  "material_specification": {
    "x": 739,
    "y": 793.0000000000001
  },
  "segment_response": {
    "x": -304,
    "y": -547
  },
  "equipment_measurement": {
    "x": -788,
    "y": -1238
  },
  "equipment_class_measurement": {
    "x": 2508,
    "y": -1235
  },
  "equipment": {
    "x": 161.99999999999994,
    "y": -1255
  }
}
