import Icon from '@ant-design/icons';
import { Checkbox, Popover } from 'antd';
import React, { useMemo } from 'react';
import './PopGroup.less';
import * as R from 'ramda';

export interface Option {
  id: number;
  label: string;
  group: string;
  cases?: any[];
}

interface Props {
  title: string;
  icon: React.ComponentType;
  style?: React.CSSProperties;
  options?: Option[];
  value?: any[];
}

const PopGroup: React.FC<Props> = (props) => {
  const { title, icon, style, options, value } = props;

  const groupedOptions = useMemo(() => {
    if (options) {
      return R.groupBy<Option>((x) => x.group)(options);
    }
    return {};
  }, [options]);

  return (
    <Popover
      placement="bottom"
      overlayInnerStyle={{
        borderRadius: '20px',
        marginLeft: 24,
        marginRight: 24,
      }}
      content={
        <div className="technology-content">
          {Object.keys(groupedOptions).map((key) => (
            <div className="technology-group" key={key}>
              <div className="technology-group-name">{key}</div>
              <div className="technology-group-content">
                {groupedOptions[key].map((x) => (
                  <div className="technology-group-item" key={x.id}>
                    <Checkbox value={x.id}>
                      {x.label}({x.cases?.length})
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      }
    >
      <span
        style={style}
        className={`technology-title ${
          value?.length ? 'technology-selected' : ''
        }`}
      >
        <Icon component={icon} className="technology-icon" />
        <span className="technology-text">{title}</span>
      </span>
    </Popover>
  );
};

export default PopGroup;
