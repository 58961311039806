/** @jsxImportSource @emotion/react */
import { Cell, EdgeView, Graph } from '@antv/x6';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import '@antv/x6-react-shape';
import { css } from '@emotion/react';
import './ReactComponent';
import '@antv/x6-react-components/es/menu/style/index.css';
import useQueryElements from './hooks/useQueryElements';
import useMyQuery from '../../api/useMyQuery';
import namespaceApi from '../../api/requests/namespaceReqs';
import { PositionGuide, SizeGuide } from '../EntityRelationDiagram/types';
import useDebounce from "react-use/esm/useDebounce";
import ResizeDetector from 'react-resize-detector';
import { useDrop } from 'react-dnd';
import entityApi from '../../api/requests/entityReqs';
import { EntityType } from '../../api/types/EntityTypes';
import { useMutation } from 'react-query';
import cuid from 'cuid';
import { GraphContainer, SelectedCell } from '../EntityRelation';

const X6Diagram = ({ namespace = ''}) => {
  const graphRef = useRef(null);
  const { entities, relations, positions: initPosition, size: initSize } = useQueryElements(namespace);
  const minimapContainer = useRef(null);

  const [positions, setPositions] = useState<PositionGuide>()

  const [nodeSize, setNodeSize] = useState<SizeGuide>();

  const [graph, setGraph] = useState<Graph>();

  const {cell, setCell} = useContext(SelectedCell);

  const {globalGraph, setGlobalGraph} = useContext(GraphContainer);

  const mutationCreateEntity =
    useMutation((newEntity: EntityType) => entityApi.create(newEntity));

  const handleAddNode = async (namespace: any, id: string, positionX: number, positionY: number) => {
    const randomName= id;
    const result = await mutationCreateEntity.mutateAsync({
      namespace,
      attributes: [],
      is_external: false,
      name: randomName,
      display: randomName,
    });
    if(result?.data) {
      const newNode = result?.data;
      let { x = positionX, y = positionY } = initPosition[newNode.name!] || {};
      let { width = 250, height = 250 } = initSize[newNode.name!] || {};
      graph?.addNode({
        id: `${newNode.name}`, // String，可选，节点的唯一标识
        x, // Number，必选，节点位置的 x 值
        y, // Number，必选，节点位置的 y 值
        width, // Number，可选，节点大小的 width 值
        height, // Number，可选，节点大小的 height 值
        shape: 'react-shape',
        component(node: any) {
          return (
            <div
              css={css`
            border: 1px solid #333;
            text-align: center;
            height: 100%;
            width: 100%;
            background-color: #ebe8e1;
            display: flex;
            flex-direction: column;
          }
          `}
            >
              <p
                css={css`
                  border-bottom: 1px solid #333;
                  margin: 0;
                `}
              >
                {newNode.display + ''}
              </p>
              <div
                css={css`
                  overflow: auto;
                  height: 100%;
                  width: 100%;
                `}
              >
                {newNode?.attributes?.map((i: any) => {
                  return (
                    <div
                      css={css`
                        display: flex;
                        justify-content: space-between;
                        padding: 0 5px;
                        height: 20px;
                        line-height: 20px;
                      `}
                    >
                      <p>{i.name}</p>
                      <p>{i.type}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        },
      });
    } else {
      //msg error
    } 
  };


  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: 'BOX',
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop: (item: any, monitor) => {
      const id = item.id || cuid();
      const currentMouseOffset = monitor.getClientOffset()
      const sourceMouseOffset = monitor.getInitialClientOffset()
      const sourceElementOffset = monitor.getInitialSourceClientOffset()
      const diffX = sourceMouseOffset!.x - sourceElementOffset!.x
      const diffY = sourceMouseOffset!.y - sourceElementOffset!.y
      const x = currentMouseOffset!.x - diffX;
      const y = currentMouseOffset!.y - diffY;
      let dropPosition = graph?.pageToLocal(x, y);
      handleAddNode(namespace, id, Math.floor(dropPosition?.x || 40), Math.floor(dropPosition?.y || 40));
      Reflect.set(positions || initPosition, id, {x: (Math.floor(dropPosition?.x || 40)), y: (Math.floor(dropPosition?.y || 40))});
      setPositions({
        ...(positions || initPosition),
        [id]: {x: (Math.floor(dropPosition?.x || 40)), y: (Math.floor(dropPosition?.y || 40))}
      });
    }
  }), [graph])

  const sourceData = useMemo(() => {
    if (entities && relations && initPosition && initSize) {
      // console.log(entities, relations, initPosition, initSize);
      return {
        nodes: entities.map((nodeItem) => {
          let { x = 40, y = 40 } = initPosition[nodeItem.name!] || {};
          let { width = 250, height = 250 } = initSize[nodeItem.name!] || {};
          // console.log(nodeItem.name, width, height);
          return {
            // name 保证唯一性
            id: `${nodeItem.name}`, // String，可选，节点的唯一标识
            x: x, // Number，必选，节点位置的 x 值
            y: y, // Number，必选，节点位置的 y 值
            width, // Number，可选，节点大小的 width 值
            height, // Number，可选，节点大小的 height 值
            label: nodeItem.display + '', // String，节点标签
            ...nodeItem,
          };
        }),
        edges: relations.map((relationItem: any) => {
          return {
            id: relationItem.name,
            source: {
              cell: relationItem.relation_multiplicity !== 'MANY2MANY'? relationItem.object_type2.name : relationItem.object_type1.name,
              port: relationItem.name,
              anchor: {
                name: 'center',
                args: {
                  dx: 10 - Math.round(Math.random()*100),
                  dy: 10 - Math.round(Math.random()*100),
                }
              }
            },
            target: {
              cell: relationItem.relation_multiplicity !== 'MANY2MANY'? relationItem.object_type1.name : relationItem.object_type2.name,
              port: relationItem.name,
              // anchor: 'orth',
              anchor: {
                name: 'orth',
                args: {
                  dx: 10 - Math.round(Math.random()*100),
                  dy: 10 - Math.round(Math.random()*100),
                }
              }
            },
            router: {
              name: 'manhattan',
            },
            connector: {
              name: 'rounded',
            },
            labels: [{
                attrs: {
                  text: {
                    text:
                      relationItem.relation_multiplicity === 'ONE2MANY'
                        ? '1'
                        : relationItem.relation_multiplicity === 'ONE2ONE'
                        ? '1'
                        : '*',
                    fontSize: 24,
                    fill: '333',
                    stroke: '#666666',
                  },
                },
                position: {
                  distance: -50,
                },
              },{
                attrs: {
                  text: {
                    text:
                      relationItem.relation_multiplicity === 'ONE2MANY'
                        ? '*'
                        : relationItem.relation_multiplicity === 'ONE2ONE'
                        ? '1'
                        : '*',
                    fontSize: 24,
                    fill: '333',
                    stroke: '#666666',
                  },
                },
                position: {
                  distance: 20,
                },
              },
            ],
            attrs: {
              line: {
                stroke: relationItem.state === 'PUBLISH_TODO' 
                ? "#3f9714"
                : relationItem.state === 'PUBLISH_DONE' 
                  ? "#333333"
                  : relationItem.state === 'DRAFT' 
                    ? "#bbbab8"
                    : relationItem.state === 'PUBLISH_UNDO'
                      ? "#3f9714" 
                      : '',
                strokeWidth: 2,
                targetMarker: {
                  name: relationItem.relation_multiplicity === 'MANY2MANY'? 'path' : 'diamond',
                  width: 22,
                  height: 10,
                  offset: -11,
                  custom: false,
                  strokeWidth: 1,
                  fill: '#ffffff',//等有强关系后用三目表达式判断关系类型并设置颜色为#333333
                }
              },
            },
          };
        }),
      };
    }
  }, [entities, relations, initPosition]);

  const {
    data: namespaceResponse,
    refetch: refetchNamespaceInfo
  } = useMyQuery(['namespace-info', namespace], namespaceApi.detail, {
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    const graph = new Graph({
      container: graphRef.current || undefined,
      // width: 800,
      // height: 600,
      background: {
        color: '#f5f5f5', // 设置画布背景颜色
      },
      resizing: {
        enabled: true,
      },
      connecting: {
        snap: true,
      },
      selecting: {
        enabled: true,
      },
      grid: {
        size: 10, // 网格大小 10px
        visible: true, // 渲染网格背景
      },
      mousewheel: true, //鼠标滚轮缩放，默认禁用。
      panning: {
        //画布是否可以拖动
        enabled: true,
      },
      scroller: {
        autoResize: true,
      },
      minimap: {
        enabled: true,
        container: minimapContainer.current || undefined,
      },
      autoResize: true, //是否监听容器大小改变，并自动更新画布大小
    });
    graph.zoom(-0.5); // 在原来缩放级别上减少 0.5
    setGraph(graph);
    setGlobalGraph(graph);
    const nodeList = sourceData?.nodes.map((item: any) => { 
      return graph.addNode({
        id: item.id,
        x: item.x,
        y: item.y,
        width: item.width,
        height: item.height,
        shape: 'react-shape',
        component(node: any) {
          return (
            <div
              css={css`
            border: 1px solid #333;
            text-align: center;
            height: 100%;
            width: 100%;
            background-color: ${item.state === 'PUBLISH_TODO' 
						? "#bbbab8"
						: item.state === 'PUBLISH_DONE' 
							? "#f1c670"
							: item.state === 'DRAFT' 
								? "#ebe8e1"
								: item.state === 'PUBLISH_UNDO'
									? "#bbbab8" 
									: 'white'};
            display: flex;
            flex-direction: column;
          }
          `}
            >
              <p
                css={css`
                  border-bottom: 1px solid #333;
                  margin: 0;
                `}
              >
                {item.label}
              </p>
              <div
                css={css`
                  overflow: auto;
                  height: 100%;
                  width: 100%;
                `}
              >
                {item?.attributes?.map((i: any) => {
                  return (
                    <div
                      css={css`
                        display: flex;
                        justify-content: space-between;
                        padding: 0 5px;
                        height: 20px;
                        line-height: 20px;
                      `}
                    >
                      <p>{i.name}</p>
                      <p>{i.type}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        },
        ports: item.ports
      });
    }) || [];
    
    const edgeList = sourceData?.edges.map((item: any) => {
      return graph.addEdge(item);
    }) || [];
    
    const update = () => {
      edgeList.forEach(edge => {
        const edgeView = edge.findView(graph) as EdgeView;
        edgeView.update()
      });
    }

    nodeList.forEach((item: any) => {
      item.on('change:position', update)
    });

    graph.on('node:moved', ({ node }) => {
      Reflect.set(positions || initPosition, node.id, node.getPosition());
      setPositions({
        ...(positions || initPosition),
        [node.id]: node.getPosition()
      });
    });
    
    graph.on('node:resized', ({ node }) => {
      Reflect.set(nodeSize || initSize, node.id, node.getSize());
      setNodeSize({
        ...(nodeSize || initSize),
        [node.id]: node.getSize()
      });
    });

    graph.on('node:click', ({ e, x, y, node, view }) => {
      setCell({node: node});
    })

    graph.on('edge:click', ({ e, x, y, edge, view }) => { 
      setCell({edge: edge});
    })

    graph.on('blank:click', ({ e, x, y }) => { 
      setCell({blank: 'blank'});
    })

    graph.on('selection:changed', (args: {
      added: Cell[],
      removed: Cell[],
      selected: Cell[],
    }) => {
      args.added.forEach((cell: Cell) => {
        if (cell.isEdge()) {
          cell.attr('line/stroke', 'red')
        }
      })
      args.removed.forEach((cell: Cell) => {
        if (cell.isEdge()) {
          console.log(cell);
          cell.attr('line/stroke', '#333333')
        }
      })
    })

    graph.centerContent();

    // graph.fromJSON(sourceData);

    // console.log(graph.getNodes(), graph.getNodes()[0].getPosition());
    // set

    return () => graph.dispose();
  }, [sourceData]);

  const onResize = (width?: number, height?: number) => {
    graph?.resize(width, height);
    graph?.centerContent();
  };

  useDebounce(() => {
    const namespaceInfo = namespaceResponse?.data;
    if (namespaceInfo && (positions || nodeSize)) {
      namespaceApi.update({
        ...namespaceInfo,
        labels: {
          ...namespaceInfo.labels,
          positionGuide: positions || initPosition,
          sizeGuide: nodeSize || initSize
        },
        old_namespaceName: namespaceInfo.name
      }).then(() => {
        return refetchNamespaceInfo({
          throwOnError: false,
          cancelRefetch: true
        })
      }).catch(err => {
        console.error(err);
      });
    }
  }, 2000, [positions, nodeSize, initPosition, initSize]);

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%', position: 'relative'}} ref={drop}>
      <ResizeDetector
        handleWidth
        handleHeight
        skipOnMount
        onResize={onResize}
      />
      <div
        ref={graphRef}
        style={{
          // width: '100% !important',
          // height: '100% !important',
          flex: 1,
        }}
      ></div>
      <div
        ref={minimapContainer}
        style={{ position: 'absolute', bottom: 0, right: 0 }}
      ></div>
    </div>
  );
};

X6Diagram.register = {
  attributes: [
    {
      name: 'namespace',
      type: 'string',
    },
  ],
  styles: ['width', 'height'],
};

export default X6Diagram;
