import request from '../../sdk/request';
import env from 'day4-utils/es/env';
import {RelationBasicSetting, RelationType} from "../types/RelationTypes";
import {EntityAttributeType} from "../types/EntityTypes";

const api = env.DAY4_APP_MODELER_META_API;

const relationApi = {
  list (namespace: string, params?: any) {
    return request<RelationType[]>({
      url: `${api}/namespaces/${namespace}/relation_types`,
      params
    })
  },
  detail (namespace: string, relation: string) {
    return request<RelationType>({
      url: `${api}/namespaces/${namespace}/relation_types/${relation}`
    })
  },
  upsertAttributes (namespace: string, relation: string, attributes: EntityAttributeType[]) {
    return request<any>({
      url: `${api}/namespaces/${namespace}/relation_types/${relation}/attributes/_batch`,
      method: 'POST',
      data: attributes
    })
  },
  create (basicSetting: RelationBasicSetting) {
    const {namespace} = basicSetting;
    return request<RelationType>({
      url: `${api}/namespaces/${namespace}/relation_types`,
      method: "POST",
      data: basicSetting
    })
  },
  update (basicSetting: RelationBasicSetting) {
    const {namespace, old_name} = basicSetting;
    if (!old_name) {
      return Promise.reject('no old_name in relation!');
    } else {
      return request<RelationType>({
        url: `${api}/namespaces/${namespace}/relation_types/${old_name}`,
        method: "PUT",
        data: basicSetting
      })
    }
  }
}

export default relationApi
