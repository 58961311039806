import { useMemo } from 'react';
import {
  EntityAttributeType,
  EntityType,
} from '../../../api/types/EntityTypes';

// 给定一个foreignKey，判断选择的ET1是否能够被这个foreignKey关联（前端体验优化，后端当然也会做校验）
function useEntityType1Validation(
  foreignKey: EntityAttributeType,
  et1: EntityType | undefined
): (string | boolean)[] {
  return useMemo(() => {
    if (!et1) {
      return [false, '请选择一个实体类型'];
    }
    if (et1.identity_type.attributes.length > 1) {
      return [false, `不能选择${et1.name}}: 只能选择一个ID属性的实体类型`];
    }
    const idAttribute = et1.identity_type.attributes[0];
    if (idAttribute.type !== foreignKey.type) {
      return [
        false,
        `不能选择${et1.name}: ${idAttribute.name}(${idAttribute.type})数据类型不匹配`,
      ];
    }
    return [true, ''];
  }, [foreignKey, et1]);
}

export default useEntityType1Validation;
