import {FC} from "react";

const FingerprintIcon: FC<any> = ({color, ...otherProps}) => (
  <svg className="icon" viewBox="0 0 1024 1024"
       version="1.1" xmlns="http://www.w3.org/2000/svg"
       width="20" height="20" {...otherProps}>
    <path
      d="M749.9 698c-7.9-2.3-16.3 2.3-18.6 10.2-5.9 20.2-11.8 35.4-18.1 46.3-4.1 7.2-1.6 16.4 5.6 20.5 2.3 1.4 4.9 2 7.4 2 5.2 0 10.3-2.7 13-7.7 7.5-13 14.3-30.2 20.9-52.7 2.3-7.9-2.3-16.3-10.2-18.6zM255.1 512.4c0.6 0.1 1.1 0.1 1.7 0.1 7.5 0 14-5.7 14.9-13.4 0.1-1 12.8-107.1 81.4-179.3 1.8-1.9 3.2-3.3 4.6-4.7 5.9-5.8 5.9-15.3 0.1-21.2-5.8-5.9-15.3-5.9-21.2-0.1-1.6 1.5-3.2 3.2-5.2 5.3-75.7 79.7-89 192-89.5 196.7-0.9 8.3 5 15.7 13.2 16.6zM710.3 298.4c-47.6-50.4-112.8-76-193.7-76-41.5 0-79.7 8.1-113.4 24.1-7.5 3.6-10.6 12.5-7.1 20 3.6 7.5 12.5 10.6 20 7.1 29.7-14.1 63.6-21.2 100.6-21.2 72.3 0 130.1 22.4 171.9 66.6 66.7 70.6 70.1 162.7 70.1 180.4 0 33.1-3.9 89.1-14.7 147.3-1.5 8.2 3.9 16 12 17.5 1 0.2 1.9 0.3 2.8 0.3 7.1 0 13.4-5 14.6-12.4 11.2-60.1 15.2-118.3 15.2-152.7 0-19.7-3.8-122.2-78.3-201z"
      fill={color} p-id="2438"></path>
    <path
      d="M644.3 363.8c-5.8-5.9-15.3-5.9-21.2 0-5.9 5.8-5.9 15.3 0 21.2 30.4 30.4 45.5 68.1 46 115.2 0.6 46.9-10.1 144.3-41.8 220-3.1 7.6 0.5 16.4 8.1 19.6 1.9 0.8 3.9 1.2 5.8 1.2 5.9 0 11.5-3.5 13.7-9.1 36.1-86.2 44.7-189.4 44.2-232-0.6-55.4-18.6-99.9-54.8-136.1zM275.9 684.8c-6.5-5.2-16-4.1-21.1 2.4-9.2 11.6-15.6 18.6-15.7 18.7-5.5 6.1-5.1 15.6 1 21.2 2.9 2.6 6.5 3.9 10.1 3.9 4.1 0 8.2-1.6 11.1-4.8 0.3-0.3 7.1-7.8 17-20.3 5.2-6.5 4.1-16-2.4-21.1zM356.1 538.3c7.9-48 15.4-93.4 40.7-131.8 27.9-42.2 67.7-63.6 118.5-63.6 21.8 0 41.7 3.3 59.3 9.7 7.7 2.9 16.3-1.1 19.2-8.9 2.9-7.7-1.1-16.3-8.9-19.2-20.9-7.6-44.3-11.5-69.7-11.5-32.5 0-61.9 7.5-87.2 22.4-22 12.9-40.9 31.2-56.3 54.6-28.8 43.7-37.2 94.5-45.3 143.5-1.5 8.7-2.9 17.6-4.5 26.4-4.5 25-17.3 54.1-38 86.5-4.4 7-2.4 16.2 4.6 20.7 2.5 1.6 5.3 2.4 8.1 2.4 5 0 9.8-2.4 12.8-7 22.8-35.8 37-68.6 42.2-97.5 1.6-8.9 3.1-18 4.5-26.7z"
      fill={color} p-id="2439"></path>
    <path
      d="M584.7 432.1c-16.2-19.3-39.3-29.1-68.6-29.1h-0.8c-26.1 0.2-47.5 10.7-63.5 31.3-5.1 6.5-3.9 15.9 2.6 21s15.9 3.9 21-2.6c10.3-13.3 23.1-19.6 40-19.7h0.6c20.2 0 35.2 6 45.4 18.4 18.7 22.3 23.5 67.8 13 124.9-10.7 58.1-39 148.1-59.5 177-4.7 6.7-3.2 16.1 3.6 20.9 2.7 1.9 5.7 2.8 8.7 2.8 4.7 0 9.4-2.2 12.5-6.3 23.7-33.5 53-126.5 64.5-189 3.4-18.8 7.2-47.2 5.5-75.3-1.9-31.8-10.3-56.8-25-74.3zM445.3 555.3c2.1-15.6 4.5-33.4 8.4-50.5 1.8-8.1-3.2-16.1-11.3-18-8.1-1.8-16.1 3.2-18 11.3-4.2 18.5-6.7 37-8.9 53.3-1.8 13.3-3.3 24.7-5.3 32.3-16.1 61.4-62.1 133.5-62.6 134.2-4.5 6.9-2.5 16.2 4.5 20.7 2.5 1.6 5.3 2.4 8.1 2.4 5 0 9.8-2.4 12.7-6.9 2-3.1 49.1-76.7 66.4-142.9 2.4-9.4 4.1-21.7 6-35.9z"
      fill={color} p-id="2440"></path>
    <path
      d="M532.7 520.2c0.4-8.3-6-15.3-14.3-15.7-8.3-0.4-15.3 6-15.7 14.3-0.1 1.4-7.9 145.3-101.6 258.1-5.2 6.3-4.4 15.8 2 21.1 2.8 2.4 6.2 3.5 9.6 3.5 4.3 0 8.6-1.8 11.6-5.5 52.4-63 79-134.5 92.2-183.4 14.2-52.9 16.1-90.8 16.2-92.4z"
      fill={color} p-id="2441"></path>
  </svg>
)

export default FingerprintIcon;
