/** @jsxImportSource @emotion/react */
import { CodeBlock as CB, dracula } from "react-code-blocks";
import { Day4CustomFC } from "../../types";

interface CodeBlockPropType {
  text: string,
  language: string,
  showLineNumbers: boolean,
  highlight: string,
}

const CodeBlock: Day4CustomFC<CodeBlockPropType> = ({
  text,
  language,
  showLineNumbers,
  highlight,
}) => {
  text = text ? text : ""
  return (
    <CB
      language={language}
      text={text}
      showLineNumbers={showLineNumbers}
      theme={dracula}
      wrapLines={true}
    />
  )
}

export default CodeBlock;
