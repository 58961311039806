import ModalAddDataSource from './ModalAddDS'


ModalAddDataSource.register = {
  styles: ['width', 'height'],
  events: [{
    type: 'ok'
  }]
}

export default ModalAddDataSource
