/** @jsxImportSource @emotion/react */
import {Day4CustomFC} from "../../types";
import useStyles from "day4-components/es/hooks/useStyles";
import Icon from "day4-components/es/components/Icon";

interface SimpleLinkPropType {
  text: string,
  href: string,
  icon?: string,
  styles?: any
}

const SimpleLink: Day4CustomFC<SimpleLinkPropType> = ({
  text,
  href,
  icon,
  styles
}) => {
  const componentCss = useStyles([], styles);
  return (
    <a href={href} css={componentCss}>
      {icon && <Icon type={icon}/>}
      {text}
    </a>
  )
}

export default SimpleLink;
